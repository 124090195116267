<template>
  <div
    class="product-navigation flex justify-space-between align-center"
    :class="{ loading: event_loading }"
  >
    <div class="left-button">
      <router-link
        v-show="!event_loading"
        v-if="!back.disabled"
        class="btn black-btn"
        :to="link + back.link"
        >{{ back.button }}</router-link
      >
      <span v-if="!back.disabled">{{ back.label }}</span>
    </div>
    <div
      class="right-button"
      v-if="!isLockerLocked && !isProjectLocked"
    >
      <span>{{ next.label }}</span>
      <router-link
        v-show="!event_loading"
        v-if="!next.event"
        class="btn white-btn"
        :to="link + next.link"
        >{{ next.button }}</router-link
      >
      <span
        v-else
        class="btn white-btn event-white-btn"
        @click="triggerEvent(next.event)"
        >{{ !event_loading ? next.button : "Loading" }}</span
      >
    </div>
    <div class="right-button" v-else>
      <span class="locked">This locker has been locked</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductNavigation",
  props: ["back", "next", "nextEventEnabled"],
  computed: {
    ...mapGetters("lockerModule", ["isLockerLocked"]),
    ...mapGetters("actionsPermissions", ["isProjectLocked"]),

    link() {
      let dealercode = this.$route.params.dealercode,
        showroom_id = this.$route.params.showroom_id,
        showroom_slug = this.$route.params.showroom_slug;
      if (showroom_id) {
        return (
          "/" +
          dealercode +
          "/showroom/" +
          showroom_id +
          "/" +
          showroom_slug +
          "/"
        );
      }
      return "/" + dealercode + "/";
    },
    hasCompanionItems() {
      return this.$store.state.companion_items.length > 0;
    },
    event_loading() {
      return this.$store.state.locked.event;
    },
  },
  methods: {
    triggerEvent(name) {
      if (!this.event_loading) {
        switch (name) {
          case "companion": {
            this.$parent.showCompanionItemsModal = true;
            break;
          }
          case "complete": {
            this.$store.commit("updateState", {
              state: "goToLocker",
              value: true,
            });
            this.$store.dispatch("completeUniform");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .product-navigation {
    width: 100%;
    background-color: $black;
    padding: 10px 15px;
    margin-left: auto;
    margin-top: auto;
    z-index: 1000;
    margin-top: auto !important;

    .btn {
      display: inline-block;
    }
    span {
      color: $white;
      font-size: 15px;
      font-style: italic;
      line-height: 17px;
      display: inline-block;
    }
    .left-button {
      text-align: center;
      span {
        font-family: $ubuntuLightItalic;
        margin-left: 8px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    .right-button {
      .locked {
        cursor: not-allowed;
      }
      span {
        font-family: $ubuntuMediumItalic;
        margin-right: 8px;
      }
      span.event-white-btn {
        margin: 0;
        font-family: "Ubuntu Medium";
        font-size: 12px;
        line-height: 13px;
        padding: 8px 26px;
        border-radius: 109px;
        border: 1px solid #000;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        font-style: initial;
        color: $black;
        &:hover {
          color: $white;
        }
      }
    }
    &.loading {
      opacity: 0.5;
    }

    @media screen and (max-width: 1440px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end !important;

      .left-button,
      .right-button {
        width: 40%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;

        span {
          margin: 0;
          margin-bottom: 0.5rem;
        }
      }

      .right-button {
        flex-direction: column;
      }
    }

    /* @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
    } */
  }
}
</style>