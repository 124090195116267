<template>
  <div class="page container flex flex-column">
    <PageHeading :product_type="'main'" />
    <div id="product-catalogue" class="flex align-start">
      <!-- Sidebard -->
      <div
        id="catalogue-sidebar"
        :class="{ active: isSidebarOpen }"
        v-if="!hideSidebar && this.catalogs && this.catalogs.length"
      >
        <!-- Close sidebar in mobiles -->
        <button class="btn btn-close-filters" @click="handleIsSidebarOpen">
          Close
        </button>

        <!-- Catalogs Checkboxes -->
        <div class="sidebar-filters box-shadow">
          <div class="sidebar-section">
            <h3>Product Type</h3>
            <div class="radio-i tems">
              <div
                v-for="(filter, index) in catalogsFilters"
                :key="index + '-sidebar-filter'"
                class="checkbox-container flex"
                :class="{ disabled: catalogCheckboxDisabled(filter) }"
              >
                <label
                  :for="filter.name"
                  :class="{ disabled: catalogCheckboxDisabled(filter) }"
                >
                  <input
                    :id="filter.name"
                    type="checkbox"
                    name="uniform"
                    value="0"
                    @change="selectCatalog(filter.id)"
                    :checked="filter.selected"
                    :disabled="catalogCheckboxDisabled(filter)"
                  />
                  <span>{{ filter.name }}</span>
                  <span class="checkmark"></span>
                </label>
              </div>
              <transition name="fade">
                <span class="loading-catalogs-msg" v-if="loadingCatalogs"
                  >Loading catalogs...</span
                >
              </transition>
            </div>
          </div>
          <div class="sidebar-section">
            <h3>Production time</h3>
            <div class="radio-items">
              <div class="radio-container flex flex-reverse justify-end">
                <input
                  id="all"
                  type="radio"
                  name="time"
                  value="0"
                  selected="selected"
                />
                <label for="all">All</label>
                <span class="checkmark"></span>
              </div>
              <div class="radio-container flex flex-reverse justify-end">
                <input id="30d" type="radio" name="time" value="1" />
                <label for="30d">30 days</label>
                <span class="checkmark"></span>
              </div>
              <div class="radio-container flex flex-reverse justify-end">
                <input id="5d" type="radio" name="time" value="2" />
                <label for="5d">5 days</label>
                <span class="checkmark"></span>
              </div>
              <div class="radio-container flex flex-reverse justify-end">
                <input id="2d" type="radio" name="time" value="3" />
                <label for="2d">2 days</label>
                <span class="checkmark"></span>
              </div>
            </div>
          </div>
          <div class="sidebar-section" v-if="!hidePrice">
            <h3>Price range</h3>
            <div class="range-slider">
              <v-range-slider
                v-model="price"
                :min="min_price"
                :max="max_price"
                color="#000"
                track-color="#dfdfdf"
                height="10px"
              ></v-range-slider>
              <div class="min-max-container flex justify-space-between">
                <h4>{{ price[0] }} USD</h4>
                <h4>{{ price[1] }} USD</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- LOGO SIDEBAR -->
        <CatalogueSidebar :filteredCatalogs="filteredCatalogs" />
        
        <router-link v-if="!hasTeamLink" :to="link" class="btn white-btn"
          >&#8810; BACK TO FORM</router-link
        >
      </div>

      <!-- Catalogs Rows -->
      <section
        id="catalogue-rows-container"
        v-if="this.catalogs && this.catalogs.length"
      >
        <button class="btn btn-open-filters" @click="handleIsSidebarOpen">
          Filters
        </button>
        <template v-if="filteredCatalogs.length">
          <CatalogueRow
            v-for="sportCatalogue in filteredCatalogs"
            :key="sportCatalogue.id + '-catalogs-row'"
            :sportCatalogue="sportCatalogue"
            :priceRange="price"
            :filteredTargetColors="filteredTargetColors"
            :hidePrice="hidePrice"
            :min_price="min_price"
            :max_price="max_price"
          />
        </template>
        <template v-else>
          <p>Select at least one catalog in the sidebar filters</p>
        </template>
      </section>

      <div v-else class="flex justify-center align-center loader-container">
        <v-progress-circular size="250" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";
import { mapActions, mapGetters, mapState } from "vuex";

import PageHeading from "../components/PageHeading.vue";
import CatalogueRow from "@/components/catalogue/CatalogueRow.vue";
import CatalogueSidebar from "@/components/catalogue/CatalogueSidebar.vue";
//

export default {
  name: "Catalogue",
  components: {
    PageHeading,
    CatalogueRow,
    CatalogueSidebar,
  },
  data() {
    return {
      min_price: 0,
      max_price: 0,
      price: [0, 0],
      isSidebarOpen: false,
    };
  },
  methods: {
    ...mapActions([
      "getProductsBySport",
      "setDefaultLogo",
      "requestTextStamp",
      "resetReversible",
      "selectCatalog",
    ]),

    // initCatalogsFilters() {
    //   const filters = [];

    //   this.catalogsFilters = filters;
    // },

    catalogCheckboxDisabled(filter) {
      return (
        this.loadingCatalogs ||
        (filter.selected && this.getSelectedCatalogsIds.length === 1)
      );
    },

    getLowerPrice(catalogs) {
      let lower_price = 9999;
      if (catalogs) {
        catalogs.forEach(function (catalog) {
          catalog.products &&
            catalog.products.forEach((product) => {
              if (parseFloat(product.price) < lower_price) {
                lower_price = parseFloat(product.price).toFixed();
              }
            });
        });
      }
      return Math.floor(lower_price);
    },

    getHigherPrice(catalogs) {
      let higher_price = 0;
      if (catalogs) {
        catalogs.forEach(function (catalog) {
          catalog.products &&
            catalog.products.forEach((product) => {
              if (parseFloat(product.price) > higher_price) {
                higher_price = parseFloat(product.price).toFixed(2);
              }
            });
        });
      }
      return Math.ceil(higher_price);
    },

    handleIsSidebarOpen() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    goToHome() {
      this.$store.dispatch("redirectToPageWithParams", {
        name: "Home",
      });
    },
  },
  computed: {
    ...mapState(["catalogs", "sport", "colors", "sports", "loadingCatalogs"]),
    ...mapGetters("actionsPermissions", ["isProjectLocked"]),

    ...mapGetters([
      "getCurrentCatalogue",
      "getColorById",
      "getColorByField",
      "getSelectedCatalogsIds",
    ]),

    catalogsFilters() {
      return this.catalogs.map((catalog) => {
        return {
          id: catalog.id,
          name: catalog.name,
          selected: catalog.selected,
        };
      });
    },

    hasTeamLink() {
      return (
        this.teamLink != null &&
        Object.keys(this.teamLink).length &&
        this.teamLink.id
      );
    },

    teamLink() {
      return this.$store.state.team_link;
    },

    hasTeamLinkLogo() {
      return this.hasTeamLink && this.teamLink.logo_converted;
    },

    activeFilters() {
      return this.catalogsFilters.filter((filter) => filter.selected === true);
    },

    filteredCatalogs() {
      return this.catalogs.filter((catalog) =>
        this.activeFilters.some((filter) => filter.name == catalog.name)
      );
    },

    filteredTargetColors() {
      //We need to get the first match for C1, C2 and C3 and then the backup colors
      let targetColors = [];
      for (let index = 1; index <= 3; index++) {
        targetColors.push(
          this.getColorByField({
            product_type: "main",
            field: "color_" + index,
          }).id
        );
      }
      return targetColors.concat(CONSTANTS.BACKUP_STOCK_COLOR_IDS).unique();
    },
    hideSidebar() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        return this.$store.state.dealer.settings.hide_sidebar;
      }
      return false;
    },
    hidePrice() {
      if (this.hasTeamLink) {
        if (this.$store.state.team_link.display_msrp !== null) {
          return this.$store.state.team_link.display_msrp === "false";
        }
      }
      return !this.$store.state.dealer.settings
        ? false
        : this.$store.state.dealer.settings.display_msrp === "false";
    },
    link() {
      let dealercode = this.$route.params.dealercode,
        showroom_id = this.$route.params.showroom_id,
        showroom_slug = this.$route.params.showroom_slug;
      if (showroom_id) {
        return (
          "/" +
          dealercode +
          "/showroom/" +
          showroom_id +
          "/" +
          showroom_slug +
          "/"
        );
      }
      return "/" + dealercode + "/";
    },
    fill() {
      return this.$store.state.main_product.front.name.fill;
    },
    fill_color() {
      let colors = this.$store.state.colors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.fill) {
          return element;
        }
      }
      return false;
    },
    outline() {
      return this.$store.state.main_product.front.name.outline;
    },
    outline_color() {
      let colors = this.$store.state.colors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.outline) {
          return element;
        }
      }
      return false;
    },
  },
  watch: {
    filteredCatalogs() {
      this.min_price = this.getLowerPrice(this.filteredCatalogs);
      this.max_price = this.getHigherPrice(this.filteredCatalogs);
      this.price = [
        this.getLowerPrice(this.filteredCatalogs),
        this.getHigherPrice(this.filteredCatalogs),
      ];

      this.$store.dispatch("updateBreadcrumbs", []);
    },

    colors() {
      let colors = this.$store.state.colors,
        color_field;
      for (let i = 0; i < colors.length; i++) {
        const element = colors[i];
        for (let index = 1; index <= 3; index++) {
          color_field = "color_" + index;
          if (
            this.$route.query[color_field] &&
            element.name.toLowerCase() ===
              this.$route.query[color_field].toLowerCase()
          ) {
            this.$store.commit("updateSubState", {
              state: "main_product",
              substate: color_field,
              value: i,
            });
          }
        }
      }
    },
  },
  mounted() {
    //If the user enter in this page without first landed in homepage, and doesn't have a teamlink, redirect him to homepage
    if (!this.$router.currentRoute.params.showroom_id && this.getSelectedCatalogsIds.length == 0) {
      this.goToHome();
    }

    // this.initCatalogsFilters();
    this.resetReversible();

    this.$store.commit("unlockStates", ["sport", "gender", "event", "colors"]);

    //Reset current selected product and data to prevent errors
    let substatesToClear = ["product", "design", "layout"],
      productTypes = ["main_product", "companion_product"];
    for (let p = 0; p < productTypes.length; p++) {
      for (let index = 0; index < substatesToClear.length; index++) {
        const substate = substatesToClear[index];
        this.$store.commit("updateSubState", {
          state: productTypes[p],
          substate,
          value: {},
        });
      }
    }
    this.$store.commit("updateState", {
      state: "uniform_id",
      value: 0,
    });
    //End reset

    this.$store.commit("updateState", {
      state: "activeProduct",
      value: "main_product",
    });

    let layersides = CONSTANTS.LAYER.SIDES;
    for (let index = 0; index < layersides.length; index++) {
      const layerside = layersides[index];

      if (
        this.hasTeamLinkLogo ||
        (!this.$store.state.main_product[layerside].logo &&
          !this.$store.state.main_product[layerside].logo.url &&
          !this.$store.state.main_product[layerside].logo.url.length)
      ) {
        this.setDefaultLogo({
          product_type: "main_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });

        this.setDefaultLogo({
          product_type: "companion_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });
      }
      //check if team name changed at homepage
      if (
        this.$store.state.main_product.front.name &&
        this.$store.state.main_product.front.name.value !== "Team name"
      ) {
        this.$store.dispatch("regenerateTextStamps", "main_product");
      }
      //end regenerate text stamp if changed at home
    }

    //Set price range

    this.min_price = this.getLowerPrice(this.catalogs);
    this.max_price = this.getHigherPrice(this.catalogs);
    this.price = [
      this.getLowerPrice(this.catalogs),
      this.getHigherPrice(this.catalogs),
    ];

    //end get catalogue
    this.$store.dispatch("updateBreadcrumbs", []);
  },
};
</script>

<style lang="scss" scoped>
#product-catalogue {
  position: relative;

  #catalogue-sidebar {
    width: 166px;
    margin-right: 20px;
    .sidebar-filters {
      width: 166px;
      min-width: 166px;
      padding: 19px 12px 2px;
      margin-right: 14px;
      margin-bottom: 22px;
      .sidebar-section {
        margin-bottom: 30px;

        h3 {
          color: $darkerGray;
          letter-spacing: 0;
          text-transform: uppercase;
          padding-bottom: 0.35rem;
          border-bottom: 1px solid $lighterGray;
          margin-bottom: 8.5px;
        }

        .range-slider {
          margin-top: 15px;
          .min-max-container {
            h4 {
              color: $darkerGray;
              opacity: 0.47;
            }
          }
        }

        .loading-catalogs-msg {
          font-family: $ubuntuMedium;
        }
      }
    }
    .btn {
      display: block;
      width: 166px;
    }
    .btn-close-filters {
      display: none;
    }
  }

  .loader-container {
    width: 100%;
    margin-top: 50px;
  }

  #catalogue-rows-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .btn.btn-open-filters {
      display: none;
    }
  }

  @media screen and (max-width: 660px) {
    display: flex;
    flex-flow: column;

    #catalogue-sidebar {
      width: 100%;
      margin: 0;
      margin-bottom: 2rem;
      overflow: scroll;
      position: fixed;
      z-index: 100;
      padding: 1rem;
      width: 75vw;
      height: 100vh;
      margin: auto;
      left: 0px;
      background: white;
      box-shadow: 0 0 10px 8px #0000002e;
      border-radius: 0 10px 10px 0;
      top: 0;
      transform: translateX(-96vw);
      transition: all 0.4s;

      &.active {
        transform: translateX(0);
      }

      .sidebar-filters {
        width: 100%;
        margin-bottom: 2rem;
      }

      .btn-close-filters {
        margin: auto;
        margin-bottom: 1rem;
        display: block;
        margin-top: 1rem;
      }
    }

    #catalogue-rows-container {
      .btn.btn-open-filters {
        background-color: black;
        color: white;
        display: block;
        margin: auto;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>