<template>
  <div class="upload-logo mb-2" @click.stop="emitOpenManageLogosModal">
    <input
      type="file"
      :id="refName"
      @click="$event.target.value = ''"
      @change="uploadFile()"
      :ref="refName"
    />
    <label :for="refName" class="flex flex-center align-center m-auto">
      <img src="~@/assets/icons/upload.svg" alt="upload" />
      <span>
        {{ graphicButtonHint }}
      </span>
    </label>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "UploadLogoButton",
  props: ["graphicButtonHint", "refName", "hasCustomer"],

  computed: {
    ...mapState(["dealer", "endpoint"]),
  },

  methods: {
    uploadFile() {
      const file = this.$refs[this.refName].files[0];
      this.emitUploadingLogo(file);

      let formData = new FormData();
      formData.append("file", file);

      axios
        .post(this.$store.state.settings.endpoint + "api/logos", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: "Bearer " + this.$store.state.settings.token, TODO -> Por que no tenemos token?
            Authorization:
              // "Bearer " + this.$store.state.settings.token,
              "Bearer " + "adascbRDboRto4uWe5dS5kyIS5kIB5bSOpZlkT7ubS",
          },
        })
        .then((response) => {
          const newLogo = {
            ...response.data,
            fileUploaded: response.data.content,
            fileOriginal: response.data.url,
            id: response.data.id,
            colors: response.data.detected_colors,
            type: [],
            typeOfLogo: [],
            status: "active",
          };

          this.emitLogoHasBeenUploaded(newLogo);
        })
        .catch(() => {
          this.$toastr.e("Error in uploadFile");
        });
    },

    emitUploadingLogo(file) {
      this.$emit("uploadingLogo", file);
    },

    emitLogoHasBeenUploaded(newLogo) {
      this.$emit("logoHasBeenUploaded", newLogo);
    },

    emitOpenManageLogosModal() {
      this.hasCustomer && this.$emit("openManageLogosModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  p {
    font-size: 1.2rem;
  }
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    width: 100%;
    height: 41px;
    background-color: #eee;
    max-width: 300px;
    padding: 10px;
    text-overflow: ellipsis;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px !important;
    }
    span {
      color: #666;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>