<template>
  <div class="row mb-5 box-shadow" :id="refName + '-sidebar'">
    <ManageLogosModal
      v-show="showManageLogosModal && !isAnEmbeddedShowroom"
      :isModalOpen="showManageLogosModal && !isAnEmbeddedShowroom"
      :preselectedLogoId="preselectedLogoId"
      :uploadedLogos="logos"
      :MAX_LOGOS_ALLOWED="1"
      :typeOfLogo="typeOfLogo"
      :refName="refName"
      @openCropperJsToEditLogo="openCropperJsToEditLogo"
      @changeSelectedLogo="changeSelectedLogo"
      @removeLogo="removeLogo"
      @closeModal="showManageLogosModal = false"
      @uploadingLogo="handleUploadingLogo"
      v-on="{ saveEditedLogo }"
      @logoHasBeenUploaded="handleLogoHasBeenUploaded"
    />

    <UploadLogoModal
      v-if="showUploadLogoModal"
      :fileUploaded="fileUploaded"
      :fileName="fileName"
      :fileSize="fileSize"
      :fileExtension="fileExtension"
      :fileOriginal="fileOriginal"
      :fullScreen="true"
      :customerId="customerId"
      :logoIdToBeEdited="logoIdToBeEdited"
      :editableLogo="editableLogo"
      :typesOfLogoToBeEdited="typesOfLogoToBeEdited"
      :isManageModalOpen="showManageLogosModal"
      @saveEditedLogo="saveEditedLogo"
      @updateLogoSrc="updateLogoSrc"
      @close="showUploadLogoModal = false"
    />

    <EditScreenLogoModal
      v-show="showEditScreenLogoModal"
      :refName="typeOfLogo"
      @applyScreenLogo="handleApplyScreenLogo"
      @close="handleCloseEditScreenLogoModal"
    />

    <h2 class="mb-3">{{ typeOfLogo }} Graphic</h2>
    <!-- Image -->
    <article class="logo-container">
      <ScreenLogoPreview
        :showLogoCondition="logo && logo.id"
        :logo="logo"
        :loading="loading"
        @handleReplaceOrDeleteButton="handleReplaceOrDeleteButton"
      />

      <!-- Replace/Delete -->
      <div class="logo-container--buttons-container d-flex flex-column">
        <button
          v-if="logo && logo.id"
          class="edit-graphic-button mb-5"
          @click="openEditScreenLogoModal"
          :disabled="loading"
        >
          Move / Resize
        </button>

        <button
          v-if="logos.length"
          class="swap-graphic-button mb-5"
          @click="handleReplaceOrDeleteButton"
          :disabled="loading"
        >
          <SwapIconSVG /> <span>Swap Graphic</span>
        </button>
      </div>
    </article>
    <!-- Upload -->
    <UploadLogoButton
      :graphicButtonHint="'Upload new graphic'"
      :refName="refName"
      @uploadingLogo="handleUploadingLogo"
      @logoHasBeenUploaded="handleLogoHasBeenUploaded"
    />

    <p class="information">
      This graphic is being used in all showroom garments, changing it here will
      change it across the whole showroom. You can also change graphics as you
      edit any individual product.
    </p>
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";

import { mapGetters, mapActions, mapState } from "vuex";

import EditScreenLogoModal from "@/components/Logos/EditScreenLogoModal";
import ManageLogosModal from "@/components/Logos/ManageLogosModal";
import ScreenLogoPreview from "@/components/Logos/ScreenLogoPreview";
import UploadLogoModal from "@/components/Logos/UploadLogoModal";
import UploadLogoButton from "@/components/Logos/UploadLogoButton";
import SwapIconSVG from "@/components/Logos/SwapIconSVG.vue";

export default {
  name: "CatalogueSidebarLogo",

  props: ["logo", "typeOfLogo", "refName"],

  data() {
    return {
      file: "",
      showUploadLogoModal: false,
      showCompanionItemsModal: false,
      fileUploaded: "",
      fileOriginal: "",
      fileName: "",
      fileExtension: "",
      fileSize: "",

      //Logo data

      logoIdToBeEdited: "",
      editableLogo: "",
      typesOfLogoToBeEdited: [this.typeOfLogo],

      //show manage logo
      showManageLogosModal: false,
      showEditScreenLogoModal: false,

      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      getTeamLink: "getTeamLink",
    }),

    ...mapGetters("customer", ["getLogoById"]),
    ...mapState(["customerId", "dealerMode", "isAnEmbeddedShowroom"]),
    ...mapState("customer", ["logos", "uniformsLogo", "spiritwearLogo"]),

    preselectedLogoId() {
      return this.logo?.id ? this.logo.id : 1;
    },
  },

  methods: {
    ...mapActions(["setDefaultLogo"]),
    ...mapActions("screenLogo", ["setScreenLogoProperties", "setApiLoading"]),
    ...mapActions("customer", [
      "addNewLogo",
      "updateLogo",
      "updateShowroomLogo",
    ]),

    setActiveLayout(layout) {
      this.product.layout = layout;
      this.activeLayout = layout;
      this.edited = true; //ver si es con emit para que aparezca el boton save de eze
    },

    //Logo methods

    handleReplaceOrDeleteButton() {
      // if (this.dealerMode && !this.isAnEmbeddedShowroom) {
      if (!this.isAnEmbeddedShowroom) {
        this.showManageLogosModal = true;
      } else {
        this.removeLogo();
        const uploadButton = this.$children.filter(
          (child) => !!child.$refs[this.refName]
        )[0];

        uploadButton.$refs[this.refName].click();
      }
    },

    updateLogoSrc(payload) {
      this.logoSrc = payload.fileUploaded;
      this.showUploadLogoModal = false;

      //update team link urls
      this.$store.commit("updateSubState", {
        state: "team_link",
        substate: "logo_converted",
        value: payload.fileUploaded,
      });

      this.$store.commit("updateSubState", {
        state: "team_link",
        substate: "logo_original",
        value: payload.fileUploaded,
      });

      //upload after call to setDefaultLogo for both prods, uses team link logo
      let layersides = CONSTANTS.LAYER.SIDES;
      for (let index = 0; index < layersides.length; index++) {
        const layerside = layersides[index];
        this.setDefaultLogo({
          product_type: "main_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });

        this.setDefaultLogo({
          product_type: "companion_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });
      }
    },

    async changeSelectedLogo(selectedLogoId) {
      try {
        this.loading = true;
        this.showManageLogosModal = false;
        if (selectedLogoId) {
          this.logoIdToBeEdited = selectedLogoId;
          await this.updateShowroomLogo({
            logoId: selectedLogoId,
            typeOfLogo: this.typeOfLogo,
          });
        }
        this.edited = true;
        this.loading = false;
      } catch (error) {
        this.$toastr.e("Error trying to update the logo");
      } finally {
        this.loading = false;
      }
    },

    removeLogo() {
      this.logoIdToBeEdited = "";
      this.showManageLogosModal = false;
      this.edited = true;
      this.updateShowroomLogo({
        logoId: null,
        typeOfLogo: this.typeOfLogo,
      });

      this.removeActiveProductLogo();
    },

    removeActiveProductLogo() {
      if (Object.keys(this.getTeamLink).length) {
        this.$store.commit("updateSubState", {
          state: "team_link",
          substate: "logo_converted",
          value: '',
        });

        this.$store.commit("updateSubState", {
          state: "team_link",
          substate: "logo_original",
          value: "",
        });

        //upload after call to setDefaultLogo for both prods, uses team link logo
        let layersides = CONSTANTS.LAYER.SIDES;
        for (let index = 0; index < layersides.length; index++) {
          const layerside = layersides[index];
          this.setDefaultLogo({
            product_type: "main_product",
            side: layerside,
            field: "logo",
            logoRemoved: false,
          });

          this.setDefaultLogo({
            product_type: "companion_product",
            side: layerside,
            field: "logo",
            logoRemoved: false,
          });
        }
      }
    },

    handleUploadingLogo(file) {
      this.showUploadLogoModal = true;
      // this.showManageLogosModal = false;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.file = file;
      this.fileName = this.file.name;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    },

    handleLogoHasBeenUploaded(newLogo) {
      this.fileUploaded = newLogo.fileUploaded;
      this.fileOriginal = newLogo.fileOriginal;

      // this.preselectedLogoId = newLogo.id;
      this.logoIdToBeEdited = newLogo.id;
      this.editableLogo = newLogo;
      this.addNewLogo(newLogo);
      this.updateShowroomLogo({
        logoId: newLogo.id,
        typeOfLogo: this.typeOfLogo,
      });
    },

    openEditScreenLogoModal() {
      this.showEditScreenLogoModal = true;
      const logoBase64 = this.getLogoById(this.logo.id);
      const { zoom, position } = this.logo.screenLogoProperties;

      this.setScreenLogoProperties({
        originalGraphic: logoBase64.content,
        zoom,
        position,
      });
    },

    handleCloseEditScreenLogoModal() {
      this.showEditScreenLogoModal = false;
      this.setScreenLogoProperties({
        originalGraphic: null,
        zoom:
          this.logo?.screenLogoProperties?.zoom ||
          CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
        position:
          this.logo?.screenLogoProperties?.position ||
          CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
      });
    },

    //This only shows how the screen logo will be, and it is applied in the modal
    async handleApplyScreenLogo(screenLogoProperties) {
      // this.logoSrc = screenLogoProperties.logo;
      // this.logoHasChanged = true;
      // this.edited = true;
      await this.updateShowroomLogo({
        logoId: this.logo.id,
        typeOfLogo: this.typeOfLogo,
        screenLogoProperties: {
          ...screenLogoProperties,
          type: this.typeOfLogo,
        },
      });
      this.setApiLoading(false);
      this.showEditScreenLogoModal = false;
    },

    openCropperJsToEditLogo(logo) {
      this.showUploadLogoModal = true;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.file = logo;
      this.fileName = this.file.fileOriginal;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];

      this.fileUploaded = logo.fileUploaded;
      this.fileOriginal = logo.fileOriginal;
      this.logoIdToBeEdited = logo.id;
      this.typesOfLogoToBeEdited = logo.typeOfLogo ? logo.typeOfLogo : [];
      this.editableLogo = logo;
    },

    getSelectedLogoById(selectedLogoId) {
      if (!selectedLogoId) return false;

      //To do=> We will need to return ALL the selected logos when the endpoint is ready
      const logo = this.logos.filter(
        (uploadedLogo) => uploadedLogo.id === selectedLogoId
      )[0];

      return logo;
    },

    saveEditedLogo({ editedLogoId, editedLogo, typeOfLogo, colors }) {
      this.fileUploaded = "";
      this.fileOriginal = "";
      // this.preselectedLogoId = editedLogoId;
      this.updateLogo({ editedLogoId, editedLogo, typeOfLogo, colors });

      this.showManageLogosModal = true;
      this.changeSelectedLogo(editedLogoId);
    },
  },

  components: {
    EditScreenLogoModal,
    ManageLogosModal,
    ScreenLogoPreview,
    UploadLogoModal,
    UploadLogoButton,
    SwapIconSVG,
  },
};
</script>

<style lang="scss" scoped>
#app {
  #catalogue-sidebar-logo {
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem;

      h2 {
        background-color: $black;
        color: whitesmoke;
        padding: 0.5rem;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .edit-graphic-button {
      background: none;
      border: 1px solid #666;
      cursor: pointer;
      color: #666;
      font-family: "Ubuntu Regular";
      font-size: 0.9rem;
      margin: 8px auto;
      padding: 0.4rem;
      width: 100%;

      &:hover {
        background: black;
        color: white;
        transform: scale(1);
      }
    }

    .swap-graphic-button {
      * {
        font-family: "Ubuntu Regular";
      }
      align-items: center;
      background: none;
      border: none !important;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      transition: all 0.2s;

      span {
        text-decoration: underline;
        text-transform: capitalize;
        color: #424242;
        font-size: 0.9rem;
      }

      svg {
        margin-right: 0.3rem;
        width: 16px;
        height: 16px;
        fill: black;
      }

      &:hover {
        span {
          color: whitesmoke;
          text-decoration: none;
        }
        svg {
          fill: whitesmoke;
        }
      }
    }

    .information {
      font-family: $ubuntuLight;
      font-size: 12px;
      line-height: 13px;
    }

    .logo-container {
      width: 100%;

      /* .logo-container--image-wrapper {
        cursor: pointer;
        display: flex;
        width: 150px;
        height: 150px;
        border: 1px solid #000;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);

        .laoder {
          margin: auto;
        }
        img.logo-image {
          display: block;
        }
      } */
      .logo-container--buttons-container {
        padding: 1rem;
        button {
          border: 1px solid black;
          border-radius: 10px;
          text-decoration: none;
          transition: all 0.2s;
          &:hover {
            background: black;
            color: white;
          }
        }
      }
    }
    .upload-wrapper {
      width: 90%;
      display: flex;
      justify-content: center;
      input {
        display: none;
      }
      .upload-logo {
        width: 100%;
        height: 41px;
        background-color: #f9f9f9;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        cursor: pointer;
        span {
          font-size: 13px;
          padding-left: 10px;
        }
      }
    }
  }
}
</style>