<template>
  <div
    class="color-picker-swatch flex align-center"
    @click="swatchClick({ color, field, product_type, side, subfield })"
  >
    <div
      class="color-picker-radius box-shadow"
      v-bind:style="{ backgroundColor: color.hex }"
    ></div>
    <span>{{ color.name }}</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["color", "field", "product_type", "side", "subfield"],
  name: "ColorSwatch",
  methods: {
    ...mapActions([
      "updateColor",
      "requestTextStamp",
      "saveUpdatedColorsAndTeamName",
    ]),
    async swatchClick(data) {
      await this.updateColor(data);
      if (this.subfield != null) {
        this.requestTextStamp({
          value:
            this.$store.state[this.product_type + "_product"][this.sideKey][
              this.field
            ].value,
          field: this.field,
          side: this.sideKey,
          fill: this.fill_color.hex,
          outline: this.outline_color.hex,
          product_type: this.product_type,
          font: this.$store.state[this.product_type + "_product"][this.sideKey][
            this.field
          ].font,
        });
      } else {
        try {
          await this.$store.dispatch(
            "regenerateTextStamps",
            this.product_type + "_product"
          );
          await this.saveUpdatedColorsAndTeamName();
        } catch {
          this.$toastr.e("Error saving updated colors");
        } finally {
          this.$parent.triggerPicker();
        }
      }
    },
  },
  computed: {
    ...mapGetters(["activeProduct"]),
    reversibleActive() {
      return !this.alternative
        ? this.activeProduct.reversibleActive
        : !this.activeProduct.reversibleActive;
    },
    sideKey() {
      return this.reversibleActive ? "reversible_" + this.side : this.side;
    },
    fill() {
      return this.$store.state[this.product_type + "_product"][this.sideKey][
        this.field
      ].fill;
    },
    fill_color() {
      let colors = this.$store.state.colors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.fill) {
          return element;
        }
      }
      return false;
    },
    outline() {
      return this.$store.state[this.product_type + "_product"][this.sideKey][
        this.field
      ].outline;
    },
    outline_color() {
      let colors = this.$store.state.colors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.outline) {
          return element;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  #main {
    #home-sidebar {
      .color-picker-swatch {
        .color-picker-radius {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .color-picker-swatch {
    cursor: pointer;
    &:hover {
      background-color: #303030;
      span {
        color: $white;
      }
    }
    .color-picker-radius {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 5px;
    }
    span {
      color: $blackGray;
      font-family: $ubuntuRegular;
      font-size: 10px;
      line-height: 11px;
      letter-spacing: 0.76px;
      text-transform: lowercase;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>