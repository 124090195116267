<template>
  <section id="locker-sh-container">
    <GameplanLocker
      :dealerMode="!!dealerMode"
      :projectStatus="showroomStatus || ''"
      :isProjectLocked="isProjectLocked"
      :projectRoute="projectRoute"
      @requestQuoteSucceded="SET_SHOWROOM_STATUS(PROJECT_STATUS.QUOTE_STATUS)"
      @requestOmgStoresSuccess="SET_SHOWROOM_STATUS(PROJECT_STATUS.OMG_STATUS)"
    />
  </section>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import CONSTANTS from "@/constants.js";
export default {
  name: "Locker",

  data() {
    return {
      lockerMode: "quote",
      showLockerSuccess: false,
      savedLocker: false,
      opened: {
        locker_name: false,
        uniform_id: 0,
        modal: false,
        quote: false,
      },
      temp_name: "",
      modal: {
        players: [],
        uniform_id: 0,
        sizes: [],
        saving: 0,
      },
      uniforms_data: [],
    };
  },

  computed: {
    ...mapGetters(["showroomStatus"]),
    ...mapGetters("actionsPermissions", ["isProjectLocked"]),
    projectRoute() {
      const { dealercode, showroom_id, showroom_slug } = this.$route.params;
      return showroom_id
        ? `/${dealercode}/showroom/${showroom_id}/${showroom_slug}/`
        : `/${dealercode}/catalogue`;
    },
    lockerCode() {
      return this.$store.lockerModule.locker.code;
    },
    forceDisabled() {
      return true; //stuff temp disabled by client request
    },
    eventLoading() {
      return this.$store.state.locked.event;
    },
    lockerName() {
      return this.dealerMode
        ? this.teamLink?.title
        : this.locker.name || this.defaultLockerTitle;
    },
    teamLink() {
      return this.$store.state.team_link;
    },
    dealerMode() {
      return this.$store.state.dealerMode;
    },
    lockerInformationMessage() {
      return this.dealerMode
        ? 'Welcome to the locker of your locker - this is where all designs will go until you or your customer are ready. You will be able to find this at any time under "My Lockers".'
        : "Welcome to the locker of your locker - this is where all your designs go until you are ready to request a quote.";
    },
    dealerLoaded() {
      return (
        this.$store.state.dealer && Object.keys(this.$store.state.dealer) > 0
      );
    },
    defaultLockerTitle() {
      if (this.$store.state.team_link) {
        return this.$store.state.team_link.title;
      }
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        if (
          this.$store.state.dealer.settings.labels &&
          this.$store.state.dealer.settings.labels.default_locker_title
        ) {
          return this.$store.state.dealer.settings.labels.default_locker_title;
        } else {
          return "Untitled Locker";
        }
      }
      return "";
    },
    changeLockerName() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        if (
          this.$store.state.dealer.settings.labels &&
          this.$store.state.dealer.settings.labels.change_locker_name
        ) {
          return this.$store.state.dealer.settings.labels.change_locker_name;
        } else {
          return "Change locker name";
        }
      }
      return "";
    },
    addAnotherUniformLabel() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        if (
          this.$store.state.dealer.settings.labels &&
          this.$store.state.dealer.settings.labels.add_another_uniform
        ) {
          return this.$store.state.dealer.settings.labels.add_another_uniform;
        } else {
          return "Add another item";
        }
      }
      return "Add another item";
    },
    is_checkout() {
      return this.lockerMode === "checkout";
    },
    is_quote() {
      return this.lockerMode === "quote";
    },
    locker() {
      return this.$store.state.lockerModule.locker;
    },
    locker_name() {
      return this.locker && this.locker.name
        ? this.locker.name
        : this.defaultLockerTitle;
    },
    uniforms_have_roster() {
      if (this.locker.items) {
        for (let index = 0; index < this.locker.items.length; index++) {
          const element = this.locker.items[index];
          if (!element.roster) {
            return false;
          }
        }
        return true;
      }
      return false;
    },

    PROJECT_STATUS() {
      return CONSTANTS.PROJECT_STATUS;
    },
  },

  methods: {
    ...mapMutations(["SET_SHOWROOM_STATUS"]),
    goToHome() {
      this.$store.dispatch("redirectToPageWithParams", {
        name: "Home",
      });
    },
    requestOMG() {
      this.$store.commit("lockEvent");
      axios
        .post(
          this.$store.state.settings.endpoint + "api/v2/omg",
          {
            uniforms_data: JSON.stringify(this.uniforms_data),
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.settings.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.$store.commit("updateSubStateField", {
            state: "locker",
            substate: "data",
            field: "locked",
            value: true,
          });
          this.showLockerSuccess = true;
          this.$store.commit("unlockEvent");
        })
        .catch(() => {
          this.$store.commit("unlockEvent");
        });
    },
    closeRosterModal() {
      this.opened.modal = false;
    },
    closeQuoteModal() {
      this.opened.quote = false;
      this.showLockerSuccess = false;
    },
    updateUniform(data) {
      switch (data.type) {
        case "uniform": {
          this.opened.uniform_id = data.id;
          break;
        }
        case "roster": {
          this.modal.players = data.roster;
          this.modal.uniform_id = data.id;
          this.modal.sizes = data.sizes;
          this.opened.modal = true;
          break;
        }
        case "update_quantities": {
          let found = false;
          for (let index = 1; index <= this.uniforms_data.length; index++) {
            const element = this.uniforms_data[index - 1];
            if (element.uniform_id === data.uniform_id) {
              found = index;
            }
          }
          if (found) {
            this.uniforms_data[found - 1] = {
              uniform_id: data.uniform_id,
              jerseys: data.jerseys,
              pants: data.pants,
            };
          } else {
            this.uniforms_data.push({
              uniform_id: data.uniform_id,
              jerseys: data.jerseys,
              pants: data.pants,
            });
          }
          break;
        }
      }
    },
    toggleLockerName() {
      this.temp_name = this.locker.name;
      this.opened.locker_name = !this.opened.locker_name;
    },
    saveLockerName() {
      if (
        this.temp_name.trim().length &&
        (!this.locker.name || this.temp_name !== this.locker.name)
      ) {
        this.$store.dispatch("updateLockerName", {
          code: this.locker.code,
          name: this.temp_name.trim(),
        });
        this.$store.commit("updateSubStateField", {
          state: "locker",
          substate: "data",
          field: "name",
          value: this.temp_name.trim(),
        });
        this.opened.locker_name = false;
      }
    },
    newUniform() {
      this.$store.commit("updateState", {
        state: "editMode",
        value: false,
      });
      this.$store.dispatch("redirectToPageWithParams", {
        name: "Catalogue",
      });
    },
    checkout() {
      if (this.is_checkout) {
        if (this.uniforms_have_roster) {
          this._vm.$toastr.e("To do checkout");
        }
      } else {
        this.opened.quote = true;
      }
    },
  },

  mounted() {
    if (!this.locker && this.locker === "pepep") {
      this.$store.dispatch("redirectToPageWithParams", {
        name: "Catalogue",
      });
    } else {
      this.$store.dispatch("updateBreadcrumbs", [
        {
          name: "Locker",
          component: "Locker",
        },
      ]);
      //Clear product states
      this.$store.commit("updateSubState", {
        state: "main_product",
        substate: "product",
        value: {},
      });

      if (this.$store.state.companion_product) {
        this.$store.commit("updateSubState", {
          state: "companion_product",
          substate: "product",
          value: null,
        });
      }

      this.$store.commit("updateState", {
        state: "uniform_id",
        value: 0,
      });

      this.$store.commit("updateState", {
        state: "goToLocker",
        value: false,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
section#locker-sh-container {
  background: #f9fafa;
  width: 100%;
  min-height: 100vh;
}
.btn-back {
  background: #f9f9f9;
  font-size: 1rem;
  font-family: Ubuntu Bold;
  text-transform: uppercase;
  border: 1px solid #707070 !important;
  border-radius: 5px;
  padding: 0.2rem 2rem 0.4rem 2rem;
  margin: auto;
  display: block;
  width: 250px;
  margin-top: 2rem;

  box-shadow: 0 3px 6px -2px #707070;

  color: #252525;
  text-decoration: none;
  text-align: center;

  font-weight: bold;
  text-transform: uppercase;
  transition: box-shadow 0.2s ease;
  transition: background-color 0.15s ease;
  transition: color 0.15s ease;
  transition: transform 0.15s ease;

  &:hover {
    box-shadow: 0 0 8px 1px #00000099;
    background-color: black;
    color: white;

    transform: scale(1.05);
  }
}
</style>