<template>
  <div class="page page-bg bg-with-overlay">
    <div
      class="page-dealer-bg"
      :style="{
        backgroundImage: backgroundImg,
      }"
    >
      <div
        id="main"
        class="container"
        :class="{ 'with-background': backgroundImg && true }"
      >
        <div id="home-sidebar">
          <h1>{{ homeSidebarTitle }}</h1>
          <div class="sidebar-body flex flex-column">
            <!-- Color Pickers -->
            <div class="interior-container">
              <h3 class="section-title">Team Colors</h3>
              <hr />
              <div class="color-pickers-container flex justify-space-between">
                <div class="color-picker-container">
                  <h5>Main color</h5>
                  <ColorPicker field="color_1" :product_type="'main'" />
                </div>
                <div class="color-picker-container">
                  <h5>Second color</h5>
                  <ColorPicker field="color_2" :product_type="'main'" />
                </div>
                <div class="color-picker-container">
                  <h5>Third color</h5>
                  <ColorPicker field="color_3" :product_type="'main'" />
                </div>
              </div>
            </div>

            <!-- Team Name -->
            <div class="interior-container">
              <h3 class="section-title">Team Name</h3>
              <hr />
              <div class="flex">
                <input
                  type="text"
                  value="Team name"
                  min="1"
                  max="20"
                  :readonly="isLockerLocked"
                  v-model="team_name"
                />
              </div>
            </div>

            <!-- Sports selection -->
            <div class="interior-container">
              <h3 class="section-title">Choose a sport</h3>
              <hr />
              <div
                v-if="catalogs.length"
                class="flex flex-wrap sports-container"
              >
                <SportCard
                  v-bind:key="index + '-sport-card'"
                  :id="sport.id"
                  :sport="sport"
                  v-for="(sport, index) in catalogs"
                />
              </div>
              <div v-else class="flex justify-center">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
            </div>

            <router-link
              v-if="getSelectedCatalogsIds.length > 0"
              class="btn black-btn"
              :to="next"
              >Start designing</router-link
            >
            <p v-else class="text-center font-weight-bold">
              (Select at least one sport)
            </p>
            <div
              class="design-code flex align-center justify-center"
              :class="{ 'locker-loading': locker_loading }"
            >
              <p>I have a design code</p>
              <input
                type="text"
                placeholder="Enter code"
                v-model="locker_code"
                v-on:keyup="locker_info = ''"
                v-on:keyup.enter="validateCode"
                minlength="1"
                maxlength="10"
                name="locker_code"
              />
              <button
                class="btn black-btn continue-locker"
                type="button"
                v-if="locker_code.length"
                @click="validateCode"
              >
                Go
              </button>
            </div>
            <span class="flex align-center justify-center error-info">{{
              locker_info
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import { mapGetters, mapActions, mapState } from "vuex";
import ColorPicker from "../components/ColorPicker.vue";
import SportCard from "../components/homepage/SportCard.vue";

export default {
  name: "Home",
  methods: {
    ...mapActions(["goToLocker"]),
    ...mapActions("lockerModule", ["getLockerByCode", "resetLockerState"]),

    async validateCode() {
      if (
        !this.locker_loading &&
        this.locker_code.length &&
        this.locker_code.length <= 10
      ) {
        this.locker_loading = true;
        this.getLockerByCode(this.locker_code)
          .then(() => {
            if (this.lockerModule.locker.id) {
              this.goToLocker();
            } else {
              this.resetLockerState();
              throw new Error("Locker not found");
            }
          })
          .catch(() => {
            this.resetLockerState();
            this.locker_info = "Error trying to information";
            throw new Error("Locker not found");
          })
          .finally(() => {
            this.locker_loading = false;
          });
      }
    },
  },
  data: function () {
    return {
      opened_pickers: {
        color_1: false,
        color_2: false,
        color_3: false,
      },
      locker_code: "",
      locker_info: "",
      locker_loading: false,
    };
  },
  components: {
    ColorPicker,
    SportCard,
  },
  computed: {
    ...mapState(["lockerModule", "catalogs", "homeCatalogsIds"]),

    ...mapGetters(["getSelectedCatalogsIds"]),
    ...mapGetters("lockerModule", ["isLockerLocked"]),

    hideGender() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        return this.$store.state.dealer.settings.hide_genders;
      }
      return false;
    },
    homeSidebarTitle() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        if (
          this.$store.state.dealer.settings.labels &&
          this.$store.state.dealer.settings.labels.home_sidebar_title
        ) {
          return this.$store.state.dealer.settings.labels.home_sidebar_title;
        } else {
          return "Design your uniform";
        }
      }
      return "";
    },
    next() {
      let dealercode = this.$route.params.dealercode,
        teamlink = this.$route.params.teamlink;
      if (teamlink) {
        return "/" + dealercode + "/team/" + teamlink;
      }
      return "/" + dealercode + "/catalogue";
    },
    backgroundImg() {
      if (
        this.$store.state.dealer &&
        this.$store.state.dealer.settings &&
        this.$store.state.dealer.settings.hero_image
      ) {
        return "url('" + this.$store.state.dealer.settings.hero_image + "')";
      }
      // return "url('../home/bg.jpg')";
      return "url('/images/homepage-hero.png')";
    },
    team_name: {
      get() {
        return this.$store.state.main_product.front.name.value;
      },
      set(value) {
        // if (!value) value = "Team name";
        this.$store.commit("setTeamName", { product_type: "main", value });
      },
    },
    gender: {
      get() {
        return this.$store.state.gender;
      },
      set(value) {
        this.$store.commit("setGender", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch("updateBreadcrumbs", []);
  },
};
</script>


<style lang="scss" scoped>
.page-bg {
  min-height: 600px;
  background: url("~@/assets/bg.jpg");
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: 0 100%;
  position: relative;
  .page-dealer-bg {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    display: flex;
    z-index: 0;
    height: 100%;
    padding-top: 0.5rem;
    background-origin: content-box;
    background-repeat: no-repeat !important;
    background-size: contain;
    background-position: left bottom;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    @media screen and (max-width: 1440px) {
      background-size: contain;
      background-position: center;
    }
    @media screen and (max-width: 768px) {
      background-size: cover;
      background-position: center;
    }

    @media screen and (max-width: 576px) {
      background-size: contain;
      background-position: top;
    }
  }
}

#main {
  #home-sidebar {
    width: 366px;
    margin-left: auto;
    margin-top: 30px;
    h1 {
      background-color: $blackGray;
      color: $white;
      text-transform: uppercase;
      padding: 15px 36px;
      margin: 0;
    }
    .sidebar-body {
      background-color: $white;
      box-shadow: -6px 5px 14px rgba($black, 0.26);
      padding: 15px 20px 25px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      .interior-container {
        margin-bottom: 16px;
        h3.section-title {
          color: $darkGray;
          text-transform: uppercase;
          margin-bottom: 5.5px;
        }
        hr {
          height: 1px;
          background-color: $lighterGray;
          border: none;
          margin: 0 0 7.5px;
        }
        .color-pickers-container {
          .color-picker-container {
            h5 {
              color: $darkGray;
              text-transform: uppercase;
            }
            .color-picker {
              margin: 6px 0;
            }
          }

          @media screen and (max-width: 576px) {
            flex-flow: column;
            padding-top: 0.5rem;

            & > * {
              margin-bottom: 1rem;
            }
          }
        }
        .sports-container {
          margin: 0 -5px;
          @media screen and (max-width: 576px) {
            display: flex;
            justify-content: space-evenly;
            margin: auto;
          }
        }
        .gender-section-container {
          margin-bottom: 15px;
          text-transform: uppercase;
          > h3 {
            color: $darkGray;
          }
          .gender-container {
            margin-left: 10px;
            cursor: pointer;
            h3 {
              label {
                cursor: pointer;
                color: $black;
                font-family: $ubuntuRegular;
                font-weight: normal;
                opacity: 0.57;
              }
            }
            input[type="radio"] {
              display: none;
              &:checked + h3 {
                label {
                  font-family: $ubuntuBold;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .btn {
        font-family: $ubuntuBold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1.22px;
        border-color: $black;
      }
      .design-code {
        margin-top: 8px;
        p {
          color: $black;
        }
        input {
          width: 100px;
          padding: 7px;
          border-radius: 4px;
          margin-left: 7px;
          color: $blackGray;
        }
        .continue-locker {
          padding: 5px 10px;
          margin-left: 5px;
        }
        &.locker-loading {
          opacity: 0.3;
        }
      }
      .error-info {
        color: $red;
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;

    #home-sidebar {
      margin: 0 !important;
      max-width: 100%;
    }
  }

  &.with-background {
    @media screen and (max-width: 1024px) {
      display: flex;
      justify-content: center;
      align-items: center;

      #home-sidebar {
        margin: 0 !important;
        max-width: 100%;
      }
    }

    @media screen and (max-width: 576px) {
      align-items: flex-end;

      #home-sidebar {
        margin-bottom: 1rem !important;
        height: 80vh;
      }
    }
  }
}
</style>