<template>
  <div class="product-designs flex align-center justify-space-between">
    <div class="product-designs-container flex" v-if="designsVisible">
      <div
        class="product-preview"
        v-for="(design, index) in limitedDesigns"
        :key="index"
        @click="selectDesign(index)"
      >
        <!-- todo redo this better with 1 compo -->
        <Vector
          :title="design.name"
          v-if="!isVariantStock"
          :side="side"
          :svgs="design.svg_data[side]"
          :backgrounds="backgrounds"
          :layout="layout"
          :product_type="product_type"
          :stock_color="design.color"
          :is_stock="is_stock"
          :isVariantStock="isVariantStock"
          :isSpiritwear="isSpiritwear"
          :styleId="styleId"
          :designName="designName"
          :isHybridSVG="isHybridSVG"
        />
        <Vector
          :title="design.color_name.replace(/_/g, ' ').toUpperCase()"
          v-else
          :side="side"
          :svgs="{ group1: [], group2: [], group3: [] }"
          :backgrounds="limitedDesigns[index].backgrounds"
          :layout="layout"
          :product_type="product_type"
          :stock_color="design.color"
          :is_stock="is_stock"
          :isVariantStock="isVariantStock"
          :isSpiritwear="isSpiritwear"
          :styleId="styleId"
          :designName="designName"
          :isHybridSVG="isHybridSVG"
        />
      </div>
    </div>
    <span v-if="moreDesigns">+{{ moreDesigns }}</span>
  </div>
</template>

<script>
import Vector from "../Vector";
import { mapGetters } from "vuex";

export default {
  props: [
    "backgrounds",
    "designs",
    "side",
    "layout",
    "product_type",
    "is_stock",
    "isVariantStock",
    "filteredStockVariants",
    "designsVisible",
    "isSpiritwear",
    "styleId",
    "designName",
    "isHybridSVG"
  ],
  name: "ProductDesigns",
  components: {
    Vector,
  },
  computed: {
    ...mapGetters(["getColorByField"]),

    fixed_designs() {
      let fixed_designs = [];
      if (!this.isVariantStock) {
        for (let index = 0; index < this.designs.length; index++) {
          if (this.designs[index].svg_data) {
            fixed_designs.push(this.designs[index]);
          }
        }
        return fixed_designs;
      }
      return this.filteredStockVariants;
    },
    limitedDesigns() {
      if (this.fixed_designs.length > 5) {
        return this.fixed_designs.slice(0, 5);
      } else {
        return this.fixed_designs;
      }
    },
    moreDesigns() {
      if (this.fixed_designs.length > 5) {
        return this.fixed_designs.length - 5;
      } else {
        return false;
      }
    },
  },
  methods: {
    selectDesign(index) {
      this.$emit("selectDesign", index);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .product-designs {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #f2f2f2;
    padding: 0 15px 0 6px;

    height: 50px;
    width: 100%;
    .product-designs-container {
      .product-preview {
        width: 32px;
        padding: 1px;
        &.active {
          padding: 0;
          border: 1px solid red;
        }
        .product-image {
          display: flex;
          cursor: pointer;
          ::v-deep svg {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    span {
      color: $black;
      font-family: $ubuntuBold;
      font-size: 11px;
      line-height: 12px;
    }
  }
}
</style>