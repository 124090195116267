<template>
  <div
    class="logo-container--image-wrapper"
    :style="{ alignItems, justifyContent }"
    @click="$emit('handleReplaceOrDeleteButton')"
  >
    <Loader v-if="loading" />
    <img
      v-else-if="showLogoCondition"
      class="logo-image"
      :src="logoImage"
      :style="{
        maxWidth: zoomStyle,
        maxHeight: zoomStyle,
      }"
    />
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";
export default {
  name: "ScreenLogoPreview",

  props: ["showLogoCondition", "logo", "loading"],

  data() {
    return {
      position: "",
      zoom: "",
      sizeRanges: ["25%", "50%", "75%", "100%"],
    };
  },

  methods: {
    setScreenLogoPreviewProperties() {
      this.position =
        this.logo?.screenLogoProperties?.position ||
        CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position;
      this.zoom =
        this.logo?.screenLogoProperties?.zoom ||
        CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom;
    },
  },

  computed: {
    ...mapGetters("customer", ["getLogoById"]),

    logoImage() {
      return this.getLogoById(this.logo.id).content;
    },

    //Screen Logo position
    alignItems() {
      //returns align-items: $value
      return [1, 2, 3].indexOf(this.position) >= 0
        ? "flex-start"
        : [4, 5, 6].indexOf(this.position) >= 0
        ? "center"
        : "flex-end";
    },
    justifyContent() {
      return [1, 4, 7].indexOf(this.position) >= 0
        ? "flex-start"
        : [2, 5, 8].indexOf(this.position) >= 0
        ? "center"
        : "flex-end";
    },
    zoomStyle() {
      return (this.zoom / this.sizeRanges.length).toFixed(2) * 100 + "%";
    },
  },

  mounted() {
    this.setScreenLogoPreviewProperties();
  },

  watch: {
    logo() {
      this.setScreenLogoPreviewProperties();
    },
  },

  components: { Loader },
};
</script>


<style lang="scss" scoped>
.logo-container--image-wrapper {
  cursor: pointer;
  display: flex;
  width: 150px;
  height: 150px;
  border: 1px solid #000;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC) !important;

  .loader {
    margin: auto;
  }
  img.logo-image {
    display: block;
  }
}
</style>