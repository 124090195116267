<template>
  <section class="layout-editor">
    <CompanionItemsModal
      v-if="showCompanionModal && activeProductType === 'main_product'"
    />
    <div id="container" class="flex justify-space-between" v-if="product">
      <ProductInformation
        :name="product.name"
        :design="design"
        :backgrounds="product.backgrounds"
        :tier="product.tier_id"
        :layout="productLayout"
        :product_type="product_type"
        :description="product.description"
        :is_stock="!product.is_sublimable"
        :isVariantStock="isVariantStock"
      ></ProductInformation>
      <div class="right-box-container">
        <div class="right-box-interior-container">
          <h2 v-show="layouts && layouts.length > 1">Layouts</h2>
          <div class="layouts-container" v-show="layouts && layouts.length > 1">
            <LayoutBox
              v-for="(layout, index) in layouts"
              :key="index"
              :layout="layout"
              :product_type="product_type"
            />
          </div>
          <div class="layout-editor-container" v-if="this.has_active_layout">
            <div
              v-if="
                this.has_active_layout && this.active_layout.layout_attributes
              "
              class="layout-editor"
            >
              <div
                class="layout-row-outer"
                v-for="(layerSide, index) in layerSides"
                :key="index"
              >
                <h2 v-if="active_layout.layout_attributes[layerSide]">
                  Customize the {{ layerSide }}
                </h2>
                <div
                  class="layout-row-inner"
                  v-for="(layerField, indey) in layerFields"
                  :key="indey"
                >
                  <LayoutRow
                    v-if="
                      active_layout.layout_attributes[layerSide] &&
                      active_layout.layout_attributes[layerSide][layerField]
                    "
                    :active_layout="active_layout"
                    :side="layerSide"
                    :field="layerField"
                    :product_type="product_type"
                    :product_tier="product.tier_id"
                  ></LayoutRow>
                </div>
                <LogoLayout
                  v-if="
                    active_layout.layout_attributes[layerSide] &&
                    active_layout.layout_attributes[layerSide].logo
                  "
                  :hideLogoSize="hideLogoSize"
                  :active_layout="active_layout"
                  :layerSide="layerSide"
                  :index="index"
                  :productType="'main'"
                  v-on="{ uploadFile }"
                >
                </LogoLayout>
                <LayoutArtwork
                  v-if="
                    active_layout.layout_attributes[layerSide] &&
                    active_layout.layout_attributes[layerSide].artwork
                  "
                  :activeLayout="active_layout"
                  :productType="'main'"
                  :side="layerSide"
                  :product_tier="product.tier_id"
                ></LayoutArtwork>
              </div>
            </div>
          </div>
        </div>
        <LogoModal
          v-if="showLogoModal"
          @close="showLogoModal = false"
          :fileUploaded="fileUploaded"
          :fileName="fileName"
          :fileSize="fileSize"
          :fileExtension="fileExtension"
          :fileOriginal="fileOriginal"
          :logoSide="logoSide"
          :isVector="isVector"
          :product_type="product_type"
          v-on="{ updateSrc }"
        ></LogoModal>

        <template v-if="activeProductType === 'main_product'">
          <ProductNavigation
            v-if="!showCompanionModal"
            :back="{
              link: mainProductBackLink,
              label: 'select layout',
              button: '≪ BACK',
            }"
            :next="{
              link: '',
              label: '',
              button: 'COMPLETE DESIGN',
              event: hasCompanionItems ? 'companion' : 'complete',
            }"
            :nextEventEnabled="true"
          />
        </template>

        <template v-else>
          <ProductNavigation
            :back="{
              link: companionProductBackLink.url,
              label: companionProductBackLink.label,
              button: '≪ BACK',
            }"
            :next="{
              link: 'complete',
              label: '',
              button: 'COMPLETE DESIGN',
              event: 'complete',
            }"
            :nextEventEnabled="true"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import CONSTANTS from "@/constants.js";
import axios from "axios";
import "vue-select/dist/vue-select.css";

import ProductNavigation from "@/components/single-product/ProductNavigation.vue";
import ProductInformation from "@/components/single-product/ProductInformation";
import LayoutBox from "@/components/single-product/LayoutBox.vue";
import LogoLayout from "@/components/single-product/LogoLayout.vue";
import LayoutRow from "@/components/single-product/LayoutRow";
import LayoutArtwork from "@/components/single-product/LayoutArtwork";
import LogoModal from "@/components/single-product/LogoModal";
import CompanionItemsModal from "@/components/single-product/CompanionItemsModal";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "LayoutEditor",
  props: ["activeProduct", "product", "designsCount", "product_type"],
  mounted() {
    if (this.productLayout.artworks) {
      this.updateArtworks({
        productType: "main_product",
        artworks: this.productLayout.artworks,
      });
    }
    this.logo_option_selected = this.logo_options[this.logo_options.length - 1]; //Select the last option by default
  },
  methods: {
    ...mapActions(["setDefaultLogo", "updateLogo", "updateArtworks"]),
    updateSrc(payload) {
      this.logoSrc = payload.value;
      this.showLogoModal = false;
      this.updateLogo(payload);
    },
    removeCurrentLogo() {
      this.logoSrc = "";
      this.fileName = "";
      this.fileExtension = "";
      this.fileSize = "";

      //if (!this.activeProduct.front.logo.url.length) {
      this.setDefaultLogo({
        product_type: this.product_type + "_product",
        side: "front",
        field: "logo",
      });
      //}
    },
    async uploadFile(data) {
      this.showLogoModal = true;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.logoSide = data.side;
      this.file = data.file;
      this.fileName = this.file.name;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];

      let formData = new FormData();
      formData.append("file", this.file);
      const response = await axios.post(
        this.settings.endpoint + "api/v2/upload/logo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.fileUploaded = response.data.content;
      this.fileOriginal = response.data.url;
    },
  },
  computed: {
    ...mapState(["settings", "companion_items", "teamLink"]),
    ...mapGetters(["dealer", "activeProductType"]),
    layouts() {
      return this.product.layouts;
    },
    hasTeamLink() {
      return (
        this.teamLink != null &&
        Object.keys(this.teamLink).length &&
        this.teamLink.id
      );
    },
    design() {
      return this.activeProduct.design;
    },
    isVariantStock() {
      return this.product.variants ? this.product.variants.length > 0 : false;
    },
    productLayout() {
      if (this.active_layout) {
        return this.active_layout;
      }
      return this.product && this.layouts ? this.layouts[0] : false;
    },
    hideLogoSize() {
      if (this.dealer && this.dealer.settings) {
        return this.dealer.settings.hide_logo_size;
      }
      return false;
    },
    mainProductBackLink() {
      return this.designsCount > 1
        ? "product"
        : this.hasTeamLink
        ? ""
        : "catalogue";
    },
    companionProductBackLink() {
      return this.designsCount > 1
        ? {
            url: "product",
            label: "Back to previous item", //todo check if has layouts or designs the main
          }
        : {
            url: "companion-editor",
            label: "Designs",
          };
    },
    isVector() {
      return ["ai", "svg", "eps"].includes(this.fileExtension);
    },
    hasCompanionItems() {
      return this.companion_items && this.companion_items.length > 0;
    },
    showCompanionModal() {
      return this.showCompanionItemsModal;
    },
    active_layout() {
      return !this.activeProduct.reversibleActive
        ? this.activeProduct.layout
        : this.activeProduct.reversible_layout;
    },

    has_active_layout() {
      return (
        Object.keys(this.active_layout) &&
        Object.keys(this.active_layout).length > 0
      );
    },
    which_sides_has_logo() {
      let layers = CONSTANTS.LAYER.SIDES,
        $return = [];
      if (this.layout_has_logo) {
        for (let index = 0; index < layers.length; index++) {
          const layer = layers[index];
          if (
            this.active_layout.layout_attributes[layer] &&
            this.active_layout.layout_attributes[layer].logo
          ) {
            $return.push(layer);
          }
        }
      }
      return $return;
    },
    layout_has_logo() {
      return (
        this.active_layout.layout_attributes &&
        this.active_layout.layout_attributes != null &&
        ((this.active_layout.layout_attributes.front &&
          this.active_layout.layout_attributes.front.logo) ||
          (this.active_layout.layout_attributes.side &&
            this.active_layout.layout_attributes.side.logo) ||
          (this.active_layout.layout_attributes.back &&
            this.active_layout.layout_attributes.back.logo))
      );
    },
  },
  components: {
    ProductNavigation,
    ProductInformation,
    LayoutBox,
    LayoutRow,
    LogoLayout,
    LayoutArtwork,
    LogoModal,
    CompanionItemsModal,
  },
  data() {
    return {
      layerSides: CONSTANTS.LAYER.SIDES,
      layerFields: CONSTANTS.LAYER.MAIN_FIELDS,
      file: "",
      showLogoModal: false,
      showCompanionItemsModal: false,
      fileUploaded: "",
      fileOriginal: "",
      fileName: "",
      fileExtension: "",
      fileSize: "",
      logoSide: false,
      logoSrc: this.activeProduct.front.logo
        ? this.activeProduct.front.logo.url !== CONSTANTS.DEFAULT_LOGO_URL
          ? this.activeProduct.front.logo.url
          : ""
        : "",
      logo_options: ["12 x 14", "12 x 15"],
      logo_option_selected: null,
    };
  },
};
</script>

<style lang="scss">
#app {
  #container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    @media screen and (max-width: 1024px) {
      flex-flow: column;
    }
  }
}
</style>