<template>
  <section class="page container">
    <PageHeading :product_type="'companion'"></PageHeading>
    <LayoutEditor
      :activeProduct="activeProduct"
      :product="product"
      :designsCount="designsCount"
      :product_type="'companion'"
    />
  </section>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import CONSTANTS from "@/constants.js";

import PageHeading from "@/components/PageHeading";
import LayoutEditor from "@/components/single-product/LayoutEditor.vue";

export default {
  data() {
    return {
      layerSides: CONSTANTS.LAYER.SIDES,
      layerFields: CONSTANTS.LAYER.MAIN_FIELDS,
      file: "",
      product: {},
      design: {},
      showLogoModal: false,
      showCompanionItemsModal: false,
      fileUploaded: "",
      fileOriginal: "",
      fileName: "",
      fileExtension: "",
      fileSize: "",
      logoSide: false,
      logoSrc: this.$store.state.companion_product.front.logo
        ? this.$store.state.companion_product.front.logo.url !==
            CONSTANTS.DEFAULT_LOGO_URL &&
          this.$store.state.companion_product.front.logo.url != ""
          ? this.$store.state.companion_product.front.logo.url
          : ""
        : "",
      logo_options: ["12 x 14", "12 x 15"],
      logo_option_selected: null,
    };
  },
  components: {
    PageHeading,
    LayoutEditor,
  },
  computed: {
    ...mapGetters(["activeProduct"]),
    isVariantStock() {
      return this.product.variants ? this.product.variants.length > 0 : false;
    },
    productLayout() {
      if (this.active_layout) {
        return this.active_layout;
      }
      return this.product && this.product.layouts
        ? this.product.layouts[0]
        : false;
    },
    hideLogoSize() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        return this.$store.state.dealer.settings.hide_logo_size;
      }
      return false;
    },

    designsCount() {
      return this.productExists && !this.product.is_sublimable
        ? this.product.variants && this.product.variants.length
        : this.product.designs_by_gender
        ? this.product.designs_by_gender[this.gender].length
        : 0;
    },
    gender() {
      return this.$store.state.gender;
    },
    isVector() {
      return ["ai", "svg", "eps"].includes(this.fileExtension);
    },
    hasCompanionItems() {
      return (
        this.$store.state.companion_items &&
        this.$store.state.companion_items.length > 0
      );
    },
    companion_data_loaded() {
      return this.$store.state.companion_items_loaded;
    },
    showCompanionModal() {
      return this.showCompanionItemsModal;
    },
    active_layout() {
      return this.$store.state.companion_product.layout;
    },
    artworks() {
      return this.$store.state.companion_product.front.artwork_data; //todo
    },
    has_active_layout() {
      return (
        Object.keys(this.active_layout) &&
        Object.keys(this.active_layout).length > 0
      );
    },
    which_sides_has_logo() {
      let layers = CONSTANTS.LAYER.SIDES,
        $return = [];
      if (this.layout_has_logo) {
        for (let index = 0; index < layers.length; index++) {
          const layer = layers[index];
          if (
            this.active_layout.layout_attributes[layer] &&
            this.active_layout.layout_attributes[layer].logo
          ) {
            $return.push(layer);
          }
        }
      }
      return $return;
    },
    layout_has_logo() {
      return (
        this.active_layout.layout_attributes &&
        this.active_layout.layout_attributes != null &&
        ((this.active_layout.layout_attributes.front &&
          this.active_layout.layout_attributes.front.logo) ||
          (this.active_layout.layout_attributes.side &&
            this.active_layout.layout_attributes.side.logo) ||
          (this.active_layout.layout_attributes.back &&
            this.active_layout.layout_attributes.back.logo))
      );
    },
  },
  methods: {
    ...mapActions(["setDefaultLogo", "updateLogo"]),
    updateSrc(payload) {
      this.logoSrc = payload.value;
      this.showLogoModal = false;
      this.updateLogo(payload);
    },
    productExists() {
      return this.product && Object.keys(this.product).length > 0;
    },
    removeCurrentLogo() {
      this.logoSrc = "";
      this.fileName = "";
      this.fileExtension = "";
      this.fileSize = "";

      this.setDefaultLogo({
        product_type: "companion_product",
        side: "front",
        field: "logo",
      });
      //}
    },
    async uploadFile(data) {
      this.showLogoModal = true;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.logoSide = data.side;
      this.file = data.file;
      this.fileName = this.file.name;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];

      let formData = new FormData();
      formData.append("file", this.file);
      const response = await axios.post(
        this.$store.state.settings.endpoint + "api/v2/upload/logo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.fileUploaded = response.data.content;
      this.fileOriginal = response.data.url;
    },
  },
  mounted() {
    this.$store.commit("lockStates", ["sport", "gender"]);
    this.$store.commit("unlockStates", ["colors"]);

    this.$store.commit("updateSubState", {
      state: "locked",
      substate: "event",
      value: false,
    });

    this.$store.commit("updateState", {
      state: "activeProduct",
      value: "companion_product",
    });

    this.product = this.$store.state["companion_product"].product;
    this.design = this.$store.state["companion_product"].design;

    if (!this.productExists()) {
      //Required field for this view
      this.$store.dispatch("redirectToPageWithParams", {
        name: "Home",
      });
    } else {
      if (!this.has_active_layout && this.productExists()) {
        this.$store.commit("updateSubState", {
          state: "companion_product",
          substate: "layout",
          value: this.product.layouts[0],
        });
      }
      if (!this.$store.state.companion_product.front.logo) {
        this.setDefaultLogo({
          product_type: "companion_product",
          side: "front",
          field: "logo",
        });
      }
    } //TODO set the right breadcrumbs

    /*if (this.designsCount > 1) {
      this.$store.dispatch("updateBreadcrumbs", [
        {
          name: "Product Designs",
          component: "Product",
        },
        {
          name: "Product Layouts",
          component: "MainProductS2",
        },
      ]);
    } else {
      this.$store.dispatch("updateBreadcrumbs", [
        {
          name: "Product Designs",
          component: "Product",
        },
      ]);
    }*/ this.logo_option_selected =
      this.logo_options[this.logo_options.length - 1]; //Select the last option by default
  },
};
</script>

<style lang="scss">
section.page.container {
  @media screen and (max-width: 1024px) {
    padding-bottom: 5rem;
  }
}
.product-and-customization-container {
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
#app {
  .layouts-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-bottom: 12px;
  }
  .layout-editor-container {
    .layout-logo {
      margin-bottom: 16px;
      .layoyt-heading {
        padding-bottom: 5.5px;
        border-bottom: 1px solid $lighterGray;
        margin-bottom: 7.5px;
        h3 {
          text-transform: uppercase;
          color: $darkGray;
        }
        ::v-deep .v-input--switch {
          .v-input__control {
            .v-input__slot {
              .v-input--selection-controls__input {
                width: 26px;
                height: 12px;
                input {
                  max-height: 12px;
                }
                .v-input--switch__track {
                  height: 12px;
                  width: 26px;
                  top: 0;
                  left: 0;
                  &.black--text {
                    color: $black;
                    caret-color: $black;
                    opacity: 1;
                  }
                }
                .v-input--switch__thumb {
                  width: 8px;
                  height: 8px;
                  top: 2px;
                  left: 2px;
                  color: $black;
                  &.black--text {
                    color: $white;
                  }
                }
              }
            }
            .v-messages {
              display: none;
            }
          }
          &.v-input--is-dirty {
            .v-input__control {
              .v-input__slot {
                .v-input--selection-controls__input {
                  .v-input--switch__thumb {
                    transform: translate(14px, 0) !important;
                  }
                }
              }
            }
          }
        }
      }
      .layout-row {
        .vertical-line {
          height: 45px;
        }

        .logo-size {
          width: 130px;
          p {
            color: $blackGray;
            font-size: 10px;
            line-height: 11px;
            letter-spacing: 0.76px;
            margin-bottom: 6px;
          }
          .v-select {
            border-radius: 15px;
            ::v-deep .vs__dropdown-toggle {
              border-radius: 15px;
              border: none;
              .vs__selected-options {
                padding: 3px 0;
                .vs__selected {
                  width: 100%;
                  justify-content: center;
                  color: $black;
                  line-height: 22px;
                  letter-spacing: 1.14px;
                  text-transform: uppercase;

                  &:after {
                    content: "";
                    width: 11px;
                    height: 19px;

                    background-image: url("~@/assets/icons/arrow_down.svg");
                    background-position: center right;
                    background-repeat: no-repeat;
                    background-size: 11px 6.5px;
                    padding-left: 5.5px;
                    border-left: 1px solid $lighterGray;

                    position: absolute;
                    right: 7px;
                  }
                }
              }
              .vs__actions {
                display: none;
              }
            }
            ::v-deep ul {
              text-align: center;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              border: none;
              li {
                padding: 2px 0;
                &.vs__dropdown-option--highlight {
                  background: $black;
                }
              }
            }
            &:not(.vs--open) {
              ::v-deep .vs__search {
                height: 0;
                border: none;
              }
            }
            &.vs--open {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              ::v-deep .vs__dropdown-toggle {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                .vs__selected-options {
                  .vs__selected {
                    &:after {
                      right: 6px;
                    }
                  }
                }
              }
            }

            &.logo-size-input {
              width: 130px;
              ::v-deep .vs__selected {
                font-family: $ubuntuMedium;
                font-size: 15px;
                padding-right: 12px;
              }
              ::v-deep ul {
                min-width: 70px;
                li {
                  font-family: $ubuntuMedium;
                  font-size: 15px;
                  letter-spacing: 1.14px;
                  padding-right: 12px;
                }
              }
            }
          }
        }
        .not-vector-container {
          position: relative;
          &:hover {
            .bubble {
              display: block;
            }
          }
          .bubble {
            display: none;
            position: absolute;
            width: 262px;
            bottom: 40px;
            right: 0;
            background-color: rgba($whiteGray, 0.94);
            border-radius: 8px;
            border: 1px solid $lightGray;
            padding: 10px 10px;
            box-shadow: 0 3px 6px rgba($black, 0.16);
            p {
              color: $black;
            }
          }
          .color-red {
            font-family: $ubuntuMedium;
            font-size: 12px;
            line-height: 13px;
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          & > * {
            margin: 1rem;
          }
        }
      }
    }
  }
  .upload-logo {
    margin-top: 21px;
    input[type="file"] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    label {
      width: 100%;
      height: 41px;
      background-color: $whiteGray;

      text-overflow: ellipsis;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
      span {
        color: $darkerGray;
        font-family: $ubuntuRegular;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
</style>