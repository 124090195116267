<template>
  <transition name="modal">
    <div class="modal-container flex justify-center align-center" :style="fullScreen ? 'position: fixed; z-index: 1;' : 'position: absolute;'">
      <div class="modal-interior-container box-shadow" v-if="step === 1">
        <h2>Upload Logo</h2>
        <div class="file-container flex align-center justify-start">
          <div class="file-extension flex align-center justify-center">
            <h5>{{ fileExtension }}</h5>
          </div>
          <div class="file-name">
            <p>{{ fileName }}</p>
            <p>{{ fileSize }}</p>
            <v-progress-linear
              v-if="!has_image"
              :active="true"
              :background-opacity="0.3"
              :bottom="false"
              :buffer-value="100"
              :height="8"
              :indeterminate="true"
              :query="false"
              :rounded="true"
              :stream="false"
              :striped="true"
              :top="false"
              :value="'0%'"
            ></v-progress-linear>
          </div>
        </div>
        <div v-if="!showCropper" class="image-container flex justify-center align-center">
          <img
            v-if="has_image"
            style="max-height: 200px;"
            :src="fileData ? fileData : fileUploaded"
          />
        </div>
        <div class="cropper-container">
          <vue-cropper v-if="showCropper" ref="cropper" :src="fileData" alt="Source Image"></vue-cropper>
          <div class="buttons-container" v-if="this.showCropper">
            <button class="btn white-btn" type="button" @click="toggleCropper()">Cancel</button>
            <button class="btn black-btn" type="button" @click="cropImage()">Crop</button>
          </div>
        </div>
        <div class="buttons-container" v-if="!this.showCropper">
          <button class="btn white-btn" type="button" @click="$emit('close')">Cancel</button>
          <button v-if="has_image" class="btn white-btn" type="button" @click="toggleCropper()">Edit image</button>
          <button v-if="has_image" class="btn black-btn" type="button" @click="setStep(2)">Continue</button>
        </div>
      </div>
      <div class="modal-interior-container box-shadow" v-if="step === 2">
        <div class="file-container flex align-center justify-start">
          <div class="file-extension flex align-center justify-center">
            <h5>{{fileExtension}}</h5>
          </div>
          <div class="file-name">
            <p>{{ fileName }}</p>
            <p>{{ fileSize }}</p>
          </div>
        </div>
        <div class="flex cropped-container">
          <div class="cropped-image-container">
            <img id="remove-background-img" :src="fileData" style="max-width:100%;" @click="setBackgroundColor($event)" :class="{ editing: backgroundMode }"/>
          </div>
          <div v-if="!isVector" class="text-container">
            <button
              class="btn white-btn"
              type="button"
              @click="startRemoveBackground()"
            >{{ backgroundMode === 0 ? "Remove background" : "Cancel" }}</button>
            <p v-if="backgroundMode === 1">Click the image and select the color you want to remove.</p>
            <div v-if="!tempHideVectorInfo" class="flex flex-column">
              <h5>NOT VECTOR</h5>
              <p>Unfortunately, the logo uploaded does not meet the quality requirements. Please allow an extra 48 hours of production time for our team of artists to vectorize this logo for production.</p>
            </div>
          </div>
          <div v-else>
            <div v-if="!tempHideVectorInfo" class="flex flex-column">
              <h5>VECTOR</h5>
              <p>The uploaded logo meets the quality requirements.</p>
            </div>
          </div>
        </div>
        <div class="buttons-container" v-if="!this.showCropper">
          <button class="btn white-btn" type="button" @click="$emit('close')">Cancel</button>
          <button class="btn white-btn" type="button" @click="setStep(1)">Back</button>
          <button class="btn black-btn" type="button" @click="saveCropper()">Continue</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
import { mapGetters } from 'vuex';

  export default {
    name: "LogoModal",
    props: ['fileUploaded', 'fileName', 'fileExtension', 'fileSize', 'fileOriginal', 'isVector', 'product_type', 'logoSide', 'fullScreen'],
    components: {
      VueCropper,
    },
    data() {
      return {
        //fileName: this.$parent.fileName,
        //fileExtension: this.$parent.fileExtension,
        fileData: '',
        //fileIsVector: false,
        showCropper: false,
        backgroundMode: 0,
        step: 1,

        tempHideVectorInfo: true,
      }
    },
    computed : {
      ...mapGetters(["activeProduct"]),
      reversibleActive() {
        return this.activeProduct.reversibleActive;
      },
      has_image() {
        return this.fileOriginal.length > 0;
      }
    },
    methods : {
      toggleCropper() {
        this.updateFileData();
        this.showCropper = !this.showCropper;
      },
      cropImage() {
        //mutate and save data
        this.fileData = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.showCropper = false;
      },
      setStep(number) {
        this.updateFileData();
        this.step = number;
      },
      updateFileData() {
        if (!this.fileData) this.fileData = this.fileUploaded;
      },
      saveCropper() {
        //mutate and save data
        
        this.$emit("updateSrc", {
          product_type: this.product_type,
          url: this.fileData,
          original_url: this.fileOriginal,
          side: this.reversibleActive ? "reversible_" + this.logoSide : this.logoSide,
        })
        //this.$parent.logoSrc = this.fileData;
        //this.$parent.showLogoModal = false;
        /*this.$store.commit("updateSubStateField", {
          state: this.product_type + '_product',
          substate: this.logoSide,
          field: 'logo',
          value: {
            url: this.fileData,
            original_url: this.fileOriginal,
          }
        });*/
      },
      startRemoveBackground() {
        this.backgroundMode = this.backgroundMode ? 0 : 1;
      },
      setBackgroundColor(event) {
        let image = document.getElementById("remove-background-img");
        let widthRate = image.naturalWidth / image.width,
            heightRate = image.naturalHeight / image.height;

        if (this.backgroundMode === 1) {  //Calculate the clicked coordinate
          let x = event.offsetX * widthRate,
              y = event.offsetY * heightRate;
          this.removeBackground(x, y);
        }
      },
      removeBackground(x, y) {
        if (this.fileData) {
          let image = document.getElementById("remove-background-img"),
              canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d"), r, g, b;

          canvas.height = image.naturalHeight; 
          canvas.width = image.naturalWidth;
          ctx.drawImage(image, 0, 0);
          let pixelData = canvas.getContext('2d').getImageData(x, y, 1, 1).data;
          let imgd = ctx.getImageData(0, 0, image.naturalWidth, image.naturalHeight),
            pix = imgd.data,
            newColor = { //set transparent color
              r: 0,
              g: 0,
              b: 0,
              a: 0
            };
          for (let i = 0, n = pix.length; i < n; i += 4) {
            r = pix[i];
            g = pix[i + 1];
            b = pix[i + 2];
            if(r == pixelData[0] && g == pixelData[1] && b == pixelData[2]) { //color matches the clicked one
                // Change the white to the new color.
                pix[i] = newColor.r;
                pix[i+1] = newColor.g;
                pix[i+2] = newColor.b;
                pix[i+3] = newColor.a;
            }
          }
          ctx.putImageData(imgd, 0, 0); 
          this.fileData = canvas.toDataURL("image/png");
          this.backgroundMode = 0;
        }
      }
    },
    created() {
    }
  }
</script>

<style lang="scss" scoped>
#app {
  .modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($lightGray, 0.75);
    .modal-interior-container {
      width: 494px;
      padding: 20px;

      background-color: $white;
      h2 {
        color: $black;
        font-family: $ubuntuMedium;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        padding-bottom: 7.5px;
        border-bottom: 1px solid $lighterGray;
        margin-bottom: 14.5px;
      }
      .file-container {
        margin-bottom: 20px;
        .file-extension {
          width: 38px;
          height: 38px;
          background: linear-gradient(to bottom, $darkerGray, $black);
          border-radius: 5px;
          margin-right: 22px;
          h5 {
            color: $white;
            letter-spacing: 0;
            text-transform: uppercase;
          }
        }
        .file-name {
          flex: 1;
          p {
            color: $gray;
            font-family: $ubuntuRegular;
            font-size: 15px;
            line-height: 17px;
            margin-bottom: 8px;
          }
          .v-progress-linear {
            ::v-deep .v-progress-linear__background.black,
            ::v-deep .v-progress-linear__determinate.black {
              background-color: $black;
              color: $black;
            }
            ::v-deep .v-progress-linear__determinate.black {
              background-image: repeating-linear-gradient(
                45deg,
                transparent 0 5px,
                rgba($white, 0.5) 5px 10px
              );
              background-size: unset;
            }
          }
        }
      }
      .image-container {
        margin-bottom: 20px;
        img {
          background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
          max-width: 100%;
        }
      }
      .cropper-container {
        ::v-deep .cropper-bg {
          margin-bottom: 20px;
        }
      }
      .cropped-container {
        margin-bottom: 25px;
        .cropped-image-container {
          margin-right: 22px;
          img {
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
            border: 1px solid $black;
            max-width: 190px !important;
            max-height: 250px;
            &.editing {
              cursor: crosshair;
            }
          }
        }
        .text-container {
          .btn {
            margin-bottom: 16px;
          }
          h5 {
            color: $red;
            margin-bottom: 9px;
          }
          p {
            color: $darkGray;
          }
        }
      }
      .buttons-container {
        text-align: right;
        .white-btn {
          border-color: $white;
          margin-right: 4px;
          &:hover {
            border-color: $black;
          }
        }
      }
    }
  }
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>