import { render, staticRenderFns } from "./LayoutRow.vue?vue&type=template&id=b124d920&scoped=true&"
import script from "./LayoutRow.vue?vue&type=script&lang=js&"
export * from "./LayoutRow.vue?vue&type=script&lang=js&"
import style0 from "./LayoutRow.vue?vue&type=style&index=0&id=b124d920&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b124d920",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSelect,VSwitch})
