<template>
  <section class="page container">
    <PageHeading :product_type="'main'"></PageHeading>
    <LayoutEditor
      :activeProduct="activeProduct"
      :product="product"
      :designsCount="designsCount"
      :product_type="'main'"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PageHeading from "@/components/PageHeading";
import LayoutEditor from "@/components/single-product/LayoutEditor.vue";

export default {
  components: {
    PageHeading,
    LayoutEditor,
  },
  computed: {
    ...mapGetters(["activeProduct", "getGender"]),
    product() {
      return this.activeProduct.product;
    },
    productExists() {
      return this.product && Object.keys(this.product).length > 0;
    },
    designsCount() {
      return this.productExists && !this.product.is_sublimable
        ? this.product.variants && this.product.variants.length
        : this.product.designs_by_gender
        ? this.product.designs_by_gender[this.getGender].length
        : 0;
    },
  },
  methods: {
    ...mapActions([
      "setActiveMainProduct",
      "updateProductField",
      "updateBreadcrumbs",
      "redirectToPageWithParams",
      "setDefaultLogo",
    ]),
    validateProduct() {
      if (!this.productExists) {
        //Required field for this view
        this.redirectToPageWithParams({
          name: "Home",
        });
      } else {
        if (!this.has_active_layout && this.productExists) {
          //reselect active layout by id to load its full info (we have only first artworks for example coming from catalogue)
          for (let index = 0; index < this.product.layouts.length; index++) {
            const layout = this.product.layouts[index];
            if (layout.id === this.activeProduct.layout.id) {
              this.updateProductField({
                field: "layout",
                value: layout,
              });
              break;
            }
          }
        }
        if (!this.activeProduct.front.logo) {
          this.setDefaultLogo({
            product_type: "main_product",
            side: "front",
            field: "logo",
          });
        }
      }
    },
    setBreadcrumbs() {
      if (this.designsCount > 1) {
        this.updateBreadcrumbs([
          {
            name: "Product Designs",
            component: "Product",
          },
          {
            name: "Product Layouts",
            component: "MainProductS2",
          },
        ]);
      } else {
        this.updateBreadcrumbs([
          {
            name: "Product Designs",
            component: "Product",
          },
        ]);
      }
    },
  },
  mounted() {
    this.$store.commit("lockStates", ["sport", "gender"]);
    this.$store.commit("unlockStates", ["colors"]);

    this.setActiveMainProduct();
    this.validateProduct();
    this.setBreadcrumbs();
  },
};
</script>

<style lang="scss">
#app {
  .layouts-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-bottom: 12px;
  }
  .layout-editor-container {
    .layout-logo {
      margin-bottom: 16px;
      .layoyt-heading {
        padding-bottom: 5.5px;
        border-bottom: 1px solid $lighterGray;
        margin-bottom: 7.5px;
        h3 {
          text-transform: uppercase;
          color: $darkGray;
        }
        ::v-deep .v-input--switch {
          .v-input__control {
            .v-input__slot {
              .v-input--selection-controls__input {
                width: 26px;
                height: 12px;
                input {
                  max-height: 12px;
                }
                .v-input--switch__track {
                  height: 12px;
                  width: 26px;
                  top: 0;
                  left: 0;
                  &.black--text {
                    color: $black;
                    caret-color: $black;
                    opacity: 1;
                  }
                }
                .v-input--switch__thumb {
                  width: 8px;
                  height: 8px;
                  top: 2px;
                  left: 2px;
                  color: $black;
                  &.black--text {
                    color: $white;
                  }
                }
              }
            }
            .v-messages {
              display: none;
            }
          }
          &.v-input--is-dirty {
            .v-input__control {
              .v-input__slot {
                .v-input--selection-controls__input {
                  .v-input--switch__thumb {
                    transform: translate(14px, 0) !important;
                  }
                }
              }
            }
          }
        }
      }
      .layout-row {
        .vertical-line {
          height: 45px;
        }

        .logo-size {
          width: 130px;
          p {
            color: $blackGray;
            font-size: 10px;
            line-height: 11px;
            letter-spacing: 0.76px;
            margin-bottom: 6px;
          }
          .v-select {
            border-radius: 15px;
            ::v-deep .vs__dropdown-toggle {
              border-radius: 15px;
              border: none;
              .vs__selected-options {
                padding: 3px 0;
                .vs__selected {
                  width: 100%;
                  justify-content: center;
                  color: $black;
                  line-height: 22px;
                  letter-spacing: 1.14px;
                  text-transform: uppercase;

                  &:after {
                    content: "";
                    width: 11px;
                    height: 19px;

                    background-image: url("~@/assets/icons/arrow_down.svg");
                    background-position: center right;
                    background-repeat: no-repeat;
                    background-size: 11px 6.5px;
                    padding-left: 5.5px;
                    border-left: 1px solid $lighterGray;

                    position: absolute;
                    right: 7px;
                  }
                }
              }
              .vs__actions {
                display: none;
              }
            }
            ::v-deep ul {
              text-align: center;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              border: none;
              li {
                padding: 2px 0;
                &.vs__dropdown-option--highlight {
                  background: $black;
                }
              }
            }
            &:not(.vs--open) {
              ::v-deep .vs__search {
                height: 0;
                border: none;
              }
            }
            &.vs--open {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              ::v-deep .vs__dropdown-toggle {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                .vs__selected-options {
                  .vs__selected {
                    &:after {
                      right: 6px;
                    }
                  }
                }
              }
            }

            &.logo-size-input {
              width: 130px;
              ::v-deep .vs__selected {
                font-family: $ubuntuMedium;
                font-size: 15px;
                padding-right: 12px;
              }
              ::v-deep ul {
                min-width: 70px;
                li {
                  font-family: $ubuntuMedium;
                  font-size: 15px;
                  letter-spacing: 1.14px;
                  padding-right: 12px;
                }
              }
            }
          }
        }
        .not-vector-container {
          position: relative;
          &:hover {
            .bubble {
              display: block;
            }
          }
          .bubble {
            display: none;
            position: absolute;
            width: 262px;
            bottom: 40px;
            right: 0;
            background-color: rgba($whiteGray, 0.94);
            border-radius: 8px;
            border: 1px solid $lightGray;
            padding: 10px 10px;
            box-shadow: 0 3px 6px rgba($black, 0.16);
            p {
              color: $black;
            }
          }
          .color-red {
            font-family: $ubuntuMedium;
            font-size: 12px;
            line-height: 13px;
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          & > * {
            margin: 1rem;
          }
        }
      }
    }
  }
  .upload-logo {
    margin-top: 21px;
    input[type="file"] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    label {
      width: 100%;
      height: 41px;
      background-color: $whiteGray;
      margin-bottom: 10px;
      text-overflow: ellipsis;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
      span {
        color: $darkerGray;
        font-family: $ubuntuRegular;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
</style>

<style lang="scss">
#app {
  section.page.container {
    @media screen and (max-width: 1024px) {
      padding-bottom: 8.5rem;
    }
  }
  .product-and-customization-container {
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>