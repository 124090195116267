var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"layout-editor"},[(_vm.showCompanionModal && _vm.activeProductType === 'main_product')?_c('CompanionItemsModal'):_vm._e(),(_vm.product)?_c('div',{staticClass:"flex justify-space-between",attrs:{"id":"container"}},[_c('ProductInformation',{attrs:{"name":_vm.product.name,"design":_vm.design,"backgrounds":_vm.product.backgrounds,"tier":_vm.product.tier_id,"layout":_vm.productLayout,"product_type":_vm.product_type,"description":_vm.product.description,"is_stock":!_vm.product.is_sublimable,"isVariantStock":_vm.isVariantStock}}),_c('div',{staticClass:"right-box-container"},[_c('div',{staticClass:"right-box-interior-container"},[_c('h2',{directives:[{name:"show",rawName:"v-show",value:(_vm.layouts && _vm.layouts.length > 1),expression:"layouts && layouts.length > 1"}]},[_vm._v("Layouts")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.layouts && _vm.layouts.length > 1),expression:"layouts && layouts.length > 1"}],staticClass:"layouts-container"},_vm._l((_vm.layouts),function(layout,index){return _c('LayoutBox',{key:index,attrs:{"layout":layout,"product_type":_vm.product_type}})}),1),(this.has_active_layout)?_c('div',{staticClass:"layout-editor-container"},[(
              this.has_active_layout && this.active_layout.layout_attributes
            )?_c('div',{staticClass:"layout-editor"},_vm._l((_vm.layerSides),function(layerSide,index){return _c('div',{key:index,staticClass:"layout-row-outer"},[(_vm.active_layout.layout_attributes[layerSide])?_c('h2',[_vm._v(" Customize the "+_vm._s(layerSide)+" ")]):_vm._e(),_vm._l((_vm.layerFields),function(layerField,indey){return _c('div',{key:indey,staticClass:"layout-row-inner"},[(
                    _vm.active_layout.layout_attributes[layerSide] &&
                    _vm.active_layout.layout_attributes[layerSide][layerField]
                  )?_c('LayoutRow',{attrs:{"active_layout":_vm.active_layout,"side":layerSide,"field":layerField,"product_type":_vm.product_type,"product_tier":_vm.product.tier_id}}):_vm._e()],1)}),(
                  _vm.active_layout.layout_attributes[layerSide] &&
                  _vm.active_layout.layout_attributes[layerSide].logo
                )?_c('LogoLayout',_vm._g({attrs:{"hideLogoSize":_vm.hideLogoSize,"active_layout":_vm.active_layout,"layerSide":layerSide,"index":index,"productType":'main'}},{ uploadFile: _vm.uploadFile })):_vm._e(),(
                  _vm.active_layout.layout_attributes[layerSide] &&
                  _vm.active_layout.layout_attributes[layerSide].artwork
                )?_c('LayoutArtwork',{attrs:{"activeLayout":_vm.active_layout,"productType":'main',"side":layerSide,"product_tier":_vm.product.tier_id}}):_vm._e()],2)}),0):_vm._e()]):_vm._e()]),(_vm.showLogoModal)?_c('LogoModal',_vm._g({attrs:{"fileUploaded":_vm.fileUploaded,"fileName":_vm.fileName,"fileSize":_vm.fileSize,"fileExtension":_vm.fileExtension,"fileOriginal":_vm.fileOriginal,"logoSide":_vm.logoSide,"isVector":_vm.isVector,"product_type":_vm.product_type},on:{"close":function($event){_vm.showLogoModal = false}}},{ updateSrc: _vm.updateSrc })):_vm._e(),(_vm.activeProductType === 'main_product')?[(!_vm.showCompanionModal)?_c('ProductNavigation',{attrs:{"back":{
            link: _vm.mainProductBackLink,
            label: 'select layout',
            button: '≪ BACK',
          },"next":{
            link: '',
            label: '',
            button: 'COMPLETE DESIGN',
            event: _vm.hasCompanionItems ? 'companion' : 'complete',
          },"nextEventEnabled":true}}):_vm._e()]:[_c('ProductNavigation',{attrs:{"back":{
            link: _vm.companionProductBackLink.url,
            label: _vm.companionProductBackLink.label,
            button: '≪ BACK',
          },"next":{
            link: 'complete',
            label: '',
            button: 'COMPLETE DESIGN',
            event: 'complete',
          },"nextEventEnabled":true}})]],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }