var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"design-editor"},[_c('ProductInformation',{ref:'productSlider',attrs:{"name":_vm.product.name,"backgrounds":_vm.product.backgrounds,"tier":_vm.activeProduct.tier_id,"layout":_vm.productLayout,"product_type":_vm.product_type,"description":_vm.product.description,"is_stock":!_vm.product.is_sublimable,"isVariantStock":_vm.isVariantStock}}),_c('div',{staticClass:"right-box-container"},[_c('div',{staticClass:"right-box-container-interior-container"},[_c('h2',[_vm._v(_vm._s(_vm.productVariationsTitle))]),_c('div',{staticClass:"design-boxes-container"},_vm._l((_vm.designs),function(item,index){return _c('DesignBox',_vm._g({key:index,attrs:{"design":item,"backgrounds":_vm.product.backgrounds,"tier":_vm.product.tier_id,"layout":_vm.productLayout,"product_type":_vm.product_type,"product_is_stock":!_vm.product.is_sublimable,"isVariantStock":_vm.isVariantStock,"styleId":_vm.product.style_id}},{ designUpdated: _vm.scrollToSlider }))}),1)]),(_vm.activeProductType === 'main_product')?[(_vm.product.layouts && _vm.product.layouts.length)?_c('ProductNavigation',{attrs:{"back":{
          link: _vm.backlink,
          label: 'select style',
          button: '≪ BACK',
          disabled: this.editMode,
        },"next":{ link: 'layout', label: 'Select layout', button: 'NEXT ≫' }}}):_c('ProductNavigation',{attrs:{"back":{
          link: _vm.backlink,
          label: 'select style',
          button: '≪ BACK',
          disabled: this.editMode,
        },"next":{
          link: '',
          label: '',
          button: 'COMPLETE DESIGN',
          event: _vm.hasCompanionItems ? 'companion' : 'complete',
        },"nextEventEnabled":true}})]:[(_vm.product.layouts && _vm.product.layouts.length)?_c('ProductNavigation',{attrs:{"back":{
          link: 'layout',
          label: 'return to previous item',
          button: '≪ BACK',
        },"next":{
          link: 'companion-layout',
          label: 'Select layout',
          button: 'NEXT ≫',
        }}}):_c('ProductNavigation',{attrs:{"back":{
          link: 'layout',
          label: 'return to previous item',
          button: '≪ BACK',
        },"next":{
          link: '',
          label: '',
          button: 'COMPLETE DESIGN',
          event: 'complete',
        },"nextEventEnabled":true}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }