<template>
  <transition name="modal">
    <div class="modal-wrapper">
      <div class="modal-container flex flex-start align-center flex-column">
        <div class="modal-interior-container box-shadow">
          <h2>Complete your uniform</h2>
          <div class="modal-body">
            <p>
              We recommend the following options to complete your uniform.
              You'll be able to choose the right colors on the next step. If you
              don't need any pants/shorts, click "Skip" to continue to your
              Locker.
            </p>
            <div id="companion-items">
              <div class="your-design flex flex-column align-center">
                <h5>YOUR DESIGN</h5>
                <CompanionItem :item="false" />
              </div>
              <VueSlickCarousel
                class="slider"
                ref="companion_slider"
                :arrows="true"
                :dots="false"
                :slidesToShow="3"
                :draggable="true"
                :focusOnSelect="false"
                :responsive="carouselResponsiveSettings"
              >
                <CompanionItem
                  v-for="(item, index) in items"
                  :key="index"
                  :item="item"
                  v-on:companionActive="updateActiveItem"
                  :isActive="active === item.id"
                  :filteredTargetColors="filteredTargetColors"
                  :hidePrice="hidePrice"
                />
              </VueSlickCarousel>
            </div>
            <div
              class="buttons-container flex justify-center"
              :class="{ loading: event_loading }"
            >
              <button class="btn white-btn" type="button" @click="hideModal">
                Back
              </button>
              <button class="btn white-btn" type="button" @click="complete">
                {{ event_loading ? "Loading" : "Skip" }}
              </button>
              <button
                class="btn black-btn"
                type="button"
                @click="selectCompanion"
                :class="{ disabled: !active }"
              >
                {{ event_loading ? "Loading" : "Customize" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import CompanionItem from "../single-product/CompanionItem";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
import CONSTANTS from "@/constants.js";

export default {
  name: "CompanionItemsModal",
  data() {
    return {
      active: 0,
      variantStockDesignSelected: null,
      carouselResponsiveSettings: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getColorByField"]),
    ...mapState(["activeProductCatalogId"]),
    hidePrice() {
      if (this.$store.state.dealer.teamlink) {
        if (this.$store.state.team_link.display_msrp === null) {
          return this.$store.state.dealer.teamlink.display_msrp === "false";
        }
        return this.$store.state.team_link.display_msrp === "false";
      }
      return this.$store.state.dealer.settings
        ? this.$store.state.dealer.settings.display_msrp === "false"
        : false;
    },
    filteredTargetColors() {
      //We need to get the first match for C1, C2 and C3 and then the backup colors
      let targetColors = [];
      for (let index = 1; index <= 3; index++) {
        targetColors.push(
          this.getColorByField({
            product_type: "main",
            field: "color_" + index,
          }).id
        );
      }
      return targetColors.concat(CONSTANTS.BACKUP_STOCK_COLOR_IDS).unique();
    },
    companion_item() {
      return this.$store.state.companion_product;
    },
    product() {
      return this.$store.state.main_product.product;
    },
    design() {
      return this.$store.state.main_product.design;
    },
    layout() {
      return this.$store.state.main_product.layout;
    },
    items() {
      return this.$store.state.companion_items;
    },
    gender() {
      return this.$store.state.gender;
    },
    event_loading() {
      return this.$store.state.locked.event;
    },
  },
  mounted() {
    this.resetReversible();
    this.resetCompanionColors();
  },
  methods: {
    ...mapActions(["resetReversible", "resetCompanionColors"]),
    hideModal() {
      if (!this.event_loading) {
        this.$parent.showCompanionItemsModal = false;
      }
    },
    updateActiveItem(item) {
      this.active = item.item.id;
      this.variantStockDesignSelected = item.variantStockDesignSelected;
    },
    complete() {
      this.$store.commit("resetProductState", {
        product_type: "companion",
      });
      if (!this.event_loading) {
        this.$store.commit("updateState", {
          state: "goToLocker",
          value: true,
        });
        this.$store.dispatch("completeUniform");
      }
    },
    async selectCompanion() {
      //request a companion product by id and redirect to the corresponding page
      this.$store.dispatch("regenerateTextStamps", "companion_product");
      if (this.active) {
        this.$store.commit("updateSubState", {
          state: "locked",
          substate: "event",
          value: true,
        });
        const response = await axios.get(
          this.$store.state.settings.endpoint + "api/v2/product",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.settings.token,
            },
            params: {
              product_id: this.active,
              catalog_id: this.activeProductCatalogId,
            },
          }
        );
        if (response) {
          let item = response.data;
          if (
            !this.$store.state.companion_product ||
            Object.keys(this.$store.state.companion_product).length === 0
          ) {
            this.$store.commit("updateState", {
              state: "companion_product",
              value: Object.assign({}, CONSTANTS.DEFAULT_PRODUCT_DATA),
            });
          }
          this.$store.commit("updateSubState", {
            state: "companion_product",
            substate: "product",
            value: item,
          });

          if (!this.variantStockDesignSelected) {
            this.$store.commit("updateSubState", {
              state: "companion_product",
              substate: "design",
              value: item.is_sublimable
                ? item.designs_by_gender[this.gender][0]
                : item.variants[0],
            });
          } else {
            this.$store.commit("updateSubState", {
              state: "companion_product",
              substate: "design",
              value: this.variantStockDesignSelected,
            });
          }
          if (item.layouts) {
            this.$store.commit("updateSubState", {
              state: "companion_product",
              substate: "layout",
              value: item.layouts[0],
            });
          }

          if (item.is_sublimable) {
            this.$store.dispatch("redirectToPageWithParams", {
              name: "CompanionLayouts",
            });
          } else {
            this.$store.dispatch("redirectToPageWithParams", {
              name: "CompanionEditor",
            });
          }
        }
      }
    },
  },
  components: {
    CompanionItem,
    VueSlickCarousel,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#b7b7b7, 0.79);
    z-index: 3;
    padding-top: 100px;
    .modal-interior-container {
      background-color: $white;
      h2 {
        color: $white;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1.52px;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        background-color: $blackGray;
      }
      .modal-body {
        padding: 20px 30px;

        p {
          text-align: center;
          max-width: 580px;
          margin: 0 auto 20px;
        }
        #companion-items {
          display: flex;
          align-items: flex-end;
          margin-bottom: 24px;
          .your-design {
            h5 {
              color: $blackGray;
              letter-spacing: 0;
            }
          }
          .slider {
            width: 100%;
            max-width: 678px;
            ::v-deep .slick-arrow {
              z-index: 1;
              &::before {
                color: black;
              }
              &.slick-next {
                right: 20px;
              }
              &.slick-prev {
                left: 20px;
              }
            }
          }
        }
        .buttons-container {
          .btn {
            min-width: 157px;
            margin: 0 4.5px;
          }
          .disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }
          &.loading {
            opacity: 0.3;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        width: 95vw;

        .modal-body {
          display: flex;
          flex-flow: column;
          padding: 1rem;

          #companion-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .slider .slick-list {
              min-width: 100% !important;
              padding: 0 3rem;
            }
          }

          .buttons-container {
            width: 100%;

            button.btn {
              min-width: 30%;
            }

            @media screen and (max-width: 569px) {
              flex-flow: column;

              button.btn {
                margin: 0;
                margin-bottom: 0.6rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>