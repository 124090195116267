<template>
  <div class="layout-artwork">
    <div class="layout-row-container">
      <p v-if="description && description.length" class="row-description">
        {{ description }}
      </p>
      <div class="layout-heading flex justify-space-between align-center">
        <h3>Select a Graphic Template</h3>
      </div>
      <div class="layout-row layout-row-column flex justify-space-between">
        <div class="artwork-options-container">
          <LayoutArtworkOption
            v-for="(artwork, index) in artworks"
            :key="index"
            :productType="productType"
            :side="side"
            :artwork="artwork"
            v-on="{ selectArtwork }"
            :activeId="activeArtwork"
          />
        </div>
        <div class="artwork-colors">
          <div class="layout-heading">
            <h3>Select colors for your graphic</h3>
          </div>
          <ColorPicker
            field="artwork_color_1"
            :product_type="'main'"
            :side="side"
            :isArtwork="true"
          />
          <ColorPicker
            field="artwork_color_2"
            :product_type="'main'"
            :side="side"
            :isArtwork="true"
          />
          <ColorPicker
            field="artwork_color_3"
            :product_type="'main'"
            :side="side"
            :isArtwork="true"
          />
        </div>
      </div>
      <LayoutRow
        :active_layout="activeLayout"
        :side="side"
        :field="'name'"
        :inputType="'text'"
        :product_type="productType"
        :label="'ADD A TEAM NAME'"
        :product_tier="product_tier"
        :isArtwork="true"
      ></LayoutRow>
    </div>
  </div>
</template>

<script>
import LayoutRow from "../../components/single-product/LayoutRow";
import LayoutArtworkOption from "../../components/single-product/LayoutArtworkOption";
import ColorPicker from "../../components/ColorPicker.vue";

export default {
  name: "LayoutArtwork",
  props: ["activeLayout", "productType", "side", "product_tier"],
  computed: {
    artworks() {
      return this.$store.state[this.productType + "_product"].layout.artworks;
    },
    description() {
      //could be overriden by dealer settings
      return "Choose from our options of pre-configured templates below and quickly customize the colors of your graphic. Add your team name on the top - you'll be able to choose the colors and the font for your team name.";
    },
    activeArtwork() {
      return this.$store.state[this.productType + "_product"][this.side]
        .artwork;
    },
  },
  watch: {
    /* artworks() {
      this.$store.commit("updateSubStateField", {
        state: [this.productType + "_product"],
        substate: this.side,
        field: "artwork",
        value: this.artworks[0].id,
      });
    }*/
  },
  methods: {
    selectArtwork(data) {
      this.$store.commit("updateSubStateField", {
        state: data.product,
        substate: data.side,
        field: "artwork",
        value: data.id,
      });
      this.activeId = data.id;
    },
  },
  data() {
    return {
      opened_pickers: {
        artwork_color_1: false,
        artwork_color_2: false,
        artwork_color_3: false,
      },
    };
  },
  created() {
    //Set first artwork as selected if none is
    if (!this.$store.state[this.productType + "_product"][this.side].artwork) {
      this.$store.commit("updateSubStateField", {
        state: [this.productType + "_product"],
        substate: this.side,
        field: "artwork",
        value: this.artworks[0].id,
      });
    }
  },
  components: {
    LayoutRow,
    ColorPicker,
    LayoutArtworkOption,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .row-description {
    font-family: "Ubuntu Light";
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 15px;
  }
  .layout-artwork {
    .layout-row-column {
      flex-direction: column;
      align-items: flex-start;
      .artwork-colors {
        display: flex;
        margin: 10px 0;
        .color-picker {
          margin: 0 10px !important;
        }
        .layout-heading {
          border: none;
        }

        @media screen and (max-width: 720px) {
          display: flex;
          flex-direction: column;
          .color-picker {
            margin-bottom: 1rem !important;
          }
          .color-picker-swatch {
            margin-bottom: 1rem;
          }
        }
      }
      .artwork-options-container {
        display: flex;
        margin: 15px 0;
        flex-wrap: wrap;
        .artwork-option {
          cursor: pointer;
          margin: 3px;
          width: 90px;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgb(240, 240, 240);
          -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
          -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
          &.active {
            border: 2px solid #000;
          }
        }

        @media screen and (max-width: 720px) {
          margin: 1rem auto;
        }
      }
    }
    .layout-heading {
      padding-bottom: 5.5px;
      border-bottom: 1px solid $lighterGray;
      margin-bottom: 7.5px;
      .layout-row-switch {
        display: flex;
        align-items: center;
        small {
          margin-right: 10px;
        }
      }
      h3 {
        text-transform: uppercase;
        color: $darkGray;
      }
    }
  }
}
</style>