<template>
  <div id="search-customization" class="page-bg">
    <div id="search-sidebar">
      <div class="head"></div>
      <div class="sidebar-body flex flex-column">
        <div class="interior-container">
          <h3 class="section-title">Sale Code</h3>
          <input
            v-model="search"
            @keydown="error = false; success = false;"
            placeholder="xxxxx"
          />
          <span v-if="error" class="flex align-center justify-center error-info"
            >{{ error }}</span
          >
          <span v-if="success" class="flex align-center justify-center success-info"
            >{{ success }}</span
          >
          <button
            v-if="!loading"
            type="button"
            class="btn black-btn"
            :class="{ disabled: loading || error || success }"
            @click="process()"
          >
            Search
          </button>
          <v-progress-circular
            class="loader"
            v-else
            width="2"
            size="15"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Orders",
  methods: {
    process() {
      if (this.loading || this.error || this.sucess) return false;
      this.loading = true;
      this.error = false;
      this.success = false;
      axios.post(this.$store.state.settings.endpoint + 'api/v2/complete-order',
      {
        sale_code: this.search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      },
      ).then(() => {
        this.loading = false;
        this.success = "Processed";
      }).catch((e) => {
        this.error = e.response.data;
        this.loading = false;
      });
    },
    submit() {
      if (this.loading) return false;
      this.loading = true;
      this.result = {};
      axios
        .get(
          this.$store.state.settings.endpoint + "api/v2/search-customization",
          {
            headers: {},
            params: {
              search: this.search,
            },
          }
        )
        .then((response) => {
          this.result = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      error: false,
      success: false,
      result: {},
    };
  },
  computed: {
    hasResult() {
      return this.result && Object.keys(this.result).length;
    },
  },
  mounted() {
    this.$store.commit("updateBreadcrumbs", {
      state: "Breadcrumbs",
      value: [
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  .page-bg {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    background: url("~@/assets/bg.jpg");
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: 0 100%;
    position: relative;
    .customization-details {
      font-family: $ubuntuLight;
      font-size: 14px;
      max-height: 65vh;
      overflow-y: scroll;
      width: 600px;
      background-color: $white;
      box-shadow: -6px 5px 14px rgba($black, 0.26);
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      margin-left: 30px;
      .customization-details-wrapper {
        padding: 20px;
        .process {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .disabled {
            opacity: 0.3;
            outline: none;
          }
        }
        .layersides-information {
          .section-title {
            margin-top: 15px;
          }
          .layerside-information-wrapper {
            display: flex;
            .layerside-information-body {
              width: 70%;
            }
            .layerside-image-proof {
              text-align: center;
              width: 30%;
              img {
                max-width: 100%;
              }
            }
          }
        }
        .detail-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 3px;
          .detail-label {
            text-transform: capitalize;
            width: 30%;
          }
          .detail-value {
            width: 70%;
          }
        }
      }
    }
    .head {
      background-color: $blackGray;
      color: $white;
      text-transform: uppercase;
      padding: 15px 36px;
      margin: 0;
    }
    h3.section-title {
      color: $darkGray;
      text-transform: uppercase;
      margin-bottom: 5.5px;
    }
    #search-sidebar {
      width: 366px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .sidebar-body {
        background-color: $white;
        box-shadow: -6px 5px 14px rgba($black, 0.26);
        padding: 15px 20px 25px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        .interior-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .loader {
            margin-top: 20px;
          }
          input {
            background: #ffffff;
            padding: 7px 14px;
            border: 1px solid rgba(184, 180, 180, 0.49);
            border-radius: 14px;
            width: 100%;
            outline: none;
            color: #121213;
            font-family: "Ubuntu Light";
            font-size: 12px;
            line-height: 13px;
          }
          .disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }
          .error-info {
            color: $red;
            font-size: 12px;
            text-transform: capitalize;
          }
          .success-info {
            color: $lightBlue;
            font-size: 12px;
            text-transform: capitalize;
          }
          button {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>