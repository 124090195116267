<template>
  <section class="page container">
    <PageHeading :product_type="'main'"></PageHeading>
    <div v-if="productExists" class="flex justify-space-between align-start">
      <DesignEditor :activeProduct="activeProduct" :product="product" :product_type="'main'" />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import PageHeading from "../components/PageHeading.vue";
import DesignEditor from "@/components/single-product/DesignEditor.vue";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["activeProduct"]),
    productExists() {
      return this.product && Object.keys(this.activeProduct.product).length > 0;
    },
    product() {
      return this.activeProduct.product;
    },
  },
  methods: {
    ...mapActions([
      "getCompanionItems",
      "resetReversible",
      "setActiveMainProduct",
      "fixColor1",
      "lockStates",
      "unlockStates",
      "updateBreadcrumbs",
      "redirectToPageWithParams",
      "reversibleImportData",
    ]),
    validateProduct() {
      if (!this.productExists) {
        this.redirectToPageWithParams({
          name: "Home",
        });
      } else {
        this.getCompanionItems();
        this.updateBreadcrumbs([
          {
            name: "Product Designs",
            component: "Product",
          },
        ]);
      }
    },
  },
  components: {
    PageHeading,
    DesignEditor,
  },
  mounted() {
    this.lockStates(["sport", "gender"]);
    this.unlockStates(["event"]);

    this.setActiveMainProduct();
    this.resetReversible();
    this.reversibleImportData();

    this.fixColor1(); //Fixes an issue that happens after edit stock items

    this.validateProduct();
  },
};
</script>

<style lang="scss">
#app {
  section.page.container {
    @media screen and (max-width: 1024px) {
      padding-bottom: 5rem;
    }
  }
  .product-and-customization-container {
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>