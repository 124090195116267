<template>
  <section class="catalogue-row">
    <h2 class="flex align-center">
      {{ sportCatalogue.name }}

      <div class="icon flex" v-html="sportCatalogue.svg_icon"></div>
    </h2>

    <div
      class="loader-container"
      v-if="
        loadingCatalogs &&
        (!sportCatalogue.products || sportCatalogue.products.length === 0)
      "
    >
      <Loader />
    </div>

    <template v-else-if="!areProductsForGenderSelected">
      <p class="no-products-avaible">
        This sport has no products assigned to this gender. Click
        <strong>Change Gender</strong> at the top of this page to view these
        products
      </p>
    </template>

    <template v-else>
      <ProductCard
        v-for="(product, index) in sportCatalogue.products"
        :key="product.id + '-pdcard'"
        :product="product"
        :side="'front'"
        :product_type="'main'"
        :priceRange="priceRange"
        :productsLength="sportCatalogue.length"
        :filteredTargetColors="filteredTargetColors"
        :hidePrice="hidePrice"
        :catalogId="sportCatalogue.id"
        :cardIndex="index"
        :isSpiritwear="isSpiritwear"
      />
    </template>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProductCard from "@/components/ProductCard.vue";
import Loader from "@/components/Loader.vue";
export default {
  name: "CatalogueRow",
  components: { ProductCard, Loader },
  props: [
    "sportCatalogue",
    "filteredTargetColors",
    "priceRange",
    "hidePrice",
    "catalogueIndex",
  ],
  computed: {
    ...mapState(["gender", "loadingCatalogs"]),

    areProductsForGenderSelected() {
      return this.sportCatalogue.genders.includes(this.gender);
    },

    isSpiritwear() {
      return this.sportCatalogue.name.toLowerCase() == "spiritwear";
    },
  },
  methods: {
    ...mapGetters(["getCurrentSport"]),
  },
};
</script>

<style lang="scss" scoped>
#catalogue-rows-container {
  width: 100%;

  .catalogue-row {
    width: 100%;
    display: grid;
    margin-bottom: 2rem;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px 13px;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgb(121, 121, 121);

    .loader-container {
      width: 100%;
      grid-area: 2/1/3/-1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 1366px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);

      max-width: 90%;
      margin: auto;
      min-width: 70%;
    }

    @media screen and (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
    }

    h2 {
      grid-area: 1/1/2/-1;
      text-transform: uppercase;
      padding: 0.7rem 0;
      font-family: "Ubuntu Bold";
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 1.52px;

      .icon {
        margin-left: 0.5rem;
      }
      .icon {
        ::v-deep svg {
          width: 22px;
          height: 22px;
          fill: $black;
          path,
          rect,
          line,
          ellipse {
            stroke: $black;
          }
        }
      }
    }

    .no-products-avaible {
      grid-area: 2/1/3/-1;
    }
  }
}
</style>