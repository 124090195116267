<template>
  <div
    id="nav-header"
    v-if="!headerDisabled && !(dealer_info && dealer_info.hide_dealer_header === false)"
  >
    <h2 v-if="!dealer_info">{{ dealer ? "Showroom" : "" }}</h2>
    <div v-else id="dealer-header-container">
      <div
        id="dealer-highlight-color"
        :style="{ 'background-color': dealer_info.header_color }"
      >
        &nbsp;
      </div>
      <div id="dealer-header" class="page container">
        <div class="dealer-logo" :style="{ 'justify-content': logoPosition }">
          <img
            :style="{ 'max-width': dealer_info.logo_size + '%' }"
            :src="dealer.logo"
          />
        </div>
        <div class="dealer-msg">{{ dealer_info.header_message }}</div>
        <div class="rep-info-container">
          <div class="rep-info" v-if="sales_rep">
            <h3>{{ sales_rep.name }}</h3>
            <span>{{ sales_rep.job_title }}</span>
            <span>{{ sales_rep.phone }}</span>
            <span>{{ sales_rep.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "DealerHeader",
  computed: {
    ...mapState(["headerDisabled"]),
    logoPosition() {
      if (this.dealer_info) {
        switch (this.dealer_info.logo_alignment) {
          case "0":
            return "flex-start";
          case "1":
            return "center";
          case "2":
            return "flex-end";
        }
      }
      return "flex-start";
    },
    dealer() {
      return this.$store.state.dealer;
    },
    dealer_info() {
      return this.dealer.settings;
    },
    sales_rep() {
      return this.$store.state.team_link
        ? this.$store.state.team_link.rep
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
#dealer-header-container {
  #dealer-highlight-color {
    height: 10px;
  }
  #dealer-header {
    display: flex;
    align-items: center;
    padding: 10px;
    .dealer-logo {
      width: 20%;
      display: flex;
      justify-content: flex-start;
      img {
        max-width: 250px;
        max-height: 75px;
      }
    }
    .dealer-msg {
      width: 60%;
    }
    .rep-info-container {
      width: 20%;
      .rep-info {
        display: flex;
        flex-direction: column;
        word-break: break-all;
      }
    }

    @media screen and (max-width: 576px) {
      flex-flow: column;
      & > * {
        margin-bottom: 1rem;
        width: 100%;
      }

      .rep-info-container {
        width: 100%;
      }

      .rep-info {
        & > * {
          margin-bottom: 1rem;
          width: 100%;
        }
      }
      .dealer-logo {
        img {
          margin: auto;
        }
      }
    }
  }

  @media screen and (max-width: 786px) {
    display: none;
  }
}
</style>