<template>
  <article v-if="design" id="slider-container">
    <div class="design-sides-slider">
      <div class="product-heading">
        <div class="tier-and-name">
          <TierTag :tier="tier"></TierTag>
          <h2>{{ name }}</h2>
        </div>
        <a href="#product-information">Product Information</a>
        <ReversiblePreview
          v-if="activeProductIsReversible && showReversible"
          :backgrounds="background_data"
          :is_stock="is_stock"
          :stock_color="stockColor"
          :isVariantStock="isVariantStock"
          :product_type="product_type"
          :design="design"
          :sides="sides"
          :activeSide="activeSlide"
        />
      </div>
      <VectorZoom v-if="zoom" :zoomData="zoomData" v-on="{ closeZoom }" />
      <VueSlickCarousel
        @beforeChange="c2GoTo"
        class="sides-slider-single"
        ref="carousel_1"
        :arrows="true"
        :dots="false"
        :asNavFor="$refs.carousel_2"
        :focusOnSelect="true"
      >
        <div
          class="product-preview"
          v-for="(side, index) in sides"
          :key="index"
        >
          <Vector
            :side="index"
            :svgs="svgs(index)"
            :backgrounds="background_data"
            :layout="layout"
            :product_type="product_type"
            :is_stock="is_stock"
            :stock_color="stockColor"
            :isVariantStock="isVariantStock"
            :zoomable="true"
            :styleId="styleId"
            :designName="designName"
            :isHybridSVG="isHybridSVG"
            v-on="{ zoomSVG }"
          />
        </div>
      </VueSlickCarousel>
      <h3 class="design-name">{{ design.name }}</h3>
      <VueSlickCarousel
        @beforeChange="c1GoTo"
        class="sides-slider-nav"
        ref="carousel_2"
        :arrows="false"
        :dots="false"
        :slidesToShow="3"
        :draggable="false"
        :focusOnSelect="true"
        :asNavFor="$refs.carousel_1"
      >
        <span
          v-for="(side, index) in sides"
          :key="index"
          :data-information-side="index"
          >{{ index }}</span
        >
      </VueSlickCarousel>
    </div>
    <div class="tabs-container" id="product-information" v-if="description">
      <v-tabs
        v-model="tab"
        grow
        height="30px"
        color="#3e3e3e"
        background-color="#f9f9f9"
        slider-color="#000000"
        slider-size="1"
      >
        <v-tab>Product details</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <p v-html="description"></p>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </article>
</template>

<script>
import CONSTANTS from "@/constants.js";

import Vector from "../Vector";
import VectorZoom from "@/components/single-product/VectorZoom";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import TierTag from "../TierTag";

import ReversiblePreview from "@/components/single-product/ReversiblePreview.vue";
import { mapGetters } from "vuex";

export default {
  name: "ProductInformation",
  props: [
    "name",
    "backgrounds",
    "tier",
    "product_type",
    "is_stock",
    "description",
    "isVariantStock",
  ],
  components: {
    VueSlickCarousel,
    Vector,
    VectorZoom,
    TierTag,
    ReversiblePreview,
  },
  computed: {
    ...mapGetters([
      "activeProduct",
      "activeProductIsReversible",
      "activeProductReverseActive",
    ]),
    bectiveProducts() {
      return this.$store.state[this.product_type + "_product"];
    },
    styleId() {
      return this.$store.state[this.product_type + "_product"].product.style_id
    },
    showReversible() {
      return this.product_type === "main";
    },
    background_data() {
      if (this.backgrounds) {
        return this.backgrounds;
      }
      return this.design.backgrounds;
    },
    stockColor() {
      return this.$store.state.stockColor;
    },
    gender() {
      return this.$store.state.gender;
    },
    design() {
      return this.$store.state[this.product_type + "_product"].design;
    },
    designName() {
      return this.design.name
    },
    isHybridSVG() {
      return this.design.color === "precolored";
    },
    layout() {
      return !this.activeProductReverseActive
        ? this.activeProduct.layout
        : this.activeProduct.reversible_layout;
    },

    sides() {
      let sides = {},
        result = {},
        possibleSides = CONSTANTS.LAYER.SIDES;
      if (this.isHybridSVG) return {
        front: CONSTANTS.EMPTY_SVG_GROUPS,
        back: CONSTANTS.EMPTY_SVG_GROUPS
      }
      if (this.design.svg_data) return this.design.svg_data;
      //else, get sides from stock png backgrounds
      let backgroundKeys = this.design.backgrounds
        ? Object.keys(this.design.backgrounds)
        : null;
      for (let index = 0; index < backgroundKeys.length; index++) {
        const backgroundKey = backgroundKeys[index]
          .replace("shadow_men_", "")
          .replace("shadow_women_", "")
          .replace("shadow_youth_", "")
          .replace("shadow_girls_", "");
        if (Object.keys(sides).indexOf(backgroundKey) === -1) {
          sides[backgroundKey] = {};
        }
      }
      for (let index = 0; index < possibleSides.length; index++) {
        //Reorder object
        const element = possibleSides[index];
        if (sides[element]) {
          result[element] = sides[element];
        }
      }
      return result;
    },
  },
  methods: {
    zoomSVG(data) {
      this.zoomData = data;
      this.zoom = true;
    },
    closeZoom() {
      this.zoomData = {};
      this.zoom = false;
    },
    c1GoTo(old, news) {
      this.activeSlide = news;
      this.$refs.carousel_1.goTo(news);
    },
    c2GoTo(old, news) {
      this.activeSlide = news;
      this.$refs.carousel_2.goTo(news);
    },
    svgs(index) {
      if (this.isVariantStock) {
        return CONSTANTS.EMPTY_SVG_GROUPS;
      }
      return this.design.svg_data
        ? this.design.svg_data[index]
        : CONSTANTS.EMPTY_SVG_GROUPS;
    },
    selectMainSide() {
      //turns active the side with the first layout with attributes
      let possibleLayers = CONSTANTS.LAYER.SIDES,
        first = "front";
      for (let index = 0; index < possibleLayers.length; index++) {
        const layer = possibleLayers[index];
        if (this.layout.layout_attributes[layer]) {
          first = layer;
          break;
        }
      }
      document
        .querySelectorAll('[data-information-side="' + first + '"]')[0]
        .click();
    },
  },
  watch: {
    layout() {
      this.selectMainSide();
    },
  },
  data() {
    return {
      zoom: false,
      zoomData: {},
      tab: null,
      activeSlide: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  article {
    width: 60%;

    @media (max-width:1024px){
      width: 100%;
    }
  }

  .design-sides-slider {
    max-width: 100%;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    .product-heading {
      position: relative;
      .tier-and-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 14px;
        .tier-tag {
          margin-right: 15px;
        }
        h2 {
          text-transform: uppercase;
        }
      }
      a {
        color: $black;
        font-family: $ubuntuRegular;
        font-size: 11px;
        line-height: 12px;
      }
    }
    .design-name {
      text-align: center;
      color: $black;
      font-family: $ubuntuMedium;
      font-size: 18px;
      line-height: 21px;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    .product-preview {
      min-height: 450px;
      .product-image {
        display: flex;
        justify-content: center;
        ::v-deep svg {
          height: 450px;
        }
      }
    }
    ::v-deep .sides-slider-single {
      .slick-arrow {
        &:before {
          opacity: 1;
          font-size: 28px;
        }
        &:after {
          content: "";
          display: block;
          background-color: #000;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 4px;
          left: 5px;
          z-index: -1;
          opacity: 0.5;
        }
        &:hover {
          &:after {
            opacity: 0.1;
          }
        }
        &.slick-next {
          right: 25px;
          z-index: 1;
        }
        &.slick-prev {
          left: 25px;
          z-index: 1;
        }
      }
    }
    ::v-deep .sides-slider-nav {
      .slick-list {
        .slick-track {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          .slick-slide {
            width: 75px !important;
            background-color: $whiteGray;
            &:first-child {
              border-bottom-left-radius: 15px;
              border-top-left-radius: 15px;
            }
            &:last-child {
              border-bottom-right-radius: 15px;
              border-top-right-radius: 15px;
            }

            &.slick-current {
              span {
                font-family: $ubuntuMedium;
                padding: 5px 13px;
                border: 1px solid $black;
              }
            }
            span {
              background-color: $whiteGray;
              color: $darkerGray;
              font-family: $ubuntuRegular;
              font-size: 12px;
              line-height: 13px;
              text-align: center;
              text-transform: uppercase;

              cursor: pointer;
              padding: 6px 14px;
              border-radius: 15px;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }
  .tabs-container {
    width: 100%;
    /* max-width: 600px; */
    padding: 10px 22px 21px;
    background-color: $whiteGray;
    .v-tabs {
      margin-bottom: 11px;
      .v-tab {
        flex: 1;
        font-family: $ubuntuRegular;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.99px;

        border-bottom: 1px solid $lighterGray;
        &.v-tab--active {
          font-family: $ubuntuBold;
        }
      }
    }
    .v-tabs-items {
      background-color: $whiteGray;
      .v-window-item {
        li,
        p {
          padding: 8.5px 0;
        }
        li {
          margin-left: 22px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }
}
</style>