import router from '../../router';

export default {
  state: {
    breadcrumbs: [
    ],
  },
  mutations: {
    updateBreadcrumbs: (state, data) => {
      state.breadcrumbs = data;
    },
  },
  actions: {
    updateBreadcrumbs: ({ rootState, state, commit }, data) => {
      let breadcrumbs = [];
      if (router.currentRoute.name !== "SearchGuest") {
        if (state.homeDisabled) {
          breadcrumbs = [{
            name: rootState.isAnEmbeddedShowroom ? 'Showroom' : rootState.team_link.title ,
            component: "Catalogue",
          }
          ];
        }
        else {
          breadcrumbs = [{
            name: "Home",
            component: "Home",
          },
          {
            name: rootState.isAnEmbeddedShowroom ? 'Showroom' : rootState.team_link.title ,
            component: "Catalogue",
          }
          ];
        }
      }
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        breadcrumbs.push({
          name: element.name,
          component: element.component
        });
      }
      commit("updateBreadcrumbs", breadcrumbs);
    },
  },
  getters: {
    breadcrumbs(state) {
      return state.breadcrumbs;
    }
  }
}