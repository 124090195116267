import axios from 'axios'
import Vue from 'vue'
import CONSTANTS from '@/constants.js';
const customer = {

  namespaced: true,

  state: () => ({
    id: '',
    name: '',
    type: '',
    rep: '',
    colors: [],
    lockers: [],
    logos: [],
    created: '',
    school: '',
    status: '',

    //Spiritwear and Uniforms Logos
    spiritwearLogo: { screenLogoProperties: { screenLogoProperties: { logo: null } } },
    uniformsLogo: { screenLogoProperties: { screenLogoProperties: { logo: null } } }
  }),

  getters: {
    getLogoById: (state) => (selectedLogoId) => {
      return [...state.logos].filter(logo => logo.id == selectedLogoId)[0]
    },

    customerId: (state) => state.id,

    customerName: (state) => state.name
  },

  actions: {

    // Customer CRUD

    async initCustomer({ dispatch, commit }, customerData) {

      commit('updateCustomer', customerData)
      const normalizedLogos = await dispatch('normalizeLogos', customerData.logos)
      commit('updateLogos', normalizedLogos)
      //Set Spiritwear and Uniforms logos
      await dispatch('initSpiritwearAndUniformsLogos')
    },

    async createCustomer({ rootState, commit }, { name, colors, type, repId }) {
      const url = rootState.settings.endpoint + "api/customers";

      let params = {
        colors,
        name,
        type,
        rep_id: repId
      };

      //If there is a Rep Id, we are in a team-link
      if (repId) {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + rootState.settings.token,
          },
        };

        await axios
          .post(url, params, headers)
          .then(({ data }) => {

            commit('updateCustomer', data)

          })
          .catch(() => {
            return false
          });
      } else {
        const data = {
          id: 1,
          created: '1-1-1',
          logos: [],
          name: '',
          lockers: [],
          rep: '',
          school: '',
          status: '',
          type: '',
        }
        //otherwise, we are in a Embedd showroom, and it does not need a real customer
        commit('updateCustomer', data)
      }


    },

    async updateCustomer({ rootState, dispatch, commit }, { name, colors, type, repId, logos }) {
      const url = rootState.settings.endpoint + "api/customers";

      let params = {
        colors: [colors[0].id, colors[1].id, colors[2].id],
        name,
        type,
        rep_id: repId,
      };

      if (Object.keys(colors[3]).length) {
        params.colors.push(colors[3].id);
      }

      if (Object.keys(colors[4]).length) {
        params.colors.push(colors[4].id);
      }

      if (Object.keys(colors[5]).length) {
        params.colors.push(colors[5].id);
      }

      if (logos.length) {
        params["logos_ids"] = logos.map((logo) => logo.id);
      }

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.settings.token,
        },
      };

      await axios
        .post(url, params, headers)
        .then(async (response) => {

          commit('updateCustomer', response.data)
          const normalizedLogos = await dispatch('normalizeLogos', response.data.logos)
          commit('updateLogos', normalizedLogos)

          //Set Spiritwear and Uniforms logos
          await dispatch('initSpiritwearAndUniformsLogos')

          //Update the customer basic data that is in rootState.dealer.customers
          // dispatch('updateBasicCustomer', response.data, { root: true })

        })
        .catch(() => {
          this._vm.$toastr.e("Error in updateCustomer");
        });
    },

    async updateCustomerStatus({ rootState, dispatch, commit }, { customerId, status }) {

      axios
        .put(
          rootState.settings.endpoint + `api/customers/${customerId}`,
          { status },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + rootState.settings.token,
            },
          }
        )
        .then(() => {

          commit('updateCustomerStatus', status)

          // this.$emit("changeCustomerStatus", { id: this.customer.id, status });

          dispatch("dispatchShowToast", {
            txt:
              status == "archived" ? "Customer Archived" : "Customer Unarchived",
          });

          // this.requestDelete = false;
        })
        .catch((error) => {
          dispatch("dispatchShowToast", {
            txt: error,
            type: "error",
          });
          this.requestDelete = false;
        });
    },

    // Logos CRUD

    addNewLogo({ commit }, logoData) {
      commit('addNewLogo', logoData)
    },

    updateLogo({ commit }, logoData) {
      commit('updateLogo', logoData)
    },

    normalizeLogos({ state }, logos) {
      state.fe + 1
      const normalizedLogos = logos
        .filter(logo => logo.status !== 'archived')
        .map((logo) => ({
          ...logo,
          fileUploaded: logo.content,
          fileOriginal: logo.url,
          typeOfLogo: logo.type ? logo.type : [],
        }));
      return normalizedLogos

    },

    initSpiritwearAndUniformsLogos({ rootState, state, dispatch }) {

      const logos = [...state.logos]
      const spiritwearLogoId = rootState.spiritwearLogoId
      const uniformsLogoId = rootState.uniformsLogoId


      if (logos.length) {

        //Check if there is any spiritwear logo
        if (spiritwearLogoId) {

          let logoSettings = { logoId: '', }
          const spiritwearLogo = logos.filter(logo => logo.id == spiritwearLogoId)[0]

          if (spiritwearLogo) {
            logoSettings['logoId'] = spiritwearLogo.id
          } else {
            const firstSpiritwearLogo = logos.filter((logo) => {
              return logo.typeOfLogo?.some((type) => type === "spiritwear");
            })[0]
            logoSettings['logoId'] = firstSpiritwearLogo.id
          }

          //Set the Screen Logos
          if (rootState.initialSpiritwearLogo.logo) {
            logoSettings['screenLogoProperties'] = rootState.initialSpiritwearLogo
          }

          dispatch('setSpiritwearLogo', logoSettings);
        }

        //Check if there is any Uniforms logo
        if (uniformsLogoId) {

          let logoSettings = { logoId: '', }

          const uniformsLogo = logos.filter(logo => logo.id == uniformsLogoId)[0]

          if (uniformsLogo) {
            logoSettings['logoId'] = uniformsLogo.id

          } else {
            const firstUniformsLogo = logos.filter((logo) => {
              return logo.typeOfLogo?.some((type) => type === "uniforms");
            })[0]
            logoSettings['logoId'] = firstUniformsLogo.id
          }

          if (rootState.initialUniformsLogo.logoId) {
            logoSettings['screenLogoProperties'] = rootState.initialUniformsLogo
          }

          dispatch('setUniformsLogo', logoSettings);
        }

      }

    },

    async updateShowroomLogo({ rootState, rootGetters, dispatch }, { logoId, typeOfLogo, screenLogoProperties }) {


      if (!rootState.isAnEmbeddedShowroom && (!rootGetters['actionsPermissions/isProjectLocked'])) {
        const showroomId = rootState.team_link.id

        //If there is a LogoId, we want to replace the logo, if not it means we want to set an empty logo
        const url = logoId ? rootState.settings.endpoint + "api/v2/team-link-logo-replace" : rootState.settings.endpoint + "api/v2/team-link-logo-delete";

        const params = {
          id: showroomId,
          type_of_logo: typeOfLogo
        }

        //Add Spiritwear/Uniforms Screen
        if (screenLogoProperties) {
          params['logo_content'] = screenLogoProperties.logo,
            params['logo_settings'] = {
              position: screenLogoProperties.position,
              zoom: screenLogoProperties.zoom
            }
        }

        typeOfLogo === 'spiritwear'
          ? params['spiritwear_logo_id'] = logoId
          : params['uniforms_logo_id'] = logoId


        const settings = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + rootState.settings.token,
          },
        }

        await axios.put(url, params, settings)
      }

      typeOfLogo === 'spiritwear'
        ? dispatch('setSpiritwearLogo', { logoId, screenLogoProperties })
        : dispatch('setUniformsLogo', { logoId, screenLogoProperties })

    },

    setSpiritwearLogo({ state, commit }, { logoId, screenLogoProperties }) {

      if (!logoId) {
        commit('setSpiritwearLogo', {})

      } else {

        const logo = [...state.logos].filter(logo => logo.id == logoId)[0]
        const logoCopy = { ...logo }

        if (screenLogoProperties && screenLogoProperties.logo) {

          logoCopy.screenLogoProperties = screenLogoProperties
        } else {

          logoCopy.screenLogoProperties = { logo: logoCopy.fileUploaded, zoom: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom, position: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position }
        }
        commit('setSpiritwearLogo', logoCopy)
      }
    },

    setUniformsLogo({ state, commit }, { logoId, screenLogoProperties }) {

      if (!logoId) {
        commit('setUniformsLogo', {})
      } else {
        const logo = [...state.logos].filter(logo => logo.id == logoId)[0]
        const logoCopy = { ...logo }
        if (screenLogoProperties && screenLogoProperties.logo) {

          logoCopy.screenLogoProperties = screenLogoProperties
        } else {

          logoCopy.screenLogoProperties = { logo: logoCopy.fileUploaded, zoom: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom, position: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position }
        }
        commit('setUniformsLogo', logoCopy)
      }
    },

    dispatchShowToast({ dispatch }, { txt, type }) {
      dispatch("showToast", { txt, type }, { root: true });
    }
  },

  mutations: {

    updateCustomer: (state, updatedCustomer) => {

      const {
        id,
        created,
        logos,
        name,
        lockers,
        rep,
        school,
        status,
        type, } = updatedCustomer


      // Logos
      state.logos = logos

      state.id = id
      state.created = created
      state.logos = logos
      state.name = name
      state.lockers = lockers ? lockers : []
      state.school = school
      state.status = status
      state.rep = rep
      state.type = type
    },

    addNewLogo: (state, newLogo) => { state.logos.push(newLogo) },

    updateLogo: (state, logoData) => {
      state.logos = [...state.logos].map((logo) => {
        if (logo.id == logoData.editedLogoId) {
          logo.content = logoData.editedLogo;
          logo.fileUploaded = logoData.editedLogo;
          logo.typeOfLogo = logoData.typeOfLogo;
          logo.colors = logoData.colors;
        }

        return logo;
      });
    },

    updateLogos: (state, logos) => {
      state.logos = logos
    },

    updateCustomerStatus: (state, status) => {
      state.status = status
    },

    setSpiritwearLogo: (state, newLogo) => {
      Vue.set(state, 'spiritwearLogo', newLogo)
    },
    setUniformsLogo: (state, newLogo) => {
      Vue.set(state, 'uniformsLogo', newLogo)
    },
  },
}

export default customer