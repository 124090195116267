<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 864 864"
    :x="artwork_properties.x" 
    :y="artwork_properties.y" 
    :width="artwork_properties.width" 
    :height="artwork_properties.height">
    <defs />
      <g>
        <path v-for="(path, index) in group_1" :key="index" :d="path" v-bind:style="{ fill: artwork_color_1 }"></path>
        <polygon v-for="(polygon, index) in group_1_polygons" :key="index + '_polygon1'" :points="polygon" v-bind:style="{ fill: artwork_color_1 }"></polygon>
        <rect v-for="(rect, index) in group_1_rects" :key="index + '_rect1'" :x="rect.x" :y="rect.y" :width="rect.width" :height="rect.height" v-bind:style="{ fill: artwork_color_1 }"></rect>
      </g>
      <g>
        <path v-for="(path, index) in group_2" :key="index" :d="path" v-bind:style="{ fill: artwork_color_2 }"></path>
        <polygon v-for="(polygon, index) in group_2_polygons" :key="index + '_polygon2'" :points="polygon" v-bind:style="{ fill: artwork_color_2 }"></polygon>
        <rect v-for="(rect, index) in group_2_rects" :key="index + '_rect2'" :x="rect.x" :y="rect.y" :width="rect.width" :height="rect.height" v-bind:style="{ fill: artwork_color_2 }"></rect>
        </g>
      <g>
        <path v-for="(path, index) in group_3" :key="index" :d="path" v-bind:style="{ fill: artwork_color_3 }"></path>
        <polygon v-for="(polygon, index) in group_3_polygons" :key="index + '_polygon3'" :points="polygon" v-bind:style="{ fill: artwork_color_3 }"></polygon>
        <rect v-for="(rect, index) in group_3_rects" :key="index + '_rect3'" :x="rect.x" :y="rect.y" :width="rect.width" :height="rect.height" v-bind:style="{ fill: artwork_color_3 }"></rect>        
      </g>
  </svg>
</template>

<script>
const default_properties = { width: '90', height: '90', x: '0', y: '0' };
import { mapGetters } from "vuex";

export default {
  name: "LayoutArtworkOptionIcon",
  props: ["artwork"],
  computed: {
    ...mapGetters(["getColorHexByField", "getColorById", "activeProduct"]),
    sideKey() {
      return this.activeProduct.reversibleActive ? "reversible_front" : "front";
    },
    artwork_properties() {
      return default_properties;
    },
    group_1() {
      return this.artwork_svg ? this.artwork_svg.group1 : false;
    },
    group_2() {
      return this.artwork_svg ? this.artwork_svg.group2 : false;
    },
    group_3() {
      return this.artwork_svg ? this.artwork_svg.group3 : false;
    },
    group_1_polygons() {
      return this.artwork_svg ? this.artwork_svg.group1_polygons : false;
    },
    group_2_polygons() {
      return this.artwork_svg ? this.artwork_svg.group2_polygons : false;
    },
    group_3_polygons() {
      return this.artwork_svg ? this.artwork_svg.group3_polygons : false;
    },
    group_1_rects() {
      return this.artwork_svg ? this.artwork_svg.group1_rects : false;
    },
    group_2_rects() {
      return this.artwork_svg ? this.artwork_svg.group2_rects : false;
    },
    group_3_rects() {
      return this.artwork_svg ? this.artwork_svg.group3_rects : false;
    },
    artwork_svg() {
      return JSON.parse(this.artwork.svg);
    },
    artwork_color_1() {
      return this.getColorById(
        this.activeProduct[this.sideKey].artwork_color_1
      ).hex;
    },
    artwork_color_2() {
      return this.getColorById(
        this.activeProduct[this.sideKey].artwork_color_2
      ).hex;
    },
    artwork_color_3() {
      return this.getColorById(
        this.activeProduct[this.sideKey].artwork_color_3
      ).hex;
    },
  }
}
</script>
