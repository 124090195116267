<template>
  <div
    class="tier-tag"
    :class="{
      premium: tierName === 'premium',
      'two-day': tierName === '2 day',
      'five-day': tierName === '5 day',
      'in-stock': tierName === 'in stock',
      stock: tierName === 'stock',
    }"
  >
    {{ tierName }}
  </div>
</template>

<script>
export default {
  name: "TierTag",
  props: ["tier", "isVariantStock"],
  computed: {
    tierName() {
      if (this.isVariantStock) {
        return "stock";
      } else {
        switch (this.tier) {
          case 1: {
            return "2 day";
          }
          case 2: {
            return "premium";
          }
          case 3: {
            return "5 day";
          }
          case 4: {
            return "in stock";
          }
          default: {
            return "n/a";
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .tier-tag {
    padding: 2px 2px 1px 5px;
    color: $white;
    font-family: $ubuntuBold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: -10px;
      top: 0;
      border-top: 19px solid transparent;
      border-right: 10px solid transparent;
    }
    &.premium {
      background-color: $orange;
      &:after {
        border-top-color: $orange;
      }
    }
    &.in-stock {
      background-color: #dadada;
      &:after {
        border-top-color: #dadada;
      }
    }
    &.stock {
      background-color: #dadada;
      &:after {
        border-top-color: #dadada;
      }
    }
    &.two-day {
      background-color: $red;
      &:after {
        border-top-color: $red;
      }
    }
    &.five-day {
      background-color: $lightBlue;
      &:after {
        border-top-color: $lightBlue;
      }
    }
  }
}
</style>