<template>
    <div v-if="getColorNameByField({field, product_type, side, subfield, isArtwork})" class="color-picker">
        <div class="color-picker-swatch flex align-center" @click="triggerPicker">
            <div class="color-picker-radius box-shadow" v-bind:style="{ backgroundColor: getColorHexByField({field, product_type, side, subfield, isArtwork}) }"></div>
            <span>{{ getColorNameByField({field, product_type, side, subfield, isArtwork}) }}</span>
        </div>
        <div class="color-picker-list box-shadow flex-column flex-wrap" v-bind:class="{ active: isActive }">
            <!-- Create a ColorSwatch for each color available in the state -->
            <ColorSwatch v-bind:key="color.id" v-for="(color) in getAllColors" :color="color" :field="field" :product_type="product_type" :side="side" :subfield="subfield"/>
        </div>
    </div>
    <div v-else class="color-picker flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ColorSwatch from "../components/ColorSwatch.vue";

export default {
    props: ['field', 'product_type', 'side', 'subfield', 'isArtwork'],
    name: "ColorPicker",
    computed: {
        ...mapGetters(['getColorHexByField', 'getColorNameByField', 'getAllColors']),
        isActive() {
          return this.subfield == null ? this.$parent.opened_pickers[this.field] : this.$parent.opened_pickers[this.side][this.field][this.subfield];
        },
    },
    methods: {
        ...mapActions(['updateTeamColor']),
        triggerPicker() {
            let keys, sides, subfields, _side, _key, _sub;
            if (!this.subfield) { //normal colorpickers
              if (!this.isArtwork) {
                keys = ['color_1', 'color_2', 'color_3'];
              }
              else {
                keys = ['artwork_color_1', 'artwork_color_2', 'artwork_color_3'];
              }
              for (let index = 0; index < keys.length; index++) {
                  const element = keys[index];
                  if (this.field !== element) {
                      this.$parent.opened_pickers[element] = false;
                  }
                  else {
                      this.$parent.opened_pickers[element] = !this.$parent.opened_pickers[this.field];
                  }
              }
            }
            else {  //layout color pickers
              keys = ['name', 'number'];
              sides = ['front', 'side', 'back'];
              subfields = ['fill', 'outline'];
              for (let side_index = 0; side_index < sides.length; side_index++) {
                _side = sides[side_index];
                for (let index = 0; index < keys.length; index++) {
                  _key = keys[index];
                  for (let sub_index = 0; sub_index < subfields.length; sub_index++) {
                    _sub = subfields[sub_index];
                    this.$parent.opened_pickers[_side][_key][_sub] = (
                      this.side === _side && this.field === _key && this.subfield === _sub && !this.$parent.opened_pickers[_side][_key][_sub]
                    );
                  }
                }
              }
            }
        }
    },
    components: {
        ColorSwatch,
    }
}
</script>

<style lang="scss" scoped>
  #app {

    #main {
      #home-sidebar {
        .color-picker-swatch {
          .color-picker-radius {
            width: 20px;
            height: 20px;
          }
        }
        .color-picker-list {
          max-height: 303px;
        }
      }
    }

    .color-picker {
      position: relative;
    }

    .color-picker-swatch {
      cursor: pointer;
      .color-picker-radius {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-right: 5px;
      }
      span {
        font-family: $ubuntuRegular;
        font-size: 10px;
        line-height: 11px;
        letter-spacing: 0.76px;
        text-transform: lowercase;
        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
    .color-picker-list {
      background: $white;
      width: 215px;
      max-height: 248px;
      display: none;
      padding: 2.5px 0 3.5px;
      margin-top: 5px;
      position: absolute;
      top: 20px;
      left: 0;

      .color-picker-swatch {
        padding: 3.5px 9px;
      }
    }

    .color-picker-list.active {
      display: flex;
      position: absolute;
      z-index: 99999;
    }
  }

</style>