import { render, staticRenderFns } from "./LayoutArtwork.vue?vue&type=template&id=394953cb&scoped=true&"
import script from "./LayoutArtwork.vue?vue&type=script&lang=js&"
export * from "./LayoutArtwork.vue?vue&type=script&lang=js&"
import style0 from "./LayoutArtwork.vue?vue&type=style&index=0&id=394953cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394953cb",
  null
  
)

export default component.exports