<template>
  <!-- This component may need to be reworked -->
  <div class="top-bar">
    <div class="container flex justify-space-between">
      <div class="breadcrumbs" v-if="!editMode">
        <router-link
          :to="breadcrumbLink(breadcrumb)"
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="index"
        >
          <span
            :class="{
              'breadcrumb-showroom-title': isBreadcrumbsShowroomLink(
                breadcrumb.component
              ),
            }"
            v-if="breadcrumbEnabled(breadcrumb.name)"
            ><span>{{ breadcrumbAfter(index) }}</span>
            {{ breadcrumb.name }}</span
          >
        </router-link>
      </div>
      <div class="breadcrumbs breadcrumbs-edit-mode" v-else></div>
      <div
        class="locker-information flex"
        v-if="locker.id"
        :class="{
          clickable: !isLockerPage || isProjectUnlockeable,
        }"
      >
        <span class="m-auto" v-if="isLockerLoading"
          ><strong>Loading locker...</strong></span
        >
        <span
          v-else
          class="m-auto"
          :title="lockerHintTitle"
          @click="handleGoToLocker()"
        >
          <!-- LOCKER ICON -->
          <v-img
            v-if="isProjectLocked"
            class="locked-img"
            :src="require('@/assets/icons/lock.svg')"
          >
          </v-img>

          <!-- Locker name/code/route -->
          <strong>{{ locker.name || defaultlockerTitle }}</strong>
          &nbsp;{{ dealerMode ? "" : "(" + locker.code + ")" }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Breadcrumbs",

  computed: {
    ...mapState(["lockerModule", "isAnEmbeddedShowroom"]),
    ...mapGetters("lockerModule", ["isLockerLocked"]),
    ...mapGetters("actionsPermissions", [
      "isProjectLocked",
      "isProjectUnlockeable",
    ]),

    lockerRoute() {
      // return `snapsheets/:dealer_code/:snapsheet_id/locker/:locker_code`
      const { dealercode, showroom_id, showroom_slug } = this.$route.params;
      return `/${dealercode}/showroom/${showroom_id}/${showroom_slug}/locker`;
    },

    isLockerLoading() {
      return this.lockerModule.loadingLocker;
    },

    editMode() {
      return this.$store.state.editMode;
    },
    homeDisabled() {
      return this.$store.state.homeDisabled;
    },
    defaultlockerTitle() {
      if (this.dealerMode) {
        return this.isLockerPage ? "My Locker" : "View Locker";
      }
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        if (
          this.$store.state.dealer.settings.labels &&
          this.$store.state.dealer.settings.labels.default_locker_title
        ) {
          return this.$store.state.dealer.settings.labels.default_locker_title;
        } else {
          return "View Locker";
        }
      }
      return "View Locker";
    },

    lockerHintTitle() {
      let name = this.dealerMode ? "locker" : "locker";
      return this.isLockerLocked || this.isProjectLocked
        ? "This " + name + " has been locked"
        : this.isLockerPage
        ? ""
        : "Go to " + name + " Page";
    },

    dealerMode() {
      return this.$store.state.dealerMode;
    },

    breadcrumbs() {
      return this.$store.getters.breadcrumbs;
    },

    locker() {
      return this.lockerModule.locker;
    },

    isLockerPage() {
      return (
        this.$route.name === "Locker" ||
        this.$route.name === "LockerWithTeamLink"
      );
    },

    lockerState() {
      const projectStatus = this.$store.state.team_link?.status;

      return projectStatus;
      // projectStatus === "quote" ||
      //   projectStatus === "OMG Store Requested" ||
      //   projectStatus === "closed";
    },
  },

  methods: {
    ...mapActions(["goToLocker", "updateShowroomStatus"]),
    ...mapActions("lockerModule", ["getLockerByCode"]),
    breadcrumbEnabled(name) {
      if (name === "Home" && this.homeDisabled) {
        return false;
      }
      return true;
    },
    breadcrumbAfter(index) {
      if (this.homeDisabled) {
        return index > 1 ? " > " : "";
      } else {
        return index > 0 ? " > " : "";
      }
    },
    breadcrumbLink(breadcrumb) {
      let params = {
          dealercode: this.$route.params.dealercode,
        },
        to = breadcrumb.component;
      if (this.$route.params.showroom_id) {
        (params.showroom_id = this.$route.params.showroom_id),
          (params.showroom_slug = this.$route.params.showroom_slug),
          (to = breadcrumb.component + "WithTeamLink");
      }
      return {
        name: to,
        params: params,
      };
    },
    async handleGoToLocker() {
      if (this.isLockerPage && !this.isProjectUnlockeable) {
        return false;
      } else if (this.isProjectUnlockeable) {
        try {
          confirm("UNLOCK THIS SHOWROOM?") &&
            (await this.updateShowroomStatus(
              CONSTANTS.PROJECT_STATUS.OPEN_STATUS
            ));
          this.goToLocker();
        } catch (error) {
          this.$toastr.e("Error trying to unlock this showroom");
        }
      } else {
        let locker_code = this.locker.code;
        if (
          !this.isLockerLoading &&
          locker_code.length &&
          locker_code.length <= 10
        ) {
          this.$store.commit("updateState", {
            state: "goTolocker",
            value: true,
          });

          this.goToLocker();
        }
      }
    },

    isBreadcrumbsShowroomLink(componentName) {
      return (
        componentName.toLowerCase() === "catalogue" &&
        !this.isAnEmbeddedShowroom
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .top-bar {
    background-color: $whiteGray;
    .container {
      padding: 10px 0;
      a {
        color: $gray;
        font-family: $ubuntuMedium;
        font-size: 10px;
        text-decoration: none;
        span {
          margin: 0 2px;
        }
        &.router-link-active {
          color: $blackGray;
        }
        &:first-child {
          span {
            margin: 0;
          }
        }
      }
      .locker-information {
        .m-auto {
          margin: auto;
        }
        text-transform: uppercase;
        &.clickable {
          cursor: pointer;
        }
        &.locked {
          cursor: not-allowed;
        }
        span {
          display: flex;
          align-items: center;
          font-family: $ubuntuRegular;
          font-size: 14px;
          line-height: 16px;
          strong {
            font-family: $ubuntuBold;
          }
          .locked-img {
            width: 20px;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .breadcrumbs {
        flex-flow: column;
        margin-bottom: 1rem;
      }
      .container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        /* margin-bottom: 2rem; */
        align-items: center;
      }
    }
  }
}

.breadcrumb-showroom-title {
  font-size: 18px;
  font-family: Ubuntu Bold;
  letter-spacing: 0.99px;
}
</style>