<template>
  <section class="page container">
    <PageHeading :product_type="'companion'"></PageHeading>
    <div v-if="productExists" class="flex justify-space-between align-start">
      <DesignEditor :activeProduct="activeProduct" :product="product" :product_type="'companion'" />
    </div>
  </section>
</template>

<script>
import CONSTANTS from "@/constants.js";
import { mapGetters, mapActions } from "vuex";

import PageHeading from "../components/PageHeading.vue";
import DesignEditor from "@/components/single-product/DesignEditor.vue";

export default {
  data() {
    return {
      showCustomizeBtn: false,
    };
  },
  computed: {
    ...mapGetters(["activeProduct", "getColorByField"]),
    isVariantStock() {
      return this.product.variants ? this.product.variants.length > 0 : false;
    },
    productLayout() {
      if (this.active_layout) {
        return this.active_layout;
      }
      return this.product && this.product.layouts
        ? this.product.layouts[0]
        : false;
    },
    product() {
      return this.activeProduct.product;
    },
    productExists() {
      return this.product && Object.keys(this.product).length > 0;
    },
    design() {
      return this.activeProduct.design;
    },
    designs() {
      if (this.productExists) {
        let gender = this.$store.state.gender;
        if (!this.isVariantStock) {
          return this.product.designs_by_gender[gender];
        } else {
          return this.filteredStockVariants;
        }
      }
      return false;
    },
    productVariationsTitle() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        if (
          this.$store.state.dealer.settings.labels &&
          this.$store.state.dealer.settings.labels.product_variations_title
        ) {
          return this.$store.state.dealer.settings.labels
            .product_variations_title;
        } else {
          return "AVAILABLE DESIGNS";
        }
      }
      return "";
    },
    filteredStockVariants() {
      if (!this.isVariantStock) return false;
      //We need to get the first match for C1, C2 and C3 and then the backup colors
      let targetColors = [],
        filteredTargetColors = [],
        filteredVariants = [];
      for (let index = 1; index <= 3; index++) {
        targetColors.push(
          this.getColorByField({
            product_type: "main",
            field: "color_" + index,
          }).id
        );
      }
      filteredTargetColors = targetColors
        .concat(CONSTANTS.BACKUP_STOCK_COLOR_IDS)
        .unique();
      //[6, 8, 10, 25, 23, 24] set of colors and backups
      for (
        let colorIndex = 0;
        colorIndex < filteredTargetColors.length;
        colorIndex++
      ) {
        const colorId = filteredTargetColors[colorIndex];
        for (let index = 0; index < this.product.variants.length; index++) {
          const variant = this.product.variants[index];
          if (variant.color_family === colorId) {
            filteredVariants.push(variant);
            break;
          }
        }
      }
      return filteredVariants;
    },
    default_layout() {
      if (this.productExists) {
        if (this.product.is_sublimable) {
          return this.product.layouts[0];
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions([
      "resetReversible",
      "setActiveCompanionProduct",
      "fixColor1",
      "lockStates",
      "unlockStates",
      "updateBreadcrumbs",
      "redirectToPageWithParams",
    ]),
    validateProduct() {
      if (!this.productExists) {
        this.redirectToPageWithParams({
          name: "Home",
        });
      } else {
        // this.getCompanionItems();
        this.updateBreadcrumbs([
          {
            name: "Product Designs",
            component: "Product",
          },
        ]);
      }
    },
  },
  components: {
    PageHeading,
    DesignEditor,
  },
  created() {
    this.lockStates(["sport", "gender", "colors"]);
    this.unlockStates(["event"]);

    if (!this.productExists) {
      this.$store.dispatch("redirectToPageWithParams", {
        name: "Home",
      });
    }

    //Let's copy the stamps from the main product for the first load

    this.setActiveCompanionProduct();
    // this.resetReversible();

    this.fixColor1(); //Fixes an issue that happens after edit stock items

    this.validateProduct();
  },
};
</script>


<style lang="scss">
section.page.container {
  @media screen and (max-width: 1024px) {
    padding-bottom: 5rem;
  }
}
.product-and-customization-container {
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
#app {
  .available-designs {
    width: 50%;
    display: flex;
    flex-direction: column;
    box-shadow: -6px 5px 14px rgba($black, 0.11);
    .available-designs-interior-container {
      padding: 15px 22px;
    }
    h2 {
      color: $black;
      font-family: $ubuntuMedium;
      font-size: 18px;
      line-height: 21px;
      font-weight: normal;
      text-transform: uppercase;

      padding-bottom: 7.5px;
      border-bottom: 1px solid $lighterGray;
      margin-bottom: 15.5px;
    }
    .design-boxes-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 17px 12px;
    }
  }
}
</style>
