<template>
  <div class="product-image" v-if="shadow">
    <!--<div v-if="!imageLoaded" class="flex justify-center align-center loader-container">
      <v-progress-circular size="20" indeterminate></v-progress-circular>
    </div>-->
    <svg
      :title="zoomable ? 'Click to zoom in' : ''"
      :class="{ zoomable: zoomable }"
      @click="doZoom()"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 800 1000"
      style="enable-background: new 0 0 800 1000"
      xml:space="preserve"
    >
      <template v-if="!isHybridSVG">
        <g>
          <path
            v-for="(path, index) in group_1"
            :key="index"
            :d="path"
            v-bind:style="{ fill: color_1 }"
          ></path>
        </g>

        <g>
          <path
            v-for="(path, index) in group_2"
            :key="index"
            :d="path"
            v-bind:style="{ fill: color_2 }"
          ></path>
        </g>

        <g>
          <path
            v-for="(path, index) in group_3"
            :key="index"
            :d="path"
            v-bind:style="{ fill: color_3 }"
          ></path>
        </g>
      </template>
      <template v-else>
          <g>
            <image
              v-if="hybridC1"
              width="800"
              height="1000"
              class="svg-background"
              :xlink:href="hybridC1"
            ></image>
          </g>
          <g>
            <image
              v-if="hybridC2"
              width="800"
              height="1000"
              class="svg-background"
              :xlink:href="hybridC2"
            ></image>
          </g>
          <g>
            <image
              v-if="hybridC3"
              width="800"
              height="1000"
              class="svg-background"
              :xlink:href="hybridC3"
            ></image>
          </g>
        </template>
      <image
        v-if="shadow && isVariantStock"
        width="800"
        height="1000"
        class="svg-background"
        :xlink:href="shadow"
      ></image>
      <image
        v-if="name_allowed"
        v-show="name_visible"
        class="svg-name"
        :width="stamp_name_properties ? stamp_name_properties.width : '0%'"
        :x="stamp_name_properties ? stamp_name_properties.x : '0%'"
        :y="stamp_name_properties ? stamp_name_properties.y : '0%'"
        :height="stamp_name_properties ? stamp_name_roster_size.height : '0%'"
        :xlink:href="stamp_name"
        v-bind:class="{ loading: text_name_loading }"
      ></image>
      <image
        v-if="number_allowed"
        v-show="number_visible"
        class="svg-number"
        :width="stamp_number_properties ? stamp_number_properties.width : '0%'"
        :x="stamp_number_properties ? stamp_number_properties.x : '0%'"
        :y="stamp_number_properties ? stamp_number_properties.y : '0%'"
        :height="
          stamp_number_properties ? stamp_number_roster_size.height : '0%'
        "
        :xlink:href="stamp_number"
        v-bind:class="{ loading: text_number_loading }"
      ></image>

      <!-- Logo -->
      <image
        v-if="logo_allowed"
        preserveAspectRatio="xMidYMin"
        v-show="stamp_logo"
        class="svg-logo"
        :width="stamp_logo_properties ? stamp_logo_properties.width : '0%'"
        :x="stamp_logo_properties ? stamp_logo_properties.x : '0%'"
        :y="stamp_logo_properties ? stamp_logo_properties.y : '0%'"
        :height="stamp_logo_properties ? stamp_logo_properties.height : '0%'"
        :xlink:href="stamp_logo"
        v-bind:class="{
          loading: text_logo_loading,
          placeholder: logoIsPlaceholder({ side, alternative }),
        }"
      ></image>
      <image
        v-if="shadow && !isVariantStock"
        width="800"
        height="1000"
        class="svg-background"
        :xlink:href="shadow"
      ></image>
      <VectorArtwork
        v-if="hasArtwork"
        :uniform_data="uniform_data"
        :product_type="product_type"
        :artwork="artwork"
        :side="side"
      ></VectorArtwork>
    </svg>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CONSTANTS from "@/constants.js";
import VectorArtwork from "../components/VectorArtwork.vue";

const default_properties = { width: "0%", height: "0%", x: "0%", y: "0%" };
const s3Url =
  "https://s3.amazonaws.com/marketing.foundersportgroup.com/flash-builder/"

export default {
  name: "Vector",
  props: [
    "zoomable",
    "svgs",
    "backgrounds",
    "side",
    "layout",
    "product_type",
    "is_stock",
    "uniform_data",
    "uniform_info",
    "stock_color",
    "isVariantStock",
    "alternative",
    "isSpiritwear",
    "isHybridSVG",
    "styleId",
    "designName"
  ],
  created() {
    /* var img = document.querySelector('image')

    if (img) {
      if (img.complete) {
        this.imageLoaded = true;
      } else {
        img.addEventListener('load', () => {
          this.imageLoaded = true;
        })
        img.addEventListener('error', function() {
          //console.log('error loading img') TODO show error image?
        })
      }
    }*/
  },
  components: {
    VectorArtwork,
  },
  computed: {
    ...mapGetters([
      "getColorHexByField",
      "getColorByField",
      "logoIsPlaceholder",
      "getColorById",
      "getArtwork",
      "activeProduct",
    ]),

    ...mapGetters("customer", ["spiritwearLogo", "uniformsLogo"]),

    ...mapState("customer", ["spiritwearLogo", "uniformsLogo"]),

    hybridC1() {
      
      const colorName = this.mapFlashColorName(this.getColorByField({
        product_type: this.product_type,
        field: "color_1",
      })
      .name.toLowerCase()
      .replaceAll(" ", "-")),
      
      designName = this.designName?.toLowerCase();

      return `${s3Url}${this.styleId}/${this.side}/${designName}_c1_${colorName}.png`
    },


    hybridC2() {
      
      const colorName = this.mapFlashColorName(this.getColorByField({
        product_type: this.product_type,
        field: "color_2",
      })
      .name.toLowerCase()
      .replaceAll(" ", "-")),
      
      designName = this.designName?.toLowerCase();

      return `${s3Url}${this.styleId}/${this.side}/${designName}_c2_${colorName}.png`
    },

    hybridC3() {
      
      const colorName = this.mapFlashColorName(this.getColorByField({
        product_type: this.product_type,
        field: "color_3",
      })
      .name.toLowerCase()
      .replaceAll(" ", "-")),
      
      designName = this.designName?.toLowerCase();

      return `${s3Url}${this.styleId}/${this.side}/${designName}_c3_${colorName}.png`
    },

    reversibleActive() {
      return !this.alternative
        ? this.activeProduct.reversibleActive
        : !this.activeProduct.reversibleActive;
    },

    sideKey() {
      return this.reversibleActive ? "reversible_" + this.side : this.side;
    },

    artwork() {
      if (!this.hasArtwork || !this.artworks) return false;
      if (!this.activeArtworkId) {
        return this.artworks[0];
      }
      for (let index = 0; index < this.artworks.length; index++) {
        const art = this.artworks[index];
        if (art.id === this.activeArtworkId) {
          return art;
        }
      }
      return false;
    },

    activeArtworkId() {
      return this.getArtwork({
        product: "main_product",
        side: this.sideKey,
      });
      //return this.$store.state[this.product_type + '_product'][this.side].artwork;
    },

    hasArtwork() {
      //if (this.isVariantStock) return false;
      if (
        !this.layout.layout_attributes ||
        !this.layout.layout_attributes[this.side]
      )
        return false;
      return (
        this.layout.layout_attributes[this.side] &&
        this.layout.layout_attributes[this.side].artwork
      );
    },

    artworks() {
      return this.layout.artworks;
    },
    color_1() {
      if (this.is_stock) {
        return this.stock_color;
      }
      return this.uniform_data
        ? this.getColorById(this.uniform_data.color_1).hex
        : this.getColorHexByField({
            field: "color_1",
            product_type: this.product_type,
            alternative: this.alternative,
          });
    },
    color_2() {
      return this.uniform_data
        ? this.getColorById(this.uniform_data.color_2).hex
        : this.getColorHexByField({
            field: "color_2",
            product_type: this.product_type,
            alternative: this.alternative,
          });
    },
    color_3() {
      return this.uniform_data
        ? this.getColorById(this.uniform_data.color_3).hex
        : this.getColorHexByField({
            field: "color_3",
            product_type: this.product_type,
            alternative: this.alternative,
          });
    },
    stamp_name() {
      let data;
      if (!this.uniform_data) {
        data = this.activeProduct[this.sideKey].name.stamp_url;
        if (!data.length) {
          if (this.svgs != null && this.svgs.name != null) {
            data = this.svgs.name.stamp_url;
          }
        }
      } else {
        data = this.uniform_data[this.sideKey].name.stamp_url;
      }
      return data ? data : false;
    },
    stamp_name_properties() {
      if (!this.uniform_data) {
        return Object.keys(this.layout).length > 0
          ? this.layout.layout_attributes[this.side].name
          : default_properties;
      } else {
        return this.uniform_data.layout.layout_attributes[this.side].name;
      }
    },
    stamp_name_roster_size() {
      let size_dif, current_height;
      if (this.activeProduct) {
        size_dif = 3 - parseInt(this.activeProduct[this.sideKey].name.size);
        current_height = this.stamp_name_properties.height;
      } else if (this.uniform_data) {
        size_dif = 3 - parseInt(this.uniform_data[this.sideKey].name.size);
        current_height = this.layout.layout_attributes[this.side].name.height;
      }
      if (size_dif != null && size_dif > 0) {
        return {
          ...this.stamp_name_properties,
          height: parseFloat(current_height) / (size_dif + 1) + "%",
        };
      }
      return this.stamp_name_properties;
    },
    stamp_number() {
      let data;

      if (!this.uniform_data) {
        data = this.activeProduct[this.sideKey].number.stamp_url;
        if (!data.length) {
          if (this.svgs != null && this.svgs.number != null) {
            data = this.svgs.number.stamp_url;
          }
        }
      } else {
        data = this.uniform_data[this.sideKey].number.stamp_url;
      }
      return data ? data : false;
    },
    stamp_number_properties() {
      if (!this.uniform_data) {
        return Object.keys(this.layout).length > 0
          ? this.layout.layout_attributes[this.side].number
          : default_properties;
      } else {
        return this.uniform_data.layout.layout_attributes[this.side].number;
      }
    },
    stamp_number_roster_size() {
      let size_dif, current_height;
      if (this.activeProduct) {
        size_dif = 3 - parseInt(this.activeProduct[this.sideKey].number.size);
        current_height = this.stamp_number_properties.height;
      } else if (this.uniform_data) {
        size_dif = 3 - parseInt(this.uniform_data[this.sideKey].number.size);
        current_height = this.layout.layout_attributes[this.side].number.height;
      }
      if (size_dif != null && size_dif > 0) {
        return {
          ...this.stamp_number_properties,
          height: parseFloat(current_height) / (size_dif + 1) + "%",
        };
      }
      return this.stamp_number_properties;
    },

    stamp_logo() {
      if (!this.uniform_data) {
        //TODO -> Averiguar QUE ES  uniform_data

        //If we are in the homepage, use the spiritwear/uniforms Logo that came from the Customer Settings

        if (this.currentRouteIsCatalogue) {

          return this.isSpiritwear
            ? this.spiritwearScreenLogo?.logo ||
                this.activeProduct[this.sideKey].logo.url ||
                CONSTANTS.DEFAULT_LOGO_URL //The Default Logo ('YOUR LOGO')
            : this.uniformsScreenLogo?.logo ||
                this.activeProduct[this.sideKey].logo.url ||
                CONSTANTS.DEFAULT_LOGO_URL; //The Default Logo ('YOUR LOGO')
        } else {
          // console.log("here 2");
          return (
            this.activeProduct[this.sideKey]?.logo?.url ||
            CONSTANTS.DEFAULT_LOGO_URL
          );
        }
      } else {
        // console.log("there");
        if (
          this.uniform_data?.layout?.layout_attributes[
            this.side
          ]?.logo?.url.indexOf(".com") !== -1 ||
          this.activeProduct[this.sideKey]?.logo?.url?.indexOf(
            "data:image/png;base64,"
          ) !== -1
        ) {
          return this.uniform_data.layout.layout_attributes[this.side].logo.url;
        } else {
          return this.uniform_data.layout.layout_attributes[this.side].logo.url;
        }
      }
    },

    uniformsScreenLogo() {
      return this.uniformsLogo.screenLogoProperties;
    },

    spiritwearScreenLogo() {
      return this.spiritwearLogo.screenLogoProperties;
    },

    stamp_logo_properties() {
      if (!this.uniform_data) {
        return Object.keys(this.layout).length > 0
          ? this.layout.layout_attributes[this.side].logo
          : default_properties;
      } else {
        return this.uniform_data.layout.layout_attributes[this.side].logo;
      }
    },
    group_1() {
      return this.svgs && this.svgs.group1 ? this.svgs.group1 : "";
    },
    group_2() {
      return this.svgs && this.svgs.group2 ? this.svgs.group2 : "";
    },
    group_3() {
      return this.svgs && this.svgs.group3 ? this.svgs.group3 : "";
    },
    shadow() {
      if (this.backgrounds) {
        let gender = this.$store.state.gender,
          sideName = !this.reversibleActive
            ? this.side
            : "reversible_" + this.side;
        let key = "shadow_" + gender + "_" + sideName;
        if (this.backgrounds[key]) {
          if (this.backgrounds[key].indexOf(".com") !== -1) {
            return this.backgrounds[key]; //returns api shadow
          }
          return this.$store.state.settings.endpoint + this.backgrounds[key];
        }
      }
      return false;
    },
    name_allowed() {
      if (!this.layout || !this.layout.layout_attributes) return false;
      return (
        this.layout.layout_attributes[this.side] != null &&
        this.layout.layout_attributes[this.side].name != null
      );
    },
    number_allowed() {
      //if (this.isVariantStock) return false;
      if (!this.layout || !this.layout.layout_attributes) return false;
      return (
        this.layout.layout_attributes[this.side] != null &&
        this.layout.layout_attributes[this.side].number != null
      );
    },
    logo_allowed() {
      //if (this.isVariantStock) return false;
      if (!this.layout || !this.layout.layout_attributes) return false;
      return this.layout && this.layout.layout_attributes && !this.uniform_data
        ? (this.layout.layout_attributes[this.side] != null &&
            Object.keys(this.activeProduct.layout).length > 0 &&
            this.activeProduct.layout.layout_attributes[this.side]?.logo !=
              null) ||
            (this.layout.layout_attributes[this.side] &&
              this.layout.layout_attributes[this.side]?.logo &&
              Object.keys(this.layout.layout_attributes[this.side]?.logo)
                .length > 0)
        : false;
    },
    name_visible() {
      return !this.uniform_data
        ? this.activeProduct[this.sideKey].name != null &&
            this.activeProduct[this.sideKey].name.visible
        : this.uniform_data[this.sideKey].name != null &&
            this.uniform_data[this.sideKey].name.visible;
    },
    number_visible() {
      return !this.uniform_data
        ? this.activeProduct[this.sideKey].number != null &&
            this.activeProduct[this.sideKey].number.visible
        : this.uniform_data[this.sideKey].number != null &&
            this.uniform_data[this.sideKey].number.visible;
    },

    currentRouteIsCatalogue() {
      return (
        this.$router.currentRoute.name === "CatalogueWithTeamLink" ||
        this.$router.currentRoute.name === "Catalogue"
      );
    },
  },
  data() {
    return {
      text_name_loading: false,
      text_number_loading: false,
      text_logo_loading: false,
      //imageLoaded: false,
      // activeArtworkId: this.$store.state[this.product_type + '_product'][this.side].artwork || this.$store.state.artworks[0].id,
      //  artwork: false,
    };
  },
  methods: {
    ...mapGetters(["getTeamNameUrl"]),
    mapFlashColorName(colorName) {
      switch (colorName) {
        case "texas": {
          return "texas-orange"
        }
        case "columbia": {
          return "columbia-blue"
        }
        case "royal": {
          return "royal-blue"
        }
        default: {
          return colorName
        }
      }
    },
    doZoom() {
      if (this.zoomable) {
        this.$emit("zoomSVG", {
          side: this.side,
          svgs: this.svgs,
          backgrounds: this.backgrounds,
          layout: this.layout,
          product_type: this.product_type,
          is_stock: this.is_stock,
          stockColor: this.stockColor,
          isVariantStock: this.isVariantStock,
        });
      }
    },
    getArtworkById(id) {
      for (let index = 0; index < this.artworks.length; index++) {
        const element = this.artworks[index];
        if (element.id === id) {
          return element;
        }
      }
      return false;
    },
    setStampValues(prop, field, _default) {
      let val = _default;

      if (this.activeProduct[this.sideKey][field].stamp_url.length) {
        val = this.activeProduct[this.sideKey][field].stamp_url;
      } else {
        if (this.svgs !== undefined) {
          val = this.svgs[field];
        }
      }
      this[prop] = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.loader-container {
  height: 100%;
}
.zoomable {
  cursor: zoom-in;
}
.loading {
  opacity: 0.4;
}
.placeholder {
  outline: dashed white;
}
</style>