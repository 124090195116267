<template>
  <div id="search-customization" class="page-bg">
    <div id="search-sidebar">
      <div class="head"></div>
      <div class="sidebar-body flex flex-column">
        <div class="interior-container">
          <h3 class="section-title">Sku + "customization id"</h3>
          <input
            v-model="search"
            @keydown="error = false"
            placeholder="xxxx-xxxxxx-xxx"
          />
          <span v-if="error" class="flex align-center justify-center error-info"
            >Invalid search value</span
          >
          <button
            v-if="!loading"
            type="button"
            class="btn black-btn"
            @click="submit()"
          >
            Search
          </button>
          <v-progress-circular
            class="loader"
            v-else
            width="2"
            size="15"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </div>
    <div v-if="hasResult" class="customization-details">
      <div class="head"></div>
      <div class="customization-details-wrapper">
        <h3 class="section-title">Configuration Details</h3>
        <div class="details-body">
          <div class="detail-row">
            <div class="detail-label">Style Name</div>
            <div class="detail-value">{{ result.style }}</div>
          </div>
          <div class="detail-row">
            <div class="detail-label">Style Number</div>
            <div class="detail-value">{{ styleNumber }}</div>
          </div>
          <div class="detail-row">
            <div class="detail-label">Design Name</div>
            <div class="detail-value">{{ result.design }}</div>
          </div>
          <div class="detail-row">
            <div class="detail-label">Design Colors</div>
            <div class="detail-value">{{ result.colors }}</div>
          </div>
          <div class="detail-row">
            <div class="detail-label">Quantity</div>
            <div class="detail-value">{{ result.quantity }}</div>
          </div>
          <div
            class="layersides-information"
            v-for="layerSide in ['front', 'back', 'side']"
            :key="layerSide"
          >
            <div
              class="layerside-information-container"
              v-if="
                result[layerSide + '_elements'] &&
                Object.keys(result[layerSide + '_elements']).length
              "
            >
              <h3 class="section-title">{{ layerSide }} elements</h3>
              <div class="layerside-information-wrapper">
                <div class="layerside-information-body">
                  <div
                    class="detail-row artwork"
                    v-if="result[layerSide + '_elements'].artwork"
                  >
                    <div class="detail-label">Artwork</div>
                    <div class="detail-value">
                      <a
                        :href="result[layerSide + '_elements'].artwork"
                        target="_blank"
                        >download</a
                      >
                    </div>
                  </div>
                  <div
                    class="field-container"
                    v-for="field in ['name', 'number']"
                    :key="field"
                  >
                    <div
                      class="field-wrapper"
                      v-if="result[layerSide + '_elements'][field] != null"
                    >
                      <div class="detail-row">
                        <div class="detail-label">{{ field }}</div>
                        <div class="detail-value">
                          {{ result[layerSide + "_elements"][field] }}
                        </div>
                      </div>
                      <div class="detail-row">
                        <div class="detail-label">{{ field }} colors</div>
                        <div class="detail-value">
                          {{
                            result[layerSide + "_elements"][field + "_colors"]
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="layerside-image-proof">
                  <img :src="result[layerSide + '_elements'].proof" />
                  <a
                    :href="result[layerSide + '_elements'].proof"
                    target="_blank"
                    >enlarge</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Search",
  methods: {
    submit() {
      if (this.loading) return false;
      this.loading = true;
      this.result = {};
      axios
        .get(
          this.$store.state.settings.endpoint + "api/v2/search-customization",
          {
            headers: {},
            params: {
              search: this.search,
            },
          }
        )
        .then((response) => {
          this.result = response.data;
          this.processed = response.data.processed;
          this.styleNumber = this.search;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
  },
  data() {
    return {
      search: "",
      styleNumber: "",
      loading: false,
      processed: false,
      error: false,
      result: {},
    };
  },
  computed: {
    hasResult() {
      return this.result && Object.keys(this.result).length;
    },
  },
  mounted() {
    this.$store.commit("updateBreadcrumbs", {
      state: "Breadcrumbs",
      value: [
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  .page-bg {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    background: url("~@/assets/bg.jpg");
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: 0 100%;
    position: relative;
    .customization-details {
      font-family: $ubuntuLight;
      font-size: 14px;
      max-height: 65vh;
      overflow-y: scroll;
      width: 600px;
      background-color: $white;
      box-shadow: -6px 5px 14px rgba($black, 0.26);
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      margin-left: 30px;
      .customization-details-wrapper {
        padding: 20px;
        .process {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .disabled {
            opacity: 0.3;
            outline: none;
          }
        }
        .layersides-information {
          .section-title {
            margin-top: 15px;
          }
          .layerside-information-wrapper {
            display: flex;
            .layerside-information-body {
              width: 70%;
            }
            .layerside-image-proof {
              text-align: center;
              width: 30%;
              img {
                max-width: 100%;
              }
            }
          }
        }
        .detail-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 3px;
          .detail-label {
            text-transform: capitalize;
            width: 30%;
          }
          .detail-value {
            width: 70%;
          }
        }
      }
    }
    .head {
      background-color: $blackGray;
      color: $white;
      text-transform: uppercase;
      padding: 15px 36px;
      margin: 0;
    }
    h3.section-title {
      color: $darkGray;
      text-transform: uppercase;
      margin-bottom: 5.5px;
    }
    #search-sidebar {
      width: 366px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .sidebar-body {
        background-color: $white;
        box-shadow: -6px 5px 14px rgba($black, 0.26);
        padding: 15px 20px 25px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        .interior-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .loader {
            margin-top: 20px;
          }
          input {
            background: #ffffff;
            padding: 7px 14px;
            border: 1px solid rgba(184, 180, 180, 0.49);
            border-radius: 14px;
            width: 100%;
            outline: none;
            color: #121213;
            font-family: "Ubuntu Light";
            font-size: 12px;
            line-height: 13px;
          }
          .error-info {
            color: $red;
            font-size: 12px;
          }
          button {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>