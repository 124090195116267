<template>
  <div class="design-editor">
    <ProductInformation
      :name="product.name"
      :backgrounds="product.backgrounds"
      :tier="activeProduct.tier_id"
      :layout="productLayout"
      :product_type="product_type"
      :description="product.description"
      :is_stock="!product.is_sublimable"
      :isVariantStock="isVariantStock"
      :ref="'productSlider'"
    ></ProductInformation>
    <div class="right-box-container">
      <div class="right-box-container-interior-container">
        <h2>{{ productVariationsTitle }}</h2>
        <div class="design-boxes-container">
          <DesignBox
            v-for="(item, index) in designs"
            :key="index"
            :design="item"
            :backgrounds="product.backgrounds"
            :tier="product.tier_id"
            :layout="productLayout"
            :product_type="product_type"
            :product_is_stock="!product.is_sublimable"
            :isVariantStock="isVariantStock"
            :styleId="product.style_id"
            v-on="{ designUpdated: scrollToSlider }"
          />
        </div>
      </div>
      <template v-if="activeProductType === 'main_product'">
        <ProductNavigation
          v-if="product.layouts && product.layouts.length"
          :back="{
            link: backlink,
            label: 'select style',
            button: '≪ BACK',
            disabled: this.editMode,
          }"
          :next="{ link: 'layout', label: 'Select layout', button: 'NEXT ≫' }"
        />

        <ProductNavigation
          v-else
          :back="{
            link: backlink,
            label: 'select style',
            button: '≪ BACK',
            disabled: this.editMode,
          }"
          :next="{
            link: '',
            label: '',
            button: 'COMPLETE DESIGN',
            event: hasCompanionItems ? 'companion' : 'complete',
          }"
          :nextEventEnabled="true"
        />
      </template>
      <template v-else>
        <ProductNavigation
          v-if="product.layouts && product.layouts.length"
          :back="{
            link: 'layout',
            label: 'return to previous item',
            button: '≪ BACK',
          }"
          :next="{
            link: 'companion-layout',
            label: 'Select layout',
            button: 'NEXT ≫',
          }"
        />
        <ProductNavigation
          v-else
          :back="{
            link: 'layout',
            label: 'return to previous item',
            button: '≪ BACK',
          }"
          :next="{
            link: '',
            label: '',
            button: 'COMPLETE DESIGN',
            event: 'complete',
          }"
          :nextEventEnabled="true"
        />
      </template>
    </div>
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";
import { mapGetters } from "vuex";

import DesignBox from "@/components/single-product/DesignBox.vue";
import ProductNavigation from "@/components/single-product/ProductNavigation.vue";
import ProductInformation from "@/components/single-product/ProductInformation";

export default {
  name: "DesignEditor",
  props: ["activeProduct", "product", "product_type"],
  computed: {
    ...mapGetters([
      "getColorByField",
      "dealer",
      "editMode",
      "getGender",
      "companionItems",
      "activeProductType",
    ]),
    productExists() {
      return this.product && Object.keys(this.product).length > 0;
    },
    isVariantStock() {
      return this.product.variants ? this.product.variants.length > 0 : false;
    },
    productLayout() {
      return this.active_layout
        ? this.active_layout
        : this.product && this.product.layouts
        ? this.product.layouts[0]
        : false;
    },
    active_layout() {
      return this.activeProduct.layout;
    },
    productVariationsTitle() {
      if (this.dealer && this.dealer.settings) {
        if (
          this.dealer.settings.labels &&
          this.dealer.settings.labels.product_variations_title
        ) {
          return this.dealer.settings.labels.product_variations_title;
        } else {
          return "AVAILABLE DESIGNS";
        }
      }
      return "";
    },
    backlink() {
      return this.$route.params.showroom_id ? "" : "catalogue";
    },
    design() {
      return this.activeProduct.design;
    },
    designs() {
      if (this.productExists) {
        let gender = this.getGender;
        if (!this.isVariantStock) {
          return this.product.designs_by_gender[gender];
        } else {
          return this.filteredStockVariants;
        }
      }
      return false;
    },
    filteredStockVariants() {
      if (!this.isVariantStock) return false;
      //We need to get the first match for C1, C2 and C3 and then the backup colors
      let targetColors = [],
        filteredTargetColors = [],
        filteredVariants = [];
      for (let index = 1; index <= 3; index++) {
        targetColors.push(
          this.getColorByField({
            product_type: this.product_type,
            field: "color_" + index,
          }).id
        );
      }

      filteredTargetColors = targetColors
        .concat(CONSTANTS.BACKUP_STOCK_COLOR_IDS)
        .unique();
      // .sort();

      //[6, 8, 10, 25, 23, 24] set of colors and backups

      filteredTargetColors.map((filteredColorId) => {
        this.product.variants.map((variant) => {
          if (filteredColorId == variant.color_family) {
            filteredVariants.push(variant);
          }
        });
      });

      return filteredVariants;
    },
    hasCompanionItems() {
      return this.companionItems && this.companionItems.length > 0;
    },
  },

  methods: {
    scrollToSlider() {
      const productSlider = document.getElementById("slider-container");
      productSlider.scrollIntoView({ behavior: "smooth" });
    },
  },

  components: {
    DesignBox,
    ProductInformation,
    ProductNavigation,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
section.page.container {
  @media screen and (max-width: 1024px) {
    padding-bottom: 5rem;
  }
}
.product-and-customization-container {
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
#app {
  .design-editor {
    flex: 1;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    .right-box-container {
      width: 632px;
      display: flex;
      flex-direction: column;
      box-shadow: -6px 5px 14px rgba($black, 0.11);
      .right-box-container-interior-container {
        padding: 15px 22px;
      }
      h2 {
        color: $black;
        font-family: $ubuntuMedium;
        font-size: 18px;
        line-height: 21px;
        font-weight: normal;
        text-transform: uppercase;

        padding-bottom: 7.5px;
        border-bottom: 1px solid $lighterGray;
        margin-bottom: 15.5px;
      }
      .design-boxes-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 17px 12px;
      }
    }

    @media screen and (max-width: 1440px) {
      /* flex-flow: column; */
      width: 100%;

      #slider-container {
        width: 60%;
      }
      .right-box-container {
        width: 50%;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-flow: column;
      width: 100%;

      #slider-container {
        width: 100%;
      }
      .right-box-container {
        width: 100%;
        .design-boxes-container {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>