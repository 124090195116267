<template>
  <div id="catalogue-sidebar-logo">
    <!-- Uniforms Logo Selection -->
    <CatalogueSidebarLogo
      v-if="showUniformsLogoSidebar(filteredCatalogs)"
      :typeOfLogo="'uniforms'"
      :logo="uniformsLogo"
      :refName="'uploadUniformLogo'"
    />

    <!-- Spiritwear Logo Selection -->
    <CatalogueSidebarLogo
      v-if="showSpiritwearLogoSidebar(filteredCatalogs)"
      :typeOfLogo="'spiritwear'"
      :logo="spiritwearLogo"
      :refName="'uploadSpiritwearLogo'"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CatalogueSidebarLogo from "@/components/catalogue/CatalogueSidebarLogo";
export default {
  name: "CatalogueSidebar",

  props: ["filteredCatalogs"],

  computed: {
    ...mapGetters(["showSpiritwearLogoSidebar", "showUniformsLogoSidebar"]),
    ...mapState("customer", ["uniformsLogo", "spiritwearLogo"]),
  },

  components: {
    CatalogueSidebarLogo,
  },
};
</script>
