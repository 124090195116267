<template>
  <div class="zoom-container">
    <div class="zoom">
      <span class="close-zoom btn black-btn" @click="close()">Close</span>
      <Vector
        :side="zoomData.side"
        :svgs="zoomData.svgs"
        :backgrounds="zoomData.backgrounds"
        :layout="zoomData.layout"
        :product_type="zoomData.product_type"
        :is_stock="zoomData.is_stock"
        :stock_color="zoomData.stockColor"
        :isVariantStock="zoomData.isVariantStock"
      />
    </div>
  </div>
</template>

<script>
import Vector from "@/components/Vector";

export default {
  name: "VectorZoom",
  props: ["zoomData"],
  components: {
    Vector,
  },
  methods: {
    close() {
      this.$emit("closeZoom");
    },
  },
};
</script>

<style lang="scss" scoped>
.zoom-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
  .zoom {
    border: 1px solid black;
    background-color: white;
    z-index: 3;
    max-width: 800px;
    overflow-y: scroll;
    height: 90vh;
    width: 90vw;
    position: relative;
    .close-zoom {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}
</style>