import CONSTANTS from '@/constants.js';
const project = {

  namespaced: true,

  getters: {

    getColorById: () => (id) => {
      return CONSTANTS.COLORS.find((color) => {
        return color.id === id
      })
    },

  },


}

export default project