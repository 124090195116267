<template>
  <!-- v-show="designs && designs.length && inPriceRange" -->
  <div
    v-if="designs && designs.length && inPriceRange && loaded"
    class="product-card fade-in"
    @mouseover="mouseOver"
    @mouseleave="showDesigns = false"
  >
    <div class="tags-container flex justify-space-between">
      <TierTag :tier="tier" :isVariantStock="isVariantStock"></TierTag>
      <div class="colors-tag flex align-center" v-if="product.is_sublimable">
        <span>Logo +</span>
        <img src="~@/assets/icons/color-wheel.svg" alt="" />
      </div>
    </div>
    <div
      class="product-preview"
      @mouseover="showCustomizeBtn = true"
      @mouseleave="showCustomizeBtn = false"
    >
      <Vector
        :side="side"
        :svgs="svgs(side)"
        :backgrounds="backgrounds"
        :layout="defaultLayout"
        :product_type="product_type"
        :is_stock="!product.is_sublimable"
        :stock_color="stockColor"
        :isVariantStock="isVariantStock"
        :isSpiritwear="isSpiritwear"
        :styleId="product.style_id"
        :designName="designName"
        :isHybridSVG="isHybridSVG"
      />
      <div
        class="btn white-btn customize-btn"
        v-show="showCustomizeBtn"
        @click="selectProduct(product, design)"
      >
        {{ event_loading ? "Loading" : "Customize It" }}
      </div>
      <div @mouseover.stop="showCustomizeBtn = false" v-if="designs.length > 1">
        <ProductDesigns
          v-show="showDesigns"
          :backgrounds="productDesignBackgrounds"
          :designs="designs"
          :side="side"
          ::isSpiritwear="isSpiritwear"
          @selectDesign="updateSelectedDesign"
          :layout="defaultLayout"
          :product_type="product_type"
          :is_stock="!product.is_sublimable"
          :isVariantStock="isVariantStock"
          :filteredStockVariants="filteredStockVariants"
          :designsVisible="designsVisible"
          :styleId="product.style_id"
          :designName="designName"
          :isHybridSVG="isHybridSVG"
        ></ProductDesigns>
      </div>
    </div>
    <hr />
    <div class="product-info">
      <h3>{{ product.name }}</h3>
      <p class="design">{{ designName }}</p>
      <div class="size-price flex justify-space-between">
        <p class="size">
          {{
            this.sizes
              ? this.sizes[0] + "-" + this.sizes[this.sizes.length - 1]
              : ""
          }}
        </p>
        <p v-if="!hidePrice" class="price">MRSP: ${{ product.price }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vector from "../components/Vector.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import TierTag from "./TierTag";
import ProductDesigns from "./catalogue/ProductDesigns";
import axios from "axios";
import CONSTANTS from "@/constants.js";

export default {
  props: [
    "product",
    "side",
    "product_type",
    "productsLength",
    "filteredTargetColors",
    "hidePrice",
    "priceRange",
    "cardIndex",
    "catalogId",
    "isSpiritwear",
  ],
  name: "ProductCard",
  data() {
    return {
      loaded: false,
      designsVisible: false,
      files: [],
      designs: [],
      design: [],
      tier: "",
      sizes: [],
      background: [],
      showDesigns: false,
      showCustomizeBtn: false,
      stockColor: false,
      variantStockDesignSelected: 0,
    };
  },
  computed: {
    ...mapState(["gender"]),
    ...mapState("customer", ["spiritwearLogo", "uniformsLogo"]),
    ...mapGetters(["getColorByField", "lockerId"]),
    ...mapGetters("customer", ["getLogoById"]),

    isHybridSVG() {
      return this.design.color === "precolored"
    },

    designName() {
      if (!this.isVariantStock && this.design) {
        return this.design.name;
      }
      if (
        this.filteredStockVariants &&
        this.filteredStockVariants[this.variantStockDesignSelected]
      ) {
        return this.filteredStockVariants[
          this.variantStockDesignSelected
        ].color_name.replace(/_/g, " ");
      }
      return "N/A";
    },
    filteredStockVariants() {
      let filteredVariants = [];
      if (!this.isVariantStock) return false;

      this.filteredTargetColors.map((filteredColorId) => {
        this.designs.map((variant) => {
          if (filteredColorId == variant.color_family) {
            filteredVariants.push(variant);
          }
        });
      });

      return filteredVariants.length ? filteredVariants : this.designs;
    },
    defaultLayout() {
      //chequear si es un uniform o un spiritwear
      //chequear si hay logo activo para su tipo (unif/spiritwear)
      const isTypeOfProductLogoActive = this.isSpiritwear
        ? !!this.spiritwearLogo.id
        : !!this.uniformsLogo.id;

      const teamNameLayout = this.product.layouts.filter(
        (layout) => layout?.layout_attributes?.front?.name
      )[0];

      const logoLayout = this.product.layouts.filter(
        (layout) => layout?.layout_attributes?.front?.logo
      )[0];

      //Mostrar layout con logo si hay logo
      return isTypeOfProductLogoActive
        ? logoLayout || this.product.layouts[0] || []
        : teamNameLayout || this.product.layouts[0] || [];
    },

    isVariantStock() {
      return this.product.variants ? this.product.variants.length > 0 : false;
    },
    productDesignBackgrounds() {
      if (!this.isVariantStock) {
        return this.product.backgrounds;
      } else {
        return this.product.variants;
      }
    },
    backgrounds() {
      if (!this.isVariantStock) {
        return this.product.backgrounds;
      } else {
        //todo optimize this part
        if (!this.variantStockDesignSelected) {
          //get closest matching family color
          for (let colorIndex = 1; colorIndex <= 3; colorIndex++) {
            //starting from c1
            const colorId = this.getColorByField({
              //get c1-3 id
              product_type: this.product_type,
              field: "color_" + colorIndex,
            }).id;
            for (
              let variantIndex = 0;
              variantIndex < this.product.variants.length;
              variantIndex++
            ) {
              const variant = this.product.variants[variantIndex]; //search through all variations
              if (variant.color_family === colorId) {
                //if it matches the current colorid, we show it
                return this.product.variants[variantIndex].backgrounds;
              }
            }
          }
          //no matching variant for c1-3, so we search for white, black and silver, in that order
          for (
            let variantIndex = 0;
            variantIndex < this.product.variants.length;
            variantIndex++
          ) {
            const variant = this.product.variants[variantIndex]; //search through all variations
            for (
              let backupColorIndex = 0;
              backupColorIndex < CONSTANTS.BACKUP_STOCK_COLOR_IDS.length;
              backupColorIndex++
            ) {
              const backupColorId =
                CONSTANTS.BACKUP_STOCK_COLOR_IDS[backupColorIndex];
              if (variant.color_family === backupColorId) {
                return this.product.variants[variantIndex].backgrounds;
              }
            }
          }
        }
        return this.filteredStockVariants &&
          this.filteredStockVariants[this.variantStockDesignSelected]
          ? this.filteredStockVariants[this.variantStockDesignSelected]
              .backgrounds
          : []; //show nothing, something is wrong, maybe missing color family
      }
    },
    inPriceRange() {
      return (
        this.productsLength === 1 ||
        (parseFloat(this.product.price) >= this.priceRange[0] &&
          parseFloat(this.product.price) <= this.priceRange[1])
      );
    },
    event_loading() {
      return this.$store.state.locked.event;
    },
  },
  components: {
    Vector,
    ProductDesigns,
    TierTag,
  },
  methods: {
    ...mapActions(["setDefaultLogo", "getColorIdFromFamily"]),
    loadCard() {
      setTimeout(() => {
        this.loaded = true;
      }, (this.cardIndex + 1) * 25);
    },
    mouseOver() {
      this.designsVisible = true;
      this.showDesigns = true;
    },
    svgs(side) {
      return this.design
        ? this.design.svg_data
          ? this.design.svg_data[side]
          : { group1: [], group2: [], group3: [] }
        : null;
    },
    getDesigns() {
      //could be refactored as a computed prop?
      let designs = [];
      let gender = this.$store.state.gender;

      if (this.product.is_sublimable) {
        if (this.product.designs_by_gender) {
          if (this.product.designs_by_gender[gender]) {
            designs = this.product.designs_by_gender[gender];
          }
        }
        this.designs = designs;
        this.design = designs.length ? designs[0] : [];
      } else {
        if (!this.isVariantStock) {
          //designs come as an array of colors for masks
          this.designs = this.product.designs_by_gender[gender];
          this.design = this.product.designs_by_gender[gender].length
            ? this.product.designs_by_gender[gender][0]
            : null;
        } else {
          this.designs = this.product.variants.filter((variant) => {
            return Object.keys(variant.backgrounds).includes(
              "shadow_" + gender + "_front"
            );
          }); //filter by gender
          this.design =
            this.designs && this.designs.length
              ? this.designs[0].backgrounds["shadow_" + gender + "_front"]
              : null;
        }
      }
      this.stockColor = this.product.is_sublimable
        ? false
        : this.design
        ? this.design.color
        : false;
    },
    getDesignBySide() {
      let face = this.side;
      return this.design.svg_data[face];
    },
    getTiers() {
      this.tier = this.product.tier_id;
    },
    getSizes() {
      let gender = this.$store.state.gender == 0 ? "men" : "women";
      this.sizes =
        this.product.sizes !== null ? this.product.sizes[gender] : "";
    },
    updateSelectedDesign(index) {
      this.design = this.designs[index];
      if (!this.product.is_sublimable) {
        if (this.isVariantStock) {
          this.variantStockDesignSelected = index;
        } else {
          this.stockColor = this.design.color;
        }
      }
    },
    async selectProduct(product, design) {
      if (!this.event_loading) {
        try {
          this.$store.commit("updateSubState", {
            state: "locked",
            substate: "event",
            value: true,
          });

          let params = {
            product_id: product.id,
            catalog_id: this.catalogId,
          };

          if (this.lockerId) {
            params.teamlink = this.lockerId;
          }

          const response = await axios.get(
            this.$store.state.settings.endpoint + "api/v2/product",
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.settings.token,
              },
              params: params,
            }
          );

          if (response) {
            //Ask Fran how to set the Spiritwaer/Uniforms Logo with this functions
            let item = response.data;
            this.$store.commit("updateSubState", {
              state: this.product_type + "_product",
              substate: "product",
              value: item,
            });

            this.$store.commit("updateSubState", {
              state: this.product_type + "_product",
              substate: "layout",
              value: this.defaultLayout,
            });

            this.$store.commit("updateSubState", {
              state: this.product_type + "_product",
              substate: "reversible_layout",
              value: this.defaultLayout,
            });

            if (!this.$store.state.main_product.front.logo) {
              this.$store.commit("updateSubStateField", {
                state: this.product_type + "_product",
                substate: "front",
                field: "logo",
                value: CONSTANTS.DEFAULT_PRODUCT_DATA.front.logo,
              });
              this.setDefaultLogo({
                product_type: this.product_type + "_product",
                side: "front",
                field: "logo",
              });
            }

            //search selected design
            if (!this.isVariantStock) {
              let gender = this.$store.state.gender;
              let designs = item.designs_by_gender[gender];
              for (let index = 0; index < designs.length; index++) {
                const element = designs[index];
                if (element.id === design.id) {
                  this.$store.commit("updateSubState", {
                    state: this.product_type + "_product",
                    substate: "design",
                    value: element,
                  });

                  if (!product.is_sublimable) {
                    this.$store.commit("updateState", {
                      state: "stockColor",
                      value: element.color,
                    });
                  }

                  let next_page;
                  if (this.designs.length > 1) {
                    next_page = "Product";
                  } else {
                    next_page = "MainProductS2";
                  }
                  this.$store.dispatch("redirectToPageWithParams", {
                    name: next_page,
                  });
                  break;
                }
              }
            } else {
              //get full design searching with the selected variantid
              let selectedStockDesign =
                  this.filteredStockVariants[this.variantStockDesignSelected]
                    .color_id,
                stockDesign =
                  this.filteredStockVariants[this.variantStockDesignSelected];
              for (
                let stockDesignsIndex = 0;
                stockDesignsIndex < item.variants.length;
                stockDesignsIndex++
              ) {
                const itemVariant = item.variants[stockDesignsIndex];
                if (itemVariant.color_id === selectedStockDesign) {
                  stockDesign = itemVariant;
                  break;
                }
              }
              this.$store.commit("updateSubState", {
                state: this.product_type + "_product",
                substate: "design",
                value: stockDesign,
              });

              this.$store.dispatch("redirectToPageWithParams", {
                name: "Product",
              });
            }

            //EXPERIMENTAL - NEED TO TEST (AND ASK FRAN)
            //update Active Product logo
            if (
              (this.isSpiritwear && this.spiritwearLogo?.id) ||
              this.uniformsLogo?.id
            ) {
              this.$store.commit("updateSubState", {
                state: "team_link",
                substate: "logo_converted",
                value: this.isSpiritwear
                  ? this.spiritwearLogo.screenLogoProperties?.logo
                  : this.uniformsLogo.screenLogoProperties?.logo,
              });

              this.$store.commit("updateSubState", {
                state: "team_link",
                substate: "logo_original",
                value: this.isSpiritwear
                  ? this.getLogoById(this.spiritwearLogo.id).content
                  : this.getLogoById(this.uniformsLogo.id).content,
              });

              //Set the Active Screen Logo (To do -> this should be a method a module)
              this.$store.commit(
                "setNewActiveScreenLogo",
                this.isSpiritwear ? this.spiritwearLogo : this.uniformsLogo
              );
            }

            //upload after call to setDefaultLogo for both prods, uses team link logo
            let layersides = CONSTANTS.LAYER.SIDES;
            for (let index = 0; index < layersides.length; index++) {
              const layerside = layersides[index];
              this.setDefaultLogo({
                product_type: "main_product",
                side: layerside,
                field: "logo",
                logoRemoved: false,
              });

              this.setDefaultLogo({
                product_type: "companion_product",
                side: layerside,
                field: "logo",
                logoRemoved: false,
              });
            }
          }

          this.$store.commit("SET_ACTIVE_PRODUCT_CATALOG_ID", this.catalogId);
        } catch (error) {
          this.$toastr.e("Error trying to get product information");
        } finally {
          this.$store.commit("updateSubState", {
            state: "locked",
            substate: "event",
            value: false,
          });
        }
      }
    },
  },
  watch: {
    gender: {
      handler() {
        this.getDesigns();
        this.getTiers();
        this.getSizes();
      },
    },
  },
  created() {
    this.getDesigns();
    this.getTiers();
    this.getSizes();
    this.designsVisible = !this.isVariantStock;
  },
  mounted() {
    this.loadCard();
  },
};
</script>

<style lang="scss" scoped>
#app {
  .fade-in {
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .product-card {
    border-radius: 4px;
    border: 1px solid $lighterGray;
    padding: 7px 0 13px;
    position: relative;
    .tags-container {
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 7px;
      width: 100%;
      .colors-tag {
        margin-right: 8px;
        background-color: #f1f1f1;
        border-radius: 9px;
        padding: 2px;
        span {
          color: $black;
          font-family: $ubuntuBold;
          font-size: 11px;
          line-height: 12px;
          text-transform: uppercase;
          margin: 0 4px;
        }
      }
    }
    .product-preview {
      height: 184.5px;
      position: relative;
      .customize-btn {
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        padding: 8px 13px;
        width: 120px;
      }
      .product-image {
        height: 100%;
        text-align: center;
        ::v-deep svg {
          height: 100%;
        }
      }
    }
    hr {
      margin: 0 0 6.5px;
      border: none;
      height: 1px;
      background-color: $lighterGray;
    }
    .product-info {
      padding: 0 12px;
      h3 {
        color: $darkerGray;
        line-height: 17px;
        letter-spacing: 0;
        text-transform: uppercase;
        margin-bottom: 3px;

        height: 34px;
        overflow: hidden;
      }
      .design {
        font-family: $ubuntuLightItalic;
        font-style: italic;
        color: $darkGray;
        font-size: 12px;
        line-height: 13px;
        margin-bottom: 29px;
        text-transform: capitalize;
      }
      .size-price {
        .price,
        .size {
          font-family: $ubuntuLight;
          font-size: 12px;
          line-height: 13px;
        }
      }
    }
  }
}
</style>