<template>
  <v-app>
    <div id="nav">
      <DealerHeader />
      <Breadcrumbs />
    </div>
    <TransitionPage>
      <router-view />
    </TransitionPage>
    <Footer v-on="{ openDealerLogin, dealerLogout, closeDealerLogin }" />
    <transition name="fade">
      <DealerLoginModal
        v-if="showLoginModal"
        v-on="{ successLogin, closeModal }"
      />
    </transition>
  </v-app>
</template>

<script>
import Breadcrumbs from "./components/Breadcrumbs.vue";
import TransitionPage from "./components/TransitionPage";
import DealerHeader from "./components/DealerHeader.vue";
import Footer from "./components/Footer.vue";
import DealerLoginModal from "./components/DealerLoginModal.vue";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "app",
  components: {
    Breadcrumbs,
    DealerHeader,
    DealerLoginModal,
    Footer,
    TransitionPage,
  },

  data() {
    return {
      showLoginModal: false,
    };
  },

  computed: {
    ...mapGetters(["getDealerSettings"]),
    ...mapState(["customerId"]),
    ...mapState("customer", ["logos"]),
  },

  methods: {
    ...mapActions("customer", ["initCustomer"]),
    ...mapActions(["dealerLogin", "getSessionData", "checkIfSessionExists"]),
    ...mapMutations(["setDealerMode"]),

    openDealerLogin() {
      this.showLoginModal = true;
    },
    closeDealerLogin() {
      this.showLoginModal = false;
      //scroll to top?
    },
    dealerLogout() {
      this.showLoginModal = false;
    },
    successLogin() {
      this.showLoginModal = false;
    },
    closeModal() {
      this.showLoginModal = false;
    },
  },

  created() {},

  mounted() {
    const { dealer_mode, passcode } = this.$router.currentRoute.query;

    // Delete Login params from the url
    let query = Object.assign({}, this.$router.currentRoute.query);

    if (query.passcode) {
      delete query.passcode;
      delete query.dealer_mode;
      this.$router.replace({ query });
    }

    if (
      this.$router.currentRoute.name !== "SearchGuest" &&
      this.$router.currentRoute.name !== "Orders"
    ) {
      //Init showroom data
      this.$store.dispatch("initizalizeState", dealer_mode).then(() => {
        //init dealer login
        if (dealer_mode && passcode) {
          this.dealerLogin({
            passcode: passcode,
          });
        } else {
          this.checkIfSessionExists().then((doesSessionExists) => {
            //Check if the session exists in the Storage Session, and if it belongs to the showrooms's dealer
            if (doesSessionExists) {
              this.getSessionData().then((sessionData) => {
                this.setDealerMode(sessionData.api_token);
              });
            }
          });
        }
      });
    } else {
      this.$store.commit("updateState", {
        state: "Breadcrumbs",
        value: [],
      });
    }
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;

  .toast-message {
    font-family: "Ubuntu Bold", arial, sans-serif;
  }
}

.has-been-sent {
  text-align: center;
}
#nav {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
@import "./assets/scss/general-styles";
</style>
