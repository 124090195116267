<template>
  <div
    class="layout-box"
    v-bind:class="{ active: false || active_layout.id === layout.id }"
    @click="selectLayout(layout)"
  >
    <h3 class="text-uppercase text-center">{{ layout.name }}</h3>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LayoutBox",
  props: ["layout", "product_type"],
  computed: {
    ...mapGetters(["activeProduct"]),
    active_layout() {
      return this.activeProduct.reversibleActive
        ? this.activeProduct.reversible_layout
        : this.activeProduct.layout;
    },
  },
  components: {},
  methods: {
    ...mapActions(["updateArtworks"]),
    selectLayout(layout) {
      let fieldKey = this.activeProduct.reversibleActive ? "reversible_layout" : "layout";
      this.$store.commit("updateSubState", {
        state: this.product_type + "_product",
        substate: fieldKey,
        value: layout,
      });
      if (layout.artworks) {
        this.updateArtworks({
          productType: this.product_type + "_product",
          artworks: layout.artworks,
        });
      }
      this.scrollToSlider()
    },
    scrollToSlider() {
      const productSlider = document.getElementById("slider-container");
      productSlider.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .layout-box {
    cursor: pointer;
    &.active {
      position: relative;
      h3 {
        background-color: $black;
        color: $white;
        &:after {
          content: "\2713";
          position: absolute;
          right: 10.5px;
        }
      }
    }

    h3 {
      background-color: #f6f6f6;
      color: $darkerGray;
      font-family: $ubuntuRegular;
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      letter-spacing: 0;

      padding: 4px;
      border-radius: 12px;
    }
    .design-box-items {
      .product-image {
        display: flex;
        margin: 0 13px;
        ::v-deep svg {
          width: 100%;
        }
      }
    }
  }
}
</style>