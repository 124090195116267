<template>
  <div class="layout-row-container" v-if="rowExists && field">
    <p v-if="description" class="row-description">{{ description }}</p>
    <div class="layout-heading flex justify-space-between align-center">
      <h3>{{ rowTitle }}</h3>
      <div class="layout-row-switch">
        <small>{{ enabled ? "On" : "Off" }}</small>
        <v-switch
          v-model="enabled"
          inset
          :ripple="false"
          color="black"
        ></v-switch>
      </div>
    </div>
    <div
      class="layout-row flex align-center justify-space-between"
      :class="{ 'row-disabled': !enabled }"
    >
      <!-- Read only inputs -->
      <div
        class="layout-input-container"
        :class="{ 'number-input-container': inputType === 'number' }"
        v-if="!editing"
      >
        <!-- Name Input -->
        <input
          v-if="inputType === 'text'"
          readonly
          type="text"
          minlength="1"
          maxlength="15"
          v-model="fieldValue"
        />

        <!-- Number input -->
        <input
          v-else-if="inputType === 'number'"
          readonly
          type="number"
          min="0"
          max="99"
          v-model="fieldValue"
        />
        <div class="layout-input-actions" v-if="!isLockerLocked">
          <span @click="toggleEdit()">Edit</span>
        </div>
      </div>

      <!-- Edit Inputs -->
      <div
        v-else
        class="layout-input-container"
        :class="{ 'number-input-container': inputType === 'number' }"
      >
        <input
          v-if="inputType === 'text'"
          type="text"
          minlength="1"
          maxlength="15"
          v-model="tempFieldValue"
        />
        <input
          v-else-if="inputType === 'number'"
          type="number"
          min="0"
          max="99"
          v-model="tempFieldValue"
        />
        <div class="layout-input-actions">
          <span @click="toggleEdit()">Cancel</span>
          <span v-if="tempFieldValue.trim().length" @click="saveValue()"
            >Save</span
          >
        </div>
      </div>

      <div class="vertical-line"></div>

      <!-- Color Picker -->
      <div class="colors-container flex justify-space-evenly">
        <ColorPicker
          :field="field"
          :product_type="product_type"
          :side="side"
          :subfield="'fill'"
        />
        <ColorPicker
          :field="field"
          :product_type="product_type"
          :side="side"
          :subfield="'outline'"
        />
      </div>

      <div class="vertical-line"></div>
      <!-- Size Font Dropdown -->
      <v-select
        v-if="!productIsFlash && !hideLayoutSize && !isLockerLocked"
        v-model="roster_selected"
        :options="roster_options"
        :clearable="false"
        :searchable="false"
        class="box-shadow size-input"
      ></v-select>

      <div
        v-if="!hideLayoutSize && !productIsFlash && !isLockerLocked"
        class="vertical-line"
      ></div>

      <!-- Fonts dropdown -->
      <v-select
        v-model="font"
        :options="fonts"
        :clearable="false"
        :searchable="false"
        class="box-shadow font-input"
        :class="[font_class, isLocked ? 'locked' : '']"
        :disabled="isLocked"
      ></v-select>
    </div>
  </div>
</template>

<script>
import ColorPicker from "../ColorPicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LayoutRow",
  props: [
    "active_layout",
    "field",
    "product_type",
    "side",
    "label",
    "product_tier",
    "isArtwork",
  ],
  components: {
    ColorPicker,
    "v-select": vSelect,
  },
  data() {
    return {
      productIsFlash: this.product_tier === 1,
      roster_option: '3"',
      reversible_roster_option: '3"',
      roster_options: ['1"', '2"', '3"'],
      /*tempFieldValue: this.activeProduct[_sideKey][this.field].value
        ? this.activeProduct[_sideKey][this.field].value
        : this.field === "name"
          ? "Name"
          : "00",*/
      tempFieldValue: "",
      editing: false,
      opened_pickers: {
        front: {
          name: {
            fill: false,
            outline: false,
          },
          number: {
            fill: false,
            outline: false,
          },
        },
        side: {
          name: {
            fill: false,
            outline: false,
          },
          number: {
            fill: false,
            outline: false,
          },
        },
        back: {
          name: {
            fill: false,
            outline: false,
          },
          number: {
            fill: false,
            outline: false,
          },
        },
      },
    };
  },
  mounted() {
    this.calcTempFieldValue();
  },

  methods: {
    ...mapActions(["requestTextStamp", "updateProductSubFieldKey"]),
    calcTempFieldValue() {
      this.tempFieldValue = this.activeProduct[this.sideKey][this.field].value
        ? this.activeProduct[this.sideKey][this.field].value
        : this.field === "name"
        ? "Name"
        : "00";
    },
    toggleEdit() {
      this.tempFieldValue = this.activeProduct[this.sideKey][this.field].value;
      this.editing = !this.editing;
    },
    saveValue() {
      if (this.tempFieldValue.trim().length) {
        this.updateProductSubFieldKey({
          field: this.sideKey,
          subfield: this.field,
          key: "value",
          value: this.tempFieldValue,
        });
        this.requestTextStamp({
          value: this.tempFieldValue,
          field: this.field,
          side: this.sideKey,
          fill: this.fill_color.hex,
          outline: this.outline_color.hex,
          product_type: this.product_type,
          font: this.font.id,
        });
        this.editing = false;
      }
    },
  },
  computed: {
    ...mapGetters(["activeProduct"]),
    ...mapGetters("lockerModule", ["isLockerLocked"]),

    reversibleActive() {
      return this.activeProduct.reversibleActive;
    },
    sideKey() {
      return this.reversibleActive ? "reversible_" + this.side : this.side;
    },

    isLocked() {
      return (
        this.product_tier === 1 &&
        this.side.toLowerCase() === "back" &&
        this.field.toLowerCase() === "name"
      );
    },

    rowTitle() {
      if (this.label) {
        return this.label;
      }
      return this.field.replace("_", " ");
    },
    description() {
      //generate labels depending on layout row and side, surely will be used more in the future
      if (this.side === "back") {
        if (this.field === "name") {
          return "To allow for individual player names, select the preferred colors or simply turn them off ussing the switch on the right.";
        }
      }
      return false;
    },
    inputType() {
      if (this.field === "name") {
        return "text";
      }
      return "number";
    },
    hideLayoutSize() {
      if (this.$store.state.dealer && this.$store.state.dealer.settings) {
        return this.$store.state.dealer.settings.hide_layout_size;
      }
      return false;
    },
    roster_selected: {
      get() {
        let activeOpt = !this.reversibleActive
          ? this.roster_option
          : this.reversible_roster_option;
        return activeOpt
          ? activeOpt
          : this.roster_options[this.roster_options.length - 1];
      },
      set(value) {
        if (!this.reversibleActive) {
          this.roster_option = value;
        } else {
          this.reversible_roster_option = value;
        }
        this.$store.commit("updateSubStateSubField", {
          state: this.product_type + "_product",
          substate: this.sideKey,
          field: this.field,
          subfield: "size",
          value: value.replace('"', ""),
        });
      },
    },
    roster_default() {
      /*return this.active_layout.layout_attributes[this.side][this.field]
        ? this.active_layout.layout_attributes[this.side][this.field].roster_default
            .toString()
            .concat('"')
        : "";*/
      return "";
    },
    fonts() {
      if (!this.isLocked) {
        //return all the fonts or just the first one for locked layouts
        return this.$store.state.fonts;
      }
      return [this.$store.state.fonts[0]];
    },
    font: {
      get() {
        return this.fonts[
          this.activeProduct[this.sideKey][this.field].font - 1
        ];
      },
      set(font) {
        this.$store.commit("updateSubStateSubField", {
          state: this.product_type + "_product",
          substate: this.sideKey,
          field: this.field,
          subfield: "font",
          value: font.id,
        });

        this.requestTextStamp({
          value: this.fieldValue,
          field: this.field,
          side: this.sideKey,
          fill: this.fill_color.hex,
          outline: this.outline_color.hex,
          product_type: this.product_type,
          font: font.id,
        });
      },
    },
    fill() {
      return this.activeProduct[this.sideKey][this.field].fill;
    },
    fill_color() {
      let colors = this.$store.state.colors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.fill) {
          return element;
        }
      }
      return false;
    },
    outline() {
      return this.activeProduct[this.sideKey][this.field].outline;
    },
    outline_color() {
      let colors = this.$store.state.colors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.outline) {
          return element;
        }
      }
      return false;
    },
    font_class() {
      return this.font.label.toLowerCase().replace(" ", "");
    },
    fieldValue() {
      return this.activeProduct[this.sideKey][this.field].value;
    },

    enabled: {
      get() {
        if (!this.field) return false;
        return this.activeProduct[this.sideKey][this.field].visible;
      },
      set(value) {
        this.$store.commit("updateSubStateSubField", {
          state: this.product_type + "_product",
          substate: this.sideKey,
          field: this.field,
          subfield: "visible",
          value,
        });
      },
    },
    rowExists() {
      return (
        this.isArtwork ||
        (this.active_layout.layout_attributes[this.side] &&
          this.active_layout.layout_attributes[this.side][this.field])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .layout-row-container {
    margin-bottom: 16px;
    //The 4 containers width of each input will be the same
    .layout-input-container,
    .colors-container,
    .size-input,
    .font-input {
      width: 25%;
    }
    .layout-input-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .layout-input-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
        span {
          font-family: $ubuntuLight;
          color: $black;
          cursor: pointer;
          font-size: 12px;
          text-decoration: underline;
        }
      }

      @media screen and (max-width: 1024px) {
        flex-flow: row;
        width: 100%;
        margin-bottom: 1rem;

        input {
          margin-right: 1rem;
        }
      }
    }
    .row-description {
      font-family: "Ubuntu Light";
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 15px;
    }
    .layout-heading {
      padding-bottom: 5.5px;
      border-bottom: 1px solid $lighterGray;
      margin-bottom: 7.5px;
      h3 {
        text-transform: uppercase;
        color: $darkGray;
      }
      .layout-row-switch {
        display: flex;
        align-items: center;
        small {
          margin-right: 10px;
          font-family: "Ubuntu Light";
        }
      }
      ::v-deep .v-input--switch {
        .v-input__control {
          .v-input__slot {
            .v-input--selection-controls__input {
              width: 26px;
              height: 12px;
              input {
                max-height: 12px;
              }
              .v-input--switch__track {
                height: 12px;
                width: 26px;
                top: 0;
                left: 0;
                &.black--text {
                  color: $black;
                  //caret-color: $black;
                  opacity: 1;
                }
              }
              .v-input--switch__thumb {
                width: 8px;
                height: 8px;
                top: 2px;
                left: 2px;
                color: $black;
                &.black--text {
                  color: $white;
                }
              }
            }
          }
          .v-messages {
            display: none;
          }
        }
        &.v-input--is-dirty {
          .v-input__control {
            .v-input__slot {
              .v-input--selection-controls__input {
                .v-input--switch__thumb {
                  transform: translate(14px, 0) !important;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 1024px) {
        margin-bottom: 1rem;
      }
    }
    .layout-row {
      flex-wrap: wrap;
      &.row-disabled {
        opacity: 0.26;
        pointer-events: none;
      }

      input[type="text"],
      input[type="number"] {
        max-width: 152px;
      }

      .vertical-line {
        margin-bottom: 10px;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      .colors-container {
        min-width: 148px;
        /* margin-bottom: 10px; */
        .color-picker {
          &:first-child {
            margin-right: 18px;
          }
        }

        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 1rem;
          align-items: center;
          justify-content: flex-start;
        }
      }

      .v-select {
        border-radius: 15px;
        ::v-deep .vs__dropdown-toggle {
          border-radius: 15px;
          border: none;

          .vs__selected-options {
            padding: 3px 0;
            .vs__selected {
              width: 100%;
              justify-content: center;
              color: $black;
              line-height: 22px;
              text-transform: uppercase;
              &:after {
                content: "";
                width: 11px;
                height: 19px;

                background-image: url("~@/assets/icons/arrow_down.svg");
                background-position: center right;
                background-repeat: no-repeat;
                background-size: 11px 6.5px;
                padding-left: 5.5px;
                border-left: 1px solid $lighterGray;

                position: absolute;
                right: 7px;
              }
            }
          }

          .vs__actions {
            display: none;
          }
        }
        ::v-deep ul {
          text-align: center;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          border: none;
          z-index: 10000;
          li {
            padding: 2px 0;
            &.vs__dropdown-option--highlight {
              background: $black;
            }
          }
        }
        &:not(.vs--open) {
          ::v-deep .vs__search {
            height: 0;
            border: none;
          }
        }
        &.vs--open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          ::v-deep .vs__dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            .vs__selected-options {
              .vs__selected {
                &:after {
                  right: 6px;
                }
              }
            }
          }
        }

        &.size-input {
          width: 70px;
          ::v-deep .vs__selected {
            font-family: $ubuntuMedium;
            font-size: 15px;
            padding-right: 12px;
          }
          ::v-deep ul {
            min-width: 70px;
            li {
              font-family: $ubuntuMedium;
              font-size: 15px;
              padding-right: 12px;
            }
          }
        }

        &.font-input {
          width: 100px;
          font-size: 0px;
          /* margin-bottom: 10px; */
          ::v-deep .vs__selected {
            padding-right: 22px;
            width: 100px !important;
            text-indent: 200%;
            overflow: hidden;
            white-space: nowrap;

            background-size: 80% auto;
            background-position: left center;
            background-repeat: no-repeat;
          }
          ::v-deep ul {
            min-width: 100px;
            li {
              background-size: 80% auto;
              background-position: center;
              background-repeat: no-repeat;

              text-indent: 200%;
              white-space: nowrap;
              overflow: hidden;

              &:hover {
                background-color: rgba(0, 0, 0, 0.4) !important;
              }

              padding-right: 22px;
              &:nth-of-type(1) {
                background-image: url("~@/assets/fonts/full_block.png");
              }
              &:nth-of-type(2) {
                background-image: url("~@/assets/fonts/apache.png");
              }
              &:nth-of-type(3) {
                background-image: url("~@/assets/fonts/collegiate.png");
              }
              &:nth-of-type(4) {
                background-image: url("~@/assets/fonts/falcon.png");
              }
              /* &:nth-of-type(5) {
                background-image: url("~@/assets/fonts/racer.png");
              } */
              &:nth-of-type(5) {
                background-image: url("~@/assets/fonts/warrior_1.png");
              }
              &:nth-of-type(6) {
                background-image: url("~@/assets/fonts/university.png");
              }
            }
          }
        }

        &.fullblock {
          ::v-deep .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                background-image: url("~@/assets/fonts/full_block.png");
              }
            }
          }
        }
        &.apache {
          ::v-deep .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                background-image: url("~@/assets/fonts/apache.png");
              }
            }
          }
        }
        &.collegiate {
          ::v-deep .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                background-image: url("~@/assets/fonts/collegiate.png");
                background-position: 5px center;
                width: 85px !important;
              }
            }
          }
        }
        &.falcon {
          ::v-deep .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                background-image: url("~@/assets/fonts/falcon.png");
              }
            }
          }
        }
        &.racer {
          ::v-deep .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                background-image: url("~@/assets/fonts/racer.png");
              }
            }
          }
        }
        &.warrior1 {
          ::v-deep .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                background-image: url("~@/assets/fonts/warrior_1.png");
                background-position: 5px center;
                width: 85px !important;
              }
            }
          }
        }
        &.university {
          ::v-deep .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                background-image: url("~@/assets/fonts/university.png");
              }
            }
          }
        }
      }
    }
    .locked {
      ::v-deep .vs__dropdown-toggle {
        .vs__selected-options {
          .vs__selected {
            &:after {
              background-image: url("~@/assets/icons/lock.svg") !important;
              background-position: center right;
              background-repeat: no-repeat;
              background-size: 16px 16px !important;
              border-left: 1px solid $lighterGray;
              position: absolute;
              right: 5px !important;
              padding-left: 7px !important;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
</style>