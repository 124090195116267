<template>
  <div
    class="design-box"
    v-bind:class="{ active: is_active }"
    @click="selectDesign(design)"
  >
    <h3 class="text-uppercase text-center">
      {{ box_name.replace(/_/g, " ") }}
    </h3>
    <div class="design-box-items flex">
      <Vector
        v-for="(side, index) in sides"
        :key="index"
        :side="side"
        :svgs="svgs(side)"
        :backgrounds="background_data"
        :layout="layout"
        :product_type="product_type"
        :is_stock="product_is_stock"
        :stock_color="stockColor"
        :isVariantStock="isVariantStock"
        :styleId="styleId"
        :designName="designName"
        :isHybridSVG="isHybridSVG"
      />
    </div>
  </div>
</template>

<script>
import Vector from "../../components/Vector.vue";

export default {
  name: "DesignBox",
  props: [
    "design",
    "backgrounds",
    "tier",
    "layout",
    "product_type",
    "product_is_stock",
    "isVariantStock",
  ],
  data() {
    return {
      sides: this.tier !== 1 ? ["front", "side", "back"] : ["front", "back"],
    };
  },
  computed: {
    activeProduct() {
      return this.$store.state[this.product_type + "_product"]
    },

    isHybridSVG() {
      return this.design.color === "precolored";
    },

    designName() {
      return this.design.name
    },

    styleId() {
      return this.activeProduct.product.style_id
    },   

    stockColor() {
      return this.product_is_stock ? this.design.color : false;
    },
    current_design() {
      return this.$store.state[this.product_type + "_product"].design;
    },
    background_data() {
      if (this.backgrounds) {
        return this.backgrounds;
      }
      return this.design.backgrounds;
    },
    box_name() {
      return !this.isVariantStock ? this.design.name : this.design.color_name;
    },
    is_active() {
      return !this.isVariantStock
        ? this.current_design.id === this.design.id
        : this.current_design.color_id === this.design.color_id;
    },
  },
  components: {
    Vector,
  },
  methods: {
    selectDesign(design) {
      this.$store.commit("updateSubState", {
        state: this.product_type + "_product",
        substate: "design",
        value: design,
      });

      if (this.product_is_stock) {
        this.$store.commit("updateState", {
          state: "stockColor",
          value: design.color,
        });
      }

      this.$emit('designUpdated')
    },
    svgs(side) {
      return this.design.svg_data
        ? this.design.svg_data[side]
        : { group1: [], group2: [], group3: [] };
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .design-box {
    cursor: pointer;
    &.active {
      position: relative;
      h3 {
        background-color: $black;
        color: $white;
        &:after {
          content: "\2713";
          position: absolute;
          right: 10.5px;
        }
      }
    }

    h3 {
      background-color: #f6f6f6;
      color: $darkerGray;
      font-family: $ubuntuRegular;
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      letter-spacing: 0;

      padding: 4px;
      border-radius: 12px;
    }
    .design-box-items {
      min-height: 75px;
      display: flex;
      justify-content: space-around;
      .product-image {
        display: flex;
        margin: 0 13px;
        ::v-deep svg {
          width: 100%;
        }
      }
    }
  }
}
</style>