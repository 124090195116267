import CONSTANTS from "@/constants.js";
const { PROJECT_STATUS } = CONSTANTS
const { QUOTE_STATUS, OMG_STATUS, CLOSED_STATUS } = PROJECT_STATUS

const actionsPermissions = {

  namespaced: true,

  getters: {

    isAllowedDealerOnlyAction: (_state, _getters, rootState) => {
      return rootState.dealerMode ? true : false
    },

    isProjectLocked: (_state, _getters, _rootState, rootGetters) => {
      const projectStatus = rootGetters['showroomStatus'] || ''

      //Locked if the dealer mode is off and the project is on quote, omg or closed status
      return projectStatus?.toLowerCase() === QUOTE_STATUS?.toLowerCase()
        || projectStatus?.toLowerCase() === OMG_STATUS?.toLowerCase()
        || projectStatus?.toLowerCase() === CLOSED_STATUS?.toLowerCase()
    },

    isProjectUnlockeable(_state, _getters, rootState, rootGetters) {
      const projectStatus = rootGetters['showroomStatus'] || ''
      return rootState.dealerMode && projectStatus?.toLowerCase() === QUOTE_STATUS?.toLowerCase()
    }
  }
}
export default actionsPermissions