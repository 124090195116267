<template>
  <div
    class="artwork-option"
    @click="select()"
    :class="{ active: artwork.id === activeId }"
  >
    <LayoutArtworkOptionIcon :artwork="artwork" />
  </div>
</template>

<script>
import LayoutArtworkOptionIcon from "../../components/single-product/LayoutArtworkOptionIcon";

export default {
  name: "LayoutArtworkOption",
  props: ["productType", "side", "artwork", "activeId"],
  methods: {
    select() {
      this.$store.commit("updateSubStateField", {
        state: this.productType + "_product",
        substate: this.side,
        field: "artwork",
        value: this.artwork.id,
      });
      this.scrollToSlider()
    },

    scrollToSlider() {
      const productSlider = document.getElementById("slider-container");
      productSlider.scrollIntoView({ behavior: "smooth" });
    },
  },
  components: {
    LayoutArtworkOptionIcon,
  },
};
</script>