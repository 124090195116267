<template>
  <div class="reversible-preview" @click="toggleReversible()">
    <div class="reversible-content">
      <Vector 
        :side="side"
        :svgs="svgs"
        :backgrounds="backgrounds"
        :layout="layout"
        :product_type="product_type"
        :is_stock="is_stock"
        :stock_color="stock_color"
        :isVariantStock="isVariantStock"
        :zoomable="false"
        :alternative="true"
    />
    </div>
    <div class="reversible-state">
      {{ !activeProductReverseActive ? 'inside' : 'outside'}}
    </div>
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";

import { mapActions, mapGetters } from 'vuex'
import Vector from "@/components/Vector.vue";

export default {
  name: "ReversiblePreview",
  props: ["backgrounds", "is_stock", "stock_color", "isVariantStock", "product_type", "design", "activeSide", "sides"],
  components: {
    Vector,
  },
  computed: {
    ...mapGetters(["activeProductReverseActive", "activeProduct"]),
    layout() {
      return this.activeProductReverseActive ? this.activeProduct.layout : this.activeProduct.reversible_layout;
    },
    side() {
      let sideNames = Object.keys(this.sides);
      return sideNames[this.activeSide];
    },
    svgs() {
      if (this.isVariantStock) {
        return CONSTANTS.EMPTY_SVG_GROUPS;
      }
      
      return this.design.svg_data
        ? this.design.svg_data[this.side]
        : CONSTANTS.EMPTY_SVG_GROUPS;
    }
  },
  methods: {
    ...mapActions(["toggleReversible"]),
  }
}
</script>

<style lang="scss">
  .reversible-preview {
    position: absolute;
    right: 10px;
    width: 120px;
    height: 130px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    .reversible-content {
      width: 100%;
      border: 2px solid;
      .product-image {
        width: 100%;
      }
    }
  }
</style>