<template>
  <div class="modal-container d-flex justify-center align-center">
    <template v-if="loading">
      <Loader />
    </template>

    <template v-else>
      <div
        :class="{ 'modal-iframe-fix': isAnEmbeddedShowroom }"
        class="modal-interior-container box-shadow m-auto"
        v-if="step === 1"
      >
        <h2>Upload Team Graphic</h2>
        <div class="file-container flex align-center justify-start">
          <div class="file-extension flex align-center justify-center">
            <h5>{{ fileExtension }}</h5>
          </div>
          <div class="file-name">
            <p>{{ fileName }}</p>
            <p>{{ fileSize }}</p>
          </div>
        </div>

        <div
          v-if="!showCropper"
          class="image-container flex justify-center align-center"
        >
          <img
            v-if="has_image"
            style="max-height: 200px"
            :src="fileData ? fileData : fileUploaded"
          />
          <div v-else class="loader-container">
            <span class="mr-3">Loading</span>
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <div class="cropper-container">
          <vue-cropper
            v-if="showCropper"
            ref="cropper"
            :src="fileData"
            alt="Source Image"
          ></vue-cropper>
          <div class="buttons-container" v-if="showCropper">
            <button
              class="btn white-btn mb-3 mb-xl-0"
              type="button"
              @click="toggleCropper()"
            >
              Cancel
            </button>
            <button
              class="btn black-btn mb-3 mb-xl-0"
              type="button"
              @click="cropImage()"
            >
              Save Changes
            </button>
          </div>
        </div>

        <div class="buttons-container" v-if="!showCropper">
          <button
            class="btn white-btn mb-3 mb-xl-0"
            type="button"
            @click="emitCloseModal"
          >
            Cancel
          </button>
          <button
            v-if="has_image"
            class="btn white-btn mb-3 mb-xl-0"
            type="button"
            @click="toggleCropper()"
          >
            Crop
          </button>
          <button
            v-if="has_image"
            class="btn black-btn mb-3 mb-xl-0"
            type="button"
            @click="setStep(2)"
          >
            Continue
          </button>
        </div>
      </div>

      <div
        :class="{ 'modal-iframe-fix': isAnEmbeddedShowroom }"
        class="modal-interior-container box-shadow"
        v-if="step === 2"
      >
        <h2>Remove Background</h2>
        <div class="file-container flex align-center justify-start">
          <div class="file-extension flex align-center justify-center">
            <h5>{{ fileExtension }}</h5>
          </div>
          <div class="file-name">
            <p>{{ fileName }}</p>
            <p>{{ fileSize }}</p>
          </div>
        </div>
        <div class="row">
          <div class="cropped-image-container col-12 col-md-6 mb-4">
            <img
              id="remove-background-img"
              :src="fileData"
              style="max-width: 100%"
              @click="setBackgroundColor($event)"
              :class="{ editing: backgroundMode }"
            />
          </div>
          <div
            class="
              remove-background-button-container
              text-container
              col-12 col-md-6
              flex flex-column
              justify-content-around
              align-center
              mb-5 mb-md-0
            "
          >
            <button
              class="btn white-btn mb-3"
              type="button"
              @click="startRemoveBackground()"
            >
              {{ backgroundMode === 0 ? "Remove background" : "Cancel" }}
            </button>
            <p v-if="backgroundMode === 1">
              Click the image and select the color you want to remove.
            </p>
          </div>
        </div>
        <div class="buttons-container" v-if="!showCropper">
          <button
            class="btn white-btn mb-3 mb-xl-0"
            type="button"
            @click="emitCloseModal()"
          >
            Cancel
          </button>
          <button
            class="btn white-btn mb-3 mb-xl-0"
            type="button"
            @click="setStep(step - 1)"
          >
            Back
          </button>
          <button
            class="btn black-btn mb-3 mb-xl-0"
            type="button"
            @click="setStep(step + 1)"
          >
            {{ logoHasBeenEdited ? "Save Changes" : "Continue" }}
          </button>
        </div>
      </div>

      <div
        :class="{ 'modal-iframe-fix': isAnEmbeddedShowroom }"
        class="modal-interior-container box-shadow"
        v-if="step === 3"
      >
        <h2>Select default type</h2>
        <div class="file-container flex align-center justify-start">
          <div class="file-extension flex align-center justify-center">
            <h5>{{ fileExtension }}</h5>
          </div>
          <div class="file-name">
            <p>{{ fileName }}</p>
            <p>{{ fileSize }}</p>
          </div>
        </div>

        <section class="row step-3-container">
          <!-- Logo Image -->
          <article class="cropped-image-container col-12 col-md-6 mb-4">
            <img
              id="remove-background-img"
              :src="fileData"
              style="max-width: 100%"
              @click="setBackgroundColor($event)"
              :class="{ editing: backgroundMode }"
            />
          </article>

          <!-- Options to edit -->
          <article
            class="
              type-of-logo-selection-container
              text-container
              col-12 col-md-6
              flex flex-column
              mb-5 mb-md-0
            "
          >
            <!-- Type of logo -->
            <div class="type-of-logo-container">
              <fieldset>
                <legend>Default for:</legend>

                <label
                  :for="typeOfLogo"
                  v-for="typeOfLogo in typesOfLogos"
                  :key="typeOfLogo + '-ty'"
                >
                  <input
                    v-model="typeOfLogoSelected"
                    type="checkbox"
                    multiple
                    :name="typeOfLogo"
                    :value="typeOfLogo"
                    :id="typeOfLogo"
                  />
                  <span> {{ typeOfLogo }}</span>
                </label>
              </fieldset>
            </div>
          </article>
        </section>

        <div class="buttons-container" v-if="!showCropper">
          <button
            class="btn white-btn mb-3 mb-xl-0"
            type="button"
            @click="emitCloseModal()"
          >
            Cancel
          </button>
          <button
            class="btn white-btn mb-3 mb-xl-0"
            type="button"
            @click="setStep(2)"
          >
            Back
          </button>
          <button
            class="btn black-btn mb-3 mb-xl-0"
            type="button"
            @click="saveLogo"
          >
            Save Graphic
          </button>
        </div>
      </div></template
    >
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import axios from "axios";
import "cropperjs/dist/cropper.css";
import { mapState } from "vuex";

import Loader from "@/components/Loader.vue";

export default {
  name: "UploadLogoModal",
  props: [
    "fileUploaded",
    "fileName",
    "fileExtension",
    "fileSize",
    "fileOriginal",
    "isVector",
    "typesOfLogoToBeEdited",
    "logoIdToBeEdited",
    "editableLogo",
    "customerId",
    "isManageModalOpen", // => This will be the indicator that the user is only editing the logo from the ManageLogosModal
  ],
  components: {
    VueCropper,
    Loader,
  },
  data() {
    return {
      fileData: "",
      showCropper: false,
      backgroundMode: 0,
      step: 1,
      typesOfLogos: ["spiritwear", "uniforms"],
      typeOfLogoSelected: [],
      loading: false,
      logoColors: [],
      logoHasBeenEdited: false,
    };
  },
  computed: {
    ...mapState([
      "dealer",
      "endpoint",
      "settings",
      "dealerMode",
      "isAnEmbeddedShowroom",
      "isShowroomLockedByDealer",
    ]),

    has_image() {
      return this.fileOriginal.length > 0;
    },
  },
  methods: {
    emitCloseModal() {
      this.$emit("close");
      {
        !this.isManageModalOpen &&
          this.$emit("removeLogo", this.logoIdToBeEdited);
      } //ACA ///

      this.fileData = "";
      this.step = 1;
    },
    toggleCropper() {
      this.updateFileData();
      this.showCropper = !this.showCropper;
    },
    cropImage() {
      //mutate and save data
      this.logoHasBeenEdited = true;
      this.fileData = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showCropper = false;
    },
    setStep(number) {
      this.updateFileData();
      this.step = number;
    },
    updateFileData() {
      if (!this.fileData) this.fileData = this.fileUploaded;
    },

    startRemoveBackground() {
      if (this.backgroundMode === 0) {
        this.logoHasBeenEdited = true;
      }
      this.backgroundMode = this.backgroundMode ? 0 : 1;
    },
    setBackgroundColor(event) {
      let image = document.getElementById("remove-background-img");
      let widthRate = image.naturalWidth / image.width,
        heightRate = image.naturalHeight / image.height;

      if (this.backgroundMode === 1) {
        //Calculate the clicked coordinate
        let x = event.offsetX * widthRate,
          y = event.offsetY * heightRate;
        this.removeBackground(x, y);
      }
    },
    removeBackground(x, y) {
      if (this.fileData) {
        let image = document.getElementById("remove-background-img"),
          canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d"),
          r,
          g,
          b;

        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        let pixelData = canvas.getContext("2d").getImageData(x, y, 1, 1).data;
        let imgd = ctx.getImageData(
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
          ),
          pix = imgd.data,
          newColor = {
            //set transparent color
            r: 0,
            g: 0,
            b: 0,
            a: 0,
          };
        for (let i = 0, n = pix.length; i < n; i += 4) {
          r = pix[i];
          g = pix[i + 1];
          b = pix[i + 2];
          if (r == pixelData[0] && g == pixelData[1] && b == pixelData[2]) {
            //color matches the clicked one
            // Change the white to the new color.
            pix[i] = newColor.r;
            pix[i + 1] = newColor.g;
            pix[i + 2] = newColor.b;
            pix[i + 3] = newColor.a;
          }
        }
        ctx.putImageData(imgd, 0, 0);
        this.fileData = canvas.toDataURL("image/png");
        this.backgroundMode = 0;
      }
    },

    async updateLogo() {
      this.loading = true;
      try {
        const url =
          this.settings.endpoint + "api/logos/" + this.logoIdToBeEdited;

        const params = {
          type: this.typeOfLogoSelected,
          colors: this.logoColors,
        };

        if (this.logoHasBeenEdited) params["contents"] = this.fileData;

        if (this.customerId) params["customer_id"] = this.customerId;

        await axios.put(url, params, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.settings.token,

            // Authorization: "Bearer " + this.$store.state.settings.token,
          },
        });
      } catch (error) {
        this.$toastr.e("Error in updateLogo");
        
      }
    },

    async saveLogo() {
      //Only save the logo changes in the database (edit, background remove, type) if dealer mode is active
      if (
        !this.isAnEmbeddedShowroom &&
        (!this.isShowroomLockedByDealer || this.dealerMode)
      ) {
        await this.updateLogo();
      }

      this.emitSaveEditedLogo({
        editedLogoId: this.logoIdToBeEdited,
        editedLogo: this.fileData,
        typeOfLogo: this.typeOfLogoSelected,
        colors: this.logoColors,
      });

      this.emitCloseModal();
      this.loading = false;
    },

    emitSaveEditedLogo(data) {
      this.$emit("saveEditedLogo", data);
    },
  },

  watch: {
    editableLogo() {
      this.logoColors = this.editableLogo.colors;
    },
    fileData() {
      if (this.fileData === "") this.logoHasBeenEdited = false;
    },
  },

  mounted() {
    this.typeOfLogoSelected = this.typesOfLogoToBeEdited;
    this.logoColors = this.editableLogo.colors;
  },
};
</script>

<style lang="scss" scoped>
#app {
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999999;
    .modal-interior-container {
      width: 100%;
      padding: 20px;
      background-color: #ffffff;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: auto;

      &.modal-iframe-fix {
        margin-bottom: auto;
        margin-top: 8rem;
      }
      .btn {
        color: #000;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: 0.91px;
        padding: 8px 25px;
        border-radius: 109px;
        border: 1px solid #000;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        &:disabled {
          opacity: 0.25;
          cursor: initial;
        }
        &.white-btn {
          background: #fff;
          box-shadow: 0 0 10px -2px #0000004d;
          &:hover {
            color: #fff;
            background: #000;
          }
        }
        &.black-btn {
          color: #fff;
          background-color: #000;
          border-color: #fff;
          &:hover {
            color: #000;
            background-color: #fff;
            border-color: #000;
          }
        }
      }
      h2 {
        color: #000000;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        padding-bottom: 7.5px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 14.5px;
      }
      .file-container {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .file-extension {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          background: linear-gradient(to bottom, #666, #000);
          border-radius: 5px;
          margin-right: 22px;
          h5 {
            color: #ffffff;
            letter-spacing: 0;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            margin: 0;
          }
        }
        .file-name {
          flex: 1;
          p {
            font-size: 15px;
            line-height: 17px;
            margin-bottom: 1px;
          }
          .v-progress-linear {
            ::v-deep .v-progress-linear__background.black,
            ::v-deep .v-progress-linear__determinate.black {
              background-color: #000;
              color: #000;
            }
            ::v-deep .v-progress-linear__determinate.black {
              background-image: repeating-linear-gradient(
                45deg,
                transparent 0 5px,
                rgba(#ffffff, 0.5) 5px 10px
              );
              background-size: unset;
            }
          }
        }
      }
      .image-container {
        margin-bottom: 20px;
        margin-bottom: 20px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
        img {
          max-width: 100%;
        }

        .loader-container {
          display: flex;
          align-items: center;
          .lds-roller {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
          }
          .lds-roller div {
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 40px 40px;
          }
          .lds-roller div:after {
            content: " ";
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: rgb(17, 17, 17);
            margin: -4px 0 0 -4px;
          }
          .lds-roller div:nth-child(1) {
            animation-delay: -0.036s;
          }
          .lds-roller div:nth-child(1):after {
            top: 63px;
            left: 63px;
          }
          .lds-roller div:nth-child(2) {
            animation-delay: -0.072s;
          }
          .lds-roller div:nth-child(2):after {
            top: 68px;
            left: 56px;
          }
          .lds-roller div:nth-child(3) {
            animation-delay: -0.108s;
          }
          .lds-roller div:nth-child(3):after {
            top: 71px;
            left: 48px;
          }
          .lds-roller div:nth-child(4) {
            animation-delay: -0.144s;
          }
          .lds-roller div:nth-child(4):after {
            top: 72px;
            left: 40px;
          }
          .lds-roller div:nth-child(5) {
            animation-delay: -0.18s;
          }
          .lds-roller div:nth-child(5):after {
            top: 71px;
            left: 32px;
          }
          .lds-roller div:nth-child(6) {
            animation-delay: -0.216s;
          }
          .lds-roller div:nth-child(6):after {
            top: 68px;
            left: 24px;
          }
          .lds-roller div:nth-child(7) {
            animation-delay: -0.252s;
          }
          .lds-roller div:nth-child(7):after {
            top: 63px;
            left: 17px;
          }
          .lds-roller div:nth-child(8) {
            animation-delay: -0.288s;
          }
          .lds-roller div:nth-child(8):after {
            top: 56px;
            left: 12px;
          }
          @keyframes lds-roller {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
      .cropper-container {
        ::v-deep .cropper-bg {
          margin-bottom: 20px;
        }
      }

      .cropped-image-container {
        margin-block: 1rem;
        img {
          display: block;
          margin: auto;
          background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
          border: 1px solid #000000;
          /* max-width: 190px !important; */
          max-height: 250px;
          &.editing {
            cursor: crosshair;
          }
        }

        .text-container {
          .btn {
            margin-bottom: 16px;
          }
          h5 {
            color: red;
            margin-bottom: 9px;
          }
          p {
            color: #666;
          }
        }
      }

      .remove-background-button-container {
        margin-bottom: 2rem;

        .btn {
          width: auto !important;
        }
      }

      .step-3-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        .cropped-image-container {
          width: 50%;
          margin: 0;
        }

        .type-of-logo-selection-container {
          width: 45%;

          fieldset {
            border: none;
            legend {
              margin-bottom: 1rem;
            }
          }

          label {
            cursor: pointer;
          }
        }
      }

      .buttons-container {
        /* text-align: right; */
        width: 100%;
        text-align: right;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        button {
          width: auto !important;
        }

        .white-btn {
          border-color: #ffffff;
          margin-right: 4px;
          &:hover {
            border-color: #000000;
          }
        }
      }
    }
  }

  .type-of-logo-container {
    width: 100%;

    fieldset {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    label {
      display: flex;
      align-items: center;
      width: 40%;
      input {
        margin-right: 0.5rem;
      }
      span {
        text-transform: capitalize;
      }
    }
  }
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>