<template>
  <div class="page-header flex flex-wrap justify-space-between">
    <div class="heading-group heading-info flex-wrap flex align-center">
      <div class="heading-item heading-with-list flex-wrap flex align-center">
        <div
          class="item-list box-shadow flex-column flex-wrap"
          v-bind:class="{ active: opened.sport && !eventLoading }"
        >
          <div
            class="sport-item flex justify-end flex-reverse"
            :class="{ selected: sport === sport_item.id }"
            v-for="(sport_item, index) in getAllSports()"
            :key="index"
          >
            <label :for="index" class="flex align-center">
              {{ sport_item.name }}
              <div class="icon flex" v-html="sport_item.svg_icon"></div>
            </label>
            <input
              :id="index"
              type="radio"
              name="sport-switch"
              v-model="sport"
              :value="sport_item.id"
              @click="triggerSport()"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="heading-group heading-customization flex-wrap flex align-center"
    >
      <div
        id="color-pickers-container"
        class="heading-item flex align-center"
        v-if="!getLockedStates().colors && !hideColors"
      >
        <h3>Colors:</h3>
        <ColorPicker field="color_1" :product_type="product_type" />
        <ColorPicker field="color_2" :product_type="product_type" />
        <ColorPicker field="color_3" :product_type="product_type" />
      </div>
      <span class="vertical-line" v-if="!getLockedStates().colors"></span>
      <div class="heading-item heading-with-list flex align-center">
        <h3>Team name:</h3>
        <p>{{ team_name }}</p>
        <span
          v-if="!isLockerLocked && !eventLoading"
          class="update-link"
          @click="triggerTeamName()"
          >{{ opened.teamname ? "Cancel" : "Change" }}</span
        >
        <div
          class="item-list item-list-long box-shadow flex-column flex-wrap"
          v-bind:class="{ active: opened.teamname && !eventLoading }"
        >
          <div class="flex align-center justify-space-between">
            <input
              type="text"
              v-model="newTeamName"
              minlength="1"
              maxlength="15"
            />
            <span class="update-link" @click="updateTeamName()">Save</span>
          </div>
        </div>
      </div>
      <span class="vertical-line"></span>
      <div
        class="heading-item heading-with-list flex align-center"
        v-if="!hideGender"
      >
        <h3>
          Gender:
          <span>{{ gender }}</span>
        </h3>
        <span
          v-if="!isGenderLocked && !isLockerLocked && !eventLoading"
          class="update-link"
          @click="triggerGender()"
          >Change Gender</span
        >
        <div
          class="item-list gender-list box-shadow flex-column flex-wrap"
          v-bind:class="{ active: opened.gender && !eventLoading }"
        >
          <div
            v-for="(genderName, index) in genders"
            :key="index"
            class="gender-item flex flex-reverse justify-end"
          >
            <h3 @click="setGender(genderName)">
              <label :for="genderName">{{ genderName }}</label>
            </h3>
            <input
              :id="'selectGender' + genderName"
              type="radio"
              :value="genderName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "../components/ColorPicker.vue";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "PageHeading",
  props: ["product_type"],
  data: function () {
    return {
      opened: {
        sport: false,
        gender: false,
        teamname: false,
      },
      opened_pickers: {
        color_1: false,
        color_2: false,
        color_3: false,
      },
      newTeamName: this.team_name,
    };
  },
  components: {
    ColorPicker,
  },
  computed: {
    ...mapState(["genders", "activeProductCatalogId", "team_name"]),

    ...mapGetters([
      "activeProduct",
      "eventLoading",
      "projectLocked",
      "dealer",
      "getAllColors",
    ]),

    ...mapGetters("lockerModule", ["isLockerLocked"]),
    
    editorPage() {
      return (
        this.$router.currentRoute.name === "Product" ||
        this.$router.currentRoute.name === "ProductWithTeamLink" ||
        this.$router.currentRoute.name === "MainProductS2" ||
        this.$router.currentRoute.name === "MainProductS2WithTeamLink"
      );
    },
    isGenderLocked() {
      return this.getLockedStates().gender;
    },
    hideGender() {
      if (this.dealer && this.dealer.settings) {
        return this.dealer.settings.hide_genders;
      }
      return false;
    },
    hideColors() {
      if (!this.activeProduct.product.is_sublimable && this.editorPage) {
        return true;
      }
      if (this.dealer && this.dealer.settings) {
        return this.dealer.settings.hide_colors;
      }
      return false;
    },

    team_name: {
      get() {
        return this.activeProduct.front.name
          ? this.activeProduct.front.name.value
          : "";
      },
      set(value) {
        this.$store.commit("setTeamName", {
          product_type: this.product_type,
          value,
        });
        let layerSides = ["front", "side"];
        for (let index = 0; index < layerSides.length; index++) {
          const layerSide = layerSides[index];
          this.requestTextStamp({
            value: value,
            field: "name",
            side: layerSide,
            fill: this.$store.getters.getColorById(
              this.activeProduct[layerSide].name.fill
            ).hex,
            outline: this.$store.getters.getColorById(
              this.activeProduct[layerSide].name.outline
            ).hex,
            product_type: this.product_type,
            font: this.activeProduct[layerSide].name.font,
          });
        }
      },
    },
    fill() {
      return this.activeProduct.front.name.fill;
    },
    fill_color() {
      let colors = this.getAllColors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.fill) {
          return element;
        }
      }
      return false;
    },
    outline() {
      return this.activeProduct.front.name.outline;
    },
    outline_color() {
      let colors = this.getAllColors;
      for (let index = 0; index < colors.length; index++) {
        const element = colors[index];
        if (element.id === this.outline) {
          return element;
        }
      }
      return false;
    },
    gender: {
      get() {
        return this.$store.state.gender;
      },
      set(value) {
        this.$store.commit("setGender", value);
      },
    },
    sport: {
      get() {
        return this.activeProductCatalogId;
      },
      set(value) {
        this.$store.commit("setSport", value);
      },
    },
  },
  methods: {
    ...mapGetters([
      "getCurrentSport",
      "getAllSports",
      "getLockedStates",
      "getGenderName",
    ]),
    ...mapActions(["requestTextStamp", "saveUpdatedColorsAndTeamName"]),
    setGender(genderName) {
      this.$store.commit("setGender", genderName);
      this.triggerGender();
    },
    triggerSport() {
      this.opened.sport = !this.opened.sport;
      this.opened.gender = false;
      this.opened.teamname = false;
    },
    triggerGender() {
      this.opened.gender = !this.opened.gender;
      this.opened.sport = false;
      this.opened.teamname = false;
    },
    triggerTeamName() {
      this.opened.teamname = !this.opened.teamname;
      this.opened.sport = false;
      this.opened.gender = false;
      this.newTeamName = this.team_name;
    },
    async updateTeamName() {
      this.team_name = this.newTeamName;
      this.opened.teamname = false;
      await this.saveUpdatedColorsAndTeamName();
    },
  },
  created() {
    this.newTeamName = this.team_name ? this.team_name : "Team name";
  },
};
</script>

<style lang="scss" scoped >
#app {
  .page-header {
    border-bottom: 1px solid $darkGray;
    padding: 5px 0;
    margin-bottom: 10.5px;
    .heading-group {
      .heading-item {
        h1 {
          text-transform: uppercase;
          .icon {
            margin-left: 6px;
            ::v-deep svg {
              width: 16px;
              height: 16px;
            }
          }
        }
        > h3 {
          color: $darkerGray;
          text-transform: uppercase;
          margin-right: 7px;
          span {
            color: $blackGray;
          }
        }
        p {
          font-family: $ubuntuLight;
          font-size: 12px;
          line-height: 13px;
        }
        .update-link {
          margin-left: 10px;
        }
        .color-picker {
          margin-right: 19px;
          padding-left: 9px;
        }
        .item-list {
          display: none;
          position: absolute;
          top: 30px;
          left: 0;
          z-index: 1;
          background-color: #ffffff;
          min-width: 150px;
          &.active {
            display: flex;
          }
          &.item-list-long {
            width: 100%;
            padding: 10px 21px 10px 15px;

            input[type="text"] {
              width: 77%;
              max-width: 160px;
            }
          }
          &.gender-list {
            width: 100%;
            .gender-item {
              &:hover {
                background-color: $black;
                h3 {
                  label {
                    color: $white;
                  }
                }
              }
              h3 {
                width: 100%;
                label {
                  color: $black;
                  display: block;
                  width: 100%;
                  padding: 7px 21px;
                  cursor: pointer;
                  text-transform: uppercase;
                }
              }
              input {
                display: none;
              }
            }
          }
        }

        &.heading-with-list {
          position: relative;
          padding: 0 21px;
          height: 24px;
          @media screen and (max-width: 576px) {
            padding: 0;
          }
          &:first-child {
            padding-left: 0;
          }
        }

        .sport-item {
          &:hover,
          &.selected {
            background-color: $black;
            label {
              color: $white;
              .icon {
                ::v-deep svg {
                  fill: $white;
                  path,
                  rect,
                  line,
                  ellipse {
                    stroke: $white;
                  }
                }
              }
            }
          }
          label {
            cursor: pointer;
            color: $black;
            font-family: $ubuntuBold;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 1.52px;
            text-transform: uppercase;
            width: 100%;
            padding: 7px 8px;
            .icon {
              margin-left: 6px;
              ::v-deep svg {
                width: 16px;
                height: 16px;
              }
            }
          }
          input {
            display: none;
          }
        }
      }
      .vertical-line {
        color: $lightGray;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      @media screen and (max-width: 1024px) {
        flex-flow: column;
        width: 100%;
        margin: 1rem 0;

        .heading-item {
          margin-bottom: 1.5rem;
        }
      }

      @media screen and (max-width: 1024px) {
        &.heading-info {
          display: none;
        }
      }
    }
    #color-pickers-container {
      @media screen and (max-width: 576px) {
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start !important;
        display: flex;
        h3 {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        .color-picker {
          margin-bottom: 1.5rem;
          position: relative;
          margin-right: 0;
          padding: 0;

          ::v-deep .color-picker-list {
            left: -75px;
          }
        }
      }
    }
  }
}
</style>