<template>
  <div class="modal-container">
    <div class="graphics-modal">
      <button class="close-modal" @click="close">x</button>

      <Loader v-show="apiLoading" />

      <!-- LO QUE IMPORTA, ACA ESTA EL SCREEN LOGO EDITOR -->

      <div class="graphics-modal-edit-mode" v-show='!apiLoading'>
        <div class="graphic-modal-edit-mode-content">
          <div class="graphic-modal-edit-panel">
            <div class="graphic-panel-items">
              <div
                class="graphic-panel-item"
                :class="{ active: position === item }"
                v-for="item in Array(9)
                  .fill()
                  .map((_, i) => i + 1)"
                :key="item"
                @click="setPosition(item)"
              >
                <img
                  class="graphic-panel-item-image"
                  :src="
                    '/images/positions/' +
                    item +
                    (position === item ? '_active' : '') +
                    '.svg'
                  "
                />
              </div>
            </div>
            <div class="graphic-zoom-container">
              <input
                class="graphic-zoom"
                type="range"
                min="1"
                :max="sizeRanges.length"
                v-model="zoomLevel"
              />
              <div class="graphic-zoom-label">
                {{ zoomLabel }}
              </div>
            </div>
            <div class="graphic-modal-edit-mode-actions">
              <button
                class="btn black-btn graphic-modal-edit-mode-action"
                @click="save"
              >
                apply
              </button>
            </div>
          </div>
          <div class="graphic-modal-transparent-bg">
            <div
              :ref="refName"
              class="graphic-modal-edit-preview"
              :style="{ alignItems, justifyContent }"
            >
              <img
                class="graphic-modal-edit-preview-image"
                :src="editableScreenLogo"
                v-bind:style="{
                  maxWidth: zoomStyle,
                  maxHeight: zoomStyle,
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
// Converts base64 to png File
*/
/*function urltoFile(url, filename, mimeType){
    mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
}*/

import html2canvas from "html2canvas";
import { mapActions, mapState } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "EditscreenLogoModal",

  data() {
    return {
      selectedFile: null,
      emailId: null,
      dealerId: null,
      sizeRanges: ["25%", "50%", "75%", "100%"],
    };
  },

  props: ["refName"],

  computed: {
    ...mapState("screenLogo", [
      "graphicsLoading",
      "apiLoading",
      "apiLogos",
      "editMode",
      "position",
      "zoom",
      "aspectRatio",
      "editableScreenLogo",
    ]),

    zoomStyle() {
      return (this.zoom / this.sizeRanges.length).toFixed(2) * 100 + "%";
    },
    preserveAspectRatio: {
      get() {
        return this.aspectRatio;
      },
      set(newValue) {
        this.setAspectRatio(newValue);
      },
    },
    zoomLevel: {
      get() {
        return this.zoom;
      },
      set(newValue) {
        this.setZoom(newValue * 1);
      },
    },
    zoomLabel() {
      return this.sizeRanges[this.zoom - 1];
    },
    alignItems() {
      //returns align-items: $value
      return [1, 2, 3].indexOf(this.position) >= 0
        ? "flex-start"
        : [4, 5, 6].indexOf(this.position) >= 0
        ? "center"
        : "flex-end";
    },
    justifyContent() {
      return [1, 4, 7].indexOf(this.position) >= 0
        ? "flex-start"
        : [2, 5, 8].indexOf(this.position) >= 0
        ? "center"
        : "flex-end";
    },
    graphics() {
      return this.hasLogos &&
        this.apiLogos.graphics &&
        this.apiLogos.graphics.length
        ? this.apiLogos.graphics
        : [];
    },
    hasLogos() {
      return this.apiLogos != null && this.apiLogos.graphics;
    },
    hasEmail() {
      return this.emailId && this.emailId.trim().length;
    },
    hasDealer() {
      return this.dealerId && this.dealerId.trim().length;
    },
    hasEmailOrDealer() {
      return this.hasEmail || this.dealerId != null;
    },
    firstGraphicEmail() {
      return this.graphics && this.graphics.length
        ? this.graphics[0].Email
        : null;
    },
    emailNotChanged() {
      return this.firstGraphicEmail === this.emailId;
    },
  },

  methods: {
    ...mapActions("screenLogo", [
      "triggerModal", // 👀
      "uploadGraphic",
      "uploadGraphicRhino",
      "setPosition",
      "setZoom",
      "setAspectRatio",
      "setApiLogo",
      "setApiLoading",
      "setEditMode",
    ]),

    save() {
      this.setApiLoading(true);

      html2canvas(this.$refs[this.refName], {
        allowTaint: true,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: null,
      })
        .then((canvas) => {
          //convert base64 to filetype - here we are uploading to API the pnged image instead of original
          //I guess we should upload both
          //const fileName = this.selectedFile.name + '.png'
          /*urltoFile(canvas.toDataURL("image/png"), fileName)
          .then((file) => {
            this.uploadGraphic({
              file,
              imageName: fileName,
              fileName,
              AccountNumber: "",
              emailId: this.emailId
          })     
        })*/
          const base64Logo = canvas.toDataURL("image/png");

          this.$emit(
            "applyScreenLogo",
            //todo set original url and or post to peter api... both urls? TBD
            { logo: base64Logo, zoom: this.zoom, position: this.position }
          );
        })
        .catch(() => this.setApiLoading(false));
    },

    fileChanged() {
      this.selectedFile = this.$refs.fileUpload.files[0];
      const fileName = this.selectedFile.name;
      /*this.uploadGraphicRhino({
        file: this.selectedFile,
        imageName: "todo",
        AccountNumber: "",
        emailId: this.emailId
      })*/
      this.uploadGraphic({
        file: this.selectedFile,
        imageName: fileName,
        fileName: fileName,
        AccountNumber: "",
        emailId: this.emailId,
      });
    },

    close() {
      this.$emit("close");
    },
  },

  mounted() {
    if (this.hasLogos) {
      this.emailId = this.apiLogos.email;
    }
  },

  components: {
    Loader,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .modal-container {
    * {
      transition: all 0.2s;
    }
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    .graphics-modal {
      display: flex;
      position: relative;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000040;
      padding: 25px 30px;
      min-width: 400px;
      max-width: 960px;

      @media screen and (max-width: 576px) {
        min-width: 100%;
        max-width: 100%;
      }

      .close-modal {
        position: absolute;
        right: 15px;
        top: 10px;
        color: white;
        background-color: black;
        width: 30px;
        height: 30px;
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        z-index: 3;

        &:hover {
          transform: scale(1.05);
        }
      }
      .graphics-modal-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .graphic-modal-actions {
          display: flex;
          width: 100%;
          margin-top: 10px;
          .graphic-modal-email-action {
            padding: 5px 25px;
          }
        }
        .graphics-modal-email {
          display: flex;
          flex-direction: column;
          label {
            text-transform: uppercase;
            font-family: "SlateStd";
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .form-input {
            height: 40px;
            box-sizing: border-box;
            padding: 3px 5%;
            font-family: "SlateStd";
            border: none;
            background: #f8f8f8 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            outline: none;
            font-size: 16px;
            color: #2e2e2e;
          }
        }
      }
      .graphics-modal-content {
        .graphics-modal-body {
          .available-graphics-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .graphics-modal-footer {
          .upload-graphic-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
            .upload-graphic-label {
              width: 100%;
              height: 41px;
              background-color: #eee;
              max-width: 300px;
              padding: 10px;
              text-overflow: ellipsis;
              border: 1px solid #ccc;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              button {
                padding-left: 5px;
                font-size: 14px;
                font-weight: 600;
                padding: 1rem;
                border-radius: 10px;
                background: black;
                color: white;
                cursor: pointer;
              }
            }
          }
        }
      }

      .graphics-modal-edit-mode {
        display: flex;
        flex-direction: column;

        * {
          transition: all 0.2s;
        }
        .graphic-modal-edit-mode-content {
          display: flex;
          align-items: center;

          .graphic-modal-edit-panel {
            width: 260px;
            display: flex;
            flex-direction: column;
            padding-right: 25px;
            .graphic-panel-items {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              .graphic-panel-item {
                width: 33%;
                padding: 10px;
                display: flex;
                justify-content: center;
                .graphic-panel-item-image {
                  cursor: pointer;
                  &.active {
                    cursor: initial;
                  }
                }
              }
            }
            .graphic-zoom-container {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              .graphic-zoom {
                width: 100%;
              }
            }
            .graphic-modal-edit-mode-actions {
              display: flex;
              margin-top: 25px;
              justify-content: center;
              button {
                background: black;
                border-radius: 10px;
                border: none;
                color: white;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                font-size: 14px;
                font-weight: 600;
                padding: 1rem 1.5rem;
                text-transform: capitalize;

                &:hover {
                  transform: scale(1.05);
                }
              }
            }
          }
          .graphic-modal-transparent-bg {
            position: relative;
            width: 260px;
            height: 260px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
            outline: 1px solid;
            .graphic-modal-edit-preview {
              position: absolute;
              left: 0;
              top: 0;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              width: 260px;
              height: 260px;
              margin-right: 20px;
              background: transparent;
              .graphic-modal-edit-preview-image {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }

          @media screen and (max-width: 576px) {
            flex-direction: column;

            .graphic-modal-edit-panel {
              padding: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>