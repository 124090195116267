<template>
  <div class="page-404">
    <h2>404 - Not Found</h2>
    <p>
      The resource you are looking for is missing.
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goHome() {
      this.$store.dispatch("redirectToPageWithParams", {
        name: "Home",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .page-404 {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 48px;
      line-height: 48px;
    }
  }
}
</style>