import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Catalogue from '../views/Catalogue.vue'
import ProductS1 from '../views/MainProductS1.vue'
import ProductS2 from '../views/MainProductS2.vue'
import CompanionS1 from '../views/CompanionProductS1.vue'
import CompanionS2 from '../views/CompanionProductS2.vue'
import Locker from '../views/Locker.vue'
import Search from '../views/Search.vue'
import Orders from '../views/Orders.vue'
import NotFound from '../views/404.vue'
//
Vue.use(VueRouter)

const routes = [
  {
    path: '/search',
    name: 'SearchGuest',
    component: Search,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode',
    name: 'Home',
    component: Home,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug/home',
    name: 'HomeWithTeamLink',
    component: Home,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug/404',
    name: 'NotFoundWithTeamLink',
    component: NotFound,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/catalogue',
    name: 'Catalogue',
    component: Catalogue,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug',
    name: 'CatalogueWithTeamLink',
    component: Catalogue,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/product',
    name: 'Product',
    component: ProductS1,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug/product',
    name: 'ProductWithTeamLink',
    component: ProductS1,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/layout',
    name: 'MainProductS2',
    component: ProductS2,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug/layout',
    name: 'MainProductS2WithTeamLink',
    component: ProductS2,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/companion-editor',
    name: 'CompanionEditor',
    component: CompanionS1,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug/companion-editor',
    name: 'CompanionEditorWithTeamLink',
    component: CompanionS1,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/companion-layout',
    name: 'CompanionLayouts',
    component: CompanionS2,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug/companion-layout',
    name: 'CompanionLayoutsWithTeamLink',
    component: CompanionS2,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/locker',
    name: 'Locker',
    component: Locker,
    meta: {
      title: "Gameplan",
    }
  },
  {
    path: '/:dealercode/showroom/:showroom_id/:showroom_slug/locker',
    name: 'LockerWithTeamLink',
    component: Locker,
    meta: {
      title: "Gameplan",
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next(); 
  // ojota, necesitamos que el next se haga al final, pero no se bien como puede afectar al a esta funcion que es para los metatags

  // Turn the meta tag definitions into actual elements in the head.
  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  }


  if (!hasQueryParams(to) && hasQueryParams(from) && !dealerModeHasBeenDeleted(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next()
  }
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

function dealerModeHasBeenDeleted(route) {
  return route.query && router.query?.dealer_mode
}



export default router
