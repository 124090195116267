import CONSTANTS from '@/constants.js';

//modules
import breadcrumbs from '@/store/modules/breadcrumbs.js';
import customer from '@/store/modules/customer.js';
import screenLogo from '@/store/modules/screenLogo.js';
import actionsPermissions from '@/store/modules/actionsPermissions.js';
import project from '@/store/modules/project.js';

//dependencies
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router';

Vue.use(Vuex)
const state = {
  settings: {
    token: false,
    rhinoCode: '',
    endpoint: process.env.VUE_APP_ENDPOINT,
    default_color_1: CONSTANTS.DEFAULT.C1,
    default_color_2: CONSTANTS.DEFAULT.C2,
    default_color_3: CONSTANTS.DEFAULT.C3,

  },

  activeProduct: 'main_product',
  editMode: false,
  url_params: false,
  url_param: false,
  goToLocker: false,
  homeDisabled: false,
  headerDisabled: false,

  dealer: false,
  team_link: {},
  dealerMode: 0,
  locker: false,
  uniform_id: 0,
  stockColor: false,
  customerId: '',
  isAnEmbeddedShowroom: false,
  embeddedEmail: null,
  locked: {
    sport: false,
    gender: false,
    colors: false,
    event: false,
  },

  isShowroomLockedByDealer: false,

  fonts: [
    {
      label: 'Full Block',
      id: 1,
      file: 'block'
    },
    {
      label: 'Apache',
      id: 2,
      file: 'apache'
    },
    {
      label: 'Collegiate',
      id: 3,
      file: 'collegiate'
    },
    {
      label: 'Falcon',
      id: 4,
      file: 'falcon'
    },
    {
      label: 'Warrior 1',
      id: 5,
      file: 'warrior_1'
    },
    {
      label: 'University',
      id: 6,
      file: 'university'
    }
  ],

  main_product: {
    color_1: CONSTANTS.DEFAULT.C1,
    color_2: CONSTANTS.DEFAULT.C2,
    color_3: CONSTANTS.DEFAULT.C3,
    reversible_color_1: CONSTANTS.DEFAULT.C2,
    reversible_color_2: CONSTANTS.DEFAULT.C1,
    reversible_color_3: CONSTANTS.DEFAULT.C3,
    reversibleActive: false,
    layout: {},
    reversible_layout: {},
    design: {},
    product: {},
    front: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Team name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Team Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      logo: {
        url: CONSTANTS.DEFAULT_LOGO_URL,
        original_url: '',
      }
    },
    back: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Player name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Player Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      logo: {
        url: CONSTANTS.DEFAULT_LOGO_URL,
        original_url: '',
      }
    },
    side: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Team name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"Team name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      },
      logo: {
        url: '',
        original_url: '',
      }
    },
    reversible_front: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Team name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Team Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      logo: {
        url: CONSTANTS.DEFAULT_LOGO_URL,
        original_url: '',
      }
    },
    reversible_back: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Player name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Player Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      logo: {
        url: CONSTANTS.DEFAULT_LOGO_URL,
        original_url: '',
      }
    },
    reversible_side: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Team name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"Team name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      },
      logo: {
        url: '',
        original_url: '',
      }
    },
  },

  companion_product: {
    color_1: CONSTANTS.DEFAULT.C1,
    color_2: CONSTANTS.DEFAULT.C2,
    color_3: CONSTANTS.DEFAULT.C3,
    layout: {},
    design: {},
    product: {},
    front: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Team name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Team Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      logo: {
        url: CONSTANTS.DEFAULT_LOGO_URL,
        original_url: '',
      }
    },
    back: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Player name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Player Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
        size: 3,
      },
      logo: {
        url: CONSTANTS.DEFAULT_LOGO_URL,
        original_url: '',
      }
    },
    side: {
      artwork: 0,
      artwork_color_1: CONSTANTS.DEFAULT.C1,
      artwork_color_2: CONSTANTS.DEFAULT.C2,
      artwork_color_3: CONSTANTS.DEFAULT.C3,
      name: {
        value: 'Team name',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"Team name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      },
      number: {
        value: '00',
        fill: CONSTANTS.DEFAULT.FILL_ID,
        outline: CONSTANTS.DEFAULT.OUTLINE_ID,
        font: 1,
        visible: true,
        stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      },
      logo: {
        url: '',
        original_url: '',
      }
    },
  },

  colors: [],
  sports: [],
  selectedSports: [],

  gender: "men",
  sport: null,

  catalogs: [],
  homeCatalogsIds: [],
  activeProductCatalogId: null,
  loadingCatalogs: false,

  genders: [],

  companion_items: [],
  companion_items_loaded: false,

  loginLoading: false,
  loginError: false,

  initialSpiritwearLogo: {
    logoId: '',
    logo: '',
    position: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
    zoom: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,

  },

  initialUniformsLogo: {
    logoId: '',
    logo: '',
    position: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
    zoom: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
  },

  activeScreenLogo: {
    logoId: '',
    logo: '',
    position: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
    zoom: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
  }

};

const getters = {

  lockerRoute() {
    // return `snapsheets/:dealer_code/:snapsheet_id/locker/:locker_code`
    const { dealercode, showroom_id, showroom_slug } = router.currentRoute.params;
    return showroom_id ? `/${dealercode}/showroom/${showroom_id}/${showroom_slug}/locker` : `/${dealercode}/locker`
  },

  showroomStatus(state) { return state.team_link?.status },

  companionItems: (state) => {
    return state.companion_items;
  },
  activeProductIsReversible: (state, getters) => {
    //return getters.activeProduct.product.is_reversible || getters.activeProduct.product.is_sublimable;
    return getters.activeProduct.product.is_reversible; // || getters.activeProduct.product.is_sublimable; for testing
  },
  activeProductReverseActive: (state, getters) => {
    return getters.activeProduct.reversibleActive;
  },
  activeProduct: (state) => {
    return state[state.activeProduct];
    // state[main_product] or state[companion_prodcut]
  },
  activeProductType: (state) => {
    return state.activeProduct;
  },
  mainProductLayout: (state) => {
    return state.main_product.layout;
  },
  lockerId: (state) => {
    return state.team_link ? state.team_link.id : false;
  },
  teamLink: (state) => {
    return state.team_link;
  },
  optimizeField: (state) => (data) => {
    let response = {}, layers = [], layout = state[data.product].layout;
    let dataSide = data.reversible ? "reversible_" + data.side : data.side;
    if (layout.layout_attributes && layout.layout_attributes[data.side]) {
      layers = Object.keys(layout.layout_attributes[data.side]);
      for (let index = 0; index < layers.length; index++) {
        const layer = data.reversible && layers[index].includes("color_")
          ? "reversible_" + layers[index]
          : layers[index];
        response[layer] = state[data.product][dataSide][layer];
        if (layer === "artwork") {
          for (let c = 1; c < 4; c++) {
            response["artwork_color_" + c] = state[data.product][dataSide]["artwork_color_" + c];
          }
          response["name"] = state[data.product][dataSide].name;
        }
      }
    }
    return response;
  },
  getArtwork: (state) => (data) => {
    return state[data.product][data.side].artwork;
  },
  getColorHexByField: (state) => (data) => {
    let side = state[data.product_type + '_product'].reversibleActive ? 'reversible_' + data.side : data.side;
    let color_id = data.isArtwork
      ? state[data.product_type + '_product'][side][data.field]
      : !data.side || !data.subfield
        ? state[data.product_type + '_product'].reversibleActive
          ? !data.alternative
            ? state[data.product_type + '_product']['reversible_' + data.field]
            : state[data.product_type + '_product'][data.field]
          : !data.alternative
            ? state[data.product_type + '_product'][data.field]
            : state[data.product_type + '_product']['reversible_' + data.field]
        : state[data.product_type + '_product'][side][data.field][data.subfield];
    for (let index = 0; index < state.colors.length; index++) {
      const element = state.colors[index];
      if (element.id === color_id) {
        return element.hex;
      }
    }
    return '#FFFFFF'; //White placeholder when data still didn't come from the API
  },

  getColorNameByField: (state) => (data) => {
    let side = state[data.product_type + '_product'].reversibleActive ? 'reversible_' + data.side : data.side;
    let color_id = data.isArtwork
      ? state[data.product_type + '_product'][side][data.field]
      : !data.side || !data.subfield
        ? state[data.product_type + '_product'].reversibleActive
          ? state[data.product_type + '_product']['reversible_' + data.field]
          : state[data.product_type + '_product'][data.field]
        : state[data.product_type + '_product'][side][data.field][data.subfield];
    for (let index = 0; index < state.colors.length; index++) {
      const element = state.colors[index];
      if (element.id === color_id) {
        return element.name;
      }
    }
    return false;
  },

  getColorByField: (state) => (data) => {
    let color_id = state[data.product_type + '_product'][data.field];
    for (let index = 0; index < state.colors.length; index++) {
      const element = state.colors[index];
      if (element.id === color_id) {
        return element;
      }
    }
    return null;
  },

  getColorById: (state) => (id) => {
    for (let index = 0; index < state.colors.length; index++) {
      const element = state.colors[index];
      if (element.id === id) {
        return element;
      }
    }
    return false;
  },

  getAllColors: (state) => {
    return state.colors;
  },

  getTeamNameUrl: (state) => {
    return state.teamNameUrl
  },

  getAllSports: (state) => {
    return state.catalogs;
  },

  getSelectedCatalogsIds: (state) => {
    const selectedCatalogs = state.catalogs.filter(catalog => catalog.selected === true)
    const arrayOfIds = selectedCatalogs.map(catalog => catalog.id)
    return selectedCatalogs.length > 0 ? arrayOfIds : []
  },

  getSport: (state) => (id) => {
    return state.catalogs.filter(sport => sport.id == id);
  },

  getLockedStates: (state) => {
    return state.locked;
  },

  eventLoading: (state) => {
    return state.locked.event;
  },

  getGender: (state) => {
    return state.gender;
  },

  getGenderName: (state) => {
    return state.gender;
  },

  getCurrentSport: (state) => {
    for (let index = 0; index < state.catalogs.length; index++) {
      const element = state.catalogs[index];
      if (element.id === state.activeProductCatalogId) {
        return element;
      }
    }
    return [];  //Returns empty when still not get info from the API
  },

  getCurrentCatalogue: (state) => {
    return state.currentCatalogue;
  },

  getCurrentDesign: (state) => {
    return state.currentDesign;
  },

  logoIsPlaceholder: (state, getters) => (data) => {
    let reversibleActive = !data.alternative ? getters.activeProduct.reversibleActive : !getters.activeProduct.reversibleActive;
    let sideKey = reversibleActive ? "reversible_" + data.side : data.side;
    return getters.activeProduct[sideKey].logo?.url?.length && !getters.activeProduct[sideKey].logo.original_url?.length && !state.customer.spiritwearLogo.id && !state.customer.uniformsLogo.id
  },

  productIsSublimable: (state) => (product_type) => {
    return state[product_type + '_product'].product.is_sublimable;
  },

  getTeamLink: (state) => {
    return state.team_link;
  },

  dealer: (state) => {
    return state.dealer;
  },

  getDealerSettings: (state) => state.dealer.settings,

  editMode: (state) => {
    return state.editMode;
  },

  showSpiritwearLogoSidebar: () => (filteredCatalogs) => {
    return filteredCatalogs.some(sport => sport.name.toLowerCase() === 'spiritwear')
  },

  showUniformsLogoSidebar: () => (filteredCatalogs) => {
    return filteredCatalogs.some(sport => sport.name.toLowerCase() !== 'spiritwear')
  },

  doesSessionDataBelongsToDealer: () => (sessionData) => {
    return sessionData.dealerCode.toLowerCase() === router.currentRoute.params.dealercode.toLowerCase()
  },

  shouldShowCardInHomepage: (state, getters) => (catalogId) => {
    return getters.doesCatalogsHasSomeProducts ? state.homeCatalogsIds?.some(id => id == catalogId) : true
  },

  doesCatalogsHasSomeProducts: () => { return state.catalogs?.some(cat => cat.products && cat.products.length) },

  isCatalogLoaded: (state) => (id) => {
    const catalog = state.catalogs?.filter(catalog => catalog.id == id)[0]
    return catalog.products?.length > 0
  }
};

const mutations = {
  setActiveMainProduct: (state) => {
    state.activeProduct = "main_product";
  },
  setActiveCompanionProduct: (state) => {
    state.activeProduct = "companion_product";
  },
  resetReversible: (state) => {
    state[state.activeProduct].reversibleActive = false;
  },
  resetCompanionColors: (state) => {
    state.companion_product.color_1 = state.main_product.color_1;
    state.companion_product.color_2 = state.main_product.color_2;
    state.companion_product.color_3 = state.main_product.color_3;
    let layerSides = CONSTANTS.LAYER.SIDES,
      layerFields = CONSTANTS.LAYER.MAIN_FIELDS;
    for (let index = 0; index < layerSides.length; index++) {
      const layerSide = layerSides[index];
      for (let f = 0; f < layerFields.length; f++) {
        const layerField = layerFields[f];
        state.companion_product[layerSide][layerField].fill = state.main_product.color_2;
        state.companion_product[layerSide][layerField].outline = state.main_product.color_3;
      }
    }
  },
  toggleReversible: (state) => {
    state[state.activeProduct].reversibleActive = !state[state.activeProduct].reversibleActive;
  },
  reversibleImportData: (state) => {
    //set rc2 = c1, rc1 = c2
    state[state.activeProduct].reversible_color_1 = state[state.activeProduct].color_2;
    state[state.activeProduct].reversible_color_2 = state[state.activeProduct].color_1;
    state[state.activeProduct].reversible_color_3 = state[state.activeProduct].color_3;
    for (let index = 0; index < CONSTANTS.LAYER.SIDES.length; index++) {
      const side = CONSTANTS.LAYER.SIDES[index];
      state[state.activeProduct]['reversible_' + side] = JSON.parse(JSON.stringify(state[state.activeProduct][side])); //bypass copy with reference
    }
  },
  fixColor1: (state) => {
    if (!state[state.activeProduct].color_1) {
      state[state.activeProduct].color_1 = CONSTANTS.DEFAULT.C1;
    }
  },
  //Initizalize states from API
  setInitialSettings: (state, settings) => {
    state.colors = settings.colors;
    state.embeddedEmail = settings.embeddedEmail
    state.headerDisabled = settings.headerDisabled

    Vue.set(state.settings, 'token', settings.token)
    Vue.set(state.settings, 'rhinoCode', settings.dealer.rhino_code)

    state.dealer = settings.dealer;

    // activeProductCatalogId = state.catalogs[0].id;
    let dealercode = router.currentRoute.params.dealercode,
      lockerId = router.currentRoute.params.showroom_id,
      lockerSlug = router.currentRoute.params.showroom_slug;
    let reservedRoutes = ["Catalogue", "CatalogueWithTeamLink"];

    let product_types = ["main_product", "companion_product"],
      layers = CONSTANTS.LAYER.SIDES, product_type, layer, product_index, index;

    if (settings.team_link) {
      state.homeDisabled = true;
      for (product_index = 0; product_index < product_types.length; product_index++) {
        product_type = product_types[product_index];
        //override base colors with the ones set in the team link
        Vue.set(state.settings, 'default_color_1', settings.team_link.color_id_1)
        Vue.set(state.settings, 'default_color_2', settings.team_link.color_id_2)
        Vue.set(state.settings, 'default_color_3', settings.team_link.color_id_3)
      }
      state.team_link = settings.team_link;
    }

    //Customer - Spiritwear/Uniforms Logo

    if (settings.team_link) {

      state.customerId = settings.team_link.customer_id;

      state.spiritwearLogoId = settings.team_link.spiritwear_logo_id;
      state.uniformsLogoId = settings.team_link.uniforms_logo_id;

      state.initialSpiritwearLogo = { logoId: settings.team_link.spiritwear_logo_id, logo: settings.team_link.spiritwear_logo_url === 'https://s3.amazonaws.com/marketing.foundersportgroup.com/' ? '' : settings.team_link.spiritwear_logo_url, position: settings.team_link.spiritwear_logo_settings?.position, zoom: settings.team_link.spiritwear_logo_settings?.zoom, };
      state.initialUniformsLogo = { logoId: settings.team_link.uniforms_logo_id, logo: settings.team_link.uniforms_logo_url === 'https://s3.amazonaws.com/marketing.foundersportgroup.com/' ? '' : settings.team_link.uniforms_logo_url, position: settings.team_link.uniforms_logo_settings?.position, zoom: settings.team_link.uniforms_logo_settings?.zoom, };

    }

    //set initial colors
    let c1 = state.settings.default_color_1,
      c2 = state.settings.default_color_2,
      c3 = state.settings.default_color_3;
    for (product_index = 0; product_index < product_types.length; product_index++) {
      product_type = product_types[product_index];
      state[product_type].color_1 = c1;
      state[product_type].color_2 = c2;
      state[product_type].color_3 = c3;
      for (index = 0; index < layers.length; index++) {
        layer = layers[index];
        state[product_type][layer].name.fill = c2;
        state[product_type][layer].number.fill = c2;
        state[product_type][layer].name.outline = c3;
        state[product_type][layer].number.outline = c3;
        state[product_type][layer].artwork_color_1 = c1;
        state[product_type][layer].artwork_color_2 = c2;
        state[product_type][layer].artwork_color_3 = c3;
        // state[product_type][layer].logo.url = CONSTANTS.DEFAULT_LOGO_URL;
        if (settings.team_link && settings.team_link.logo_converted) {
          state[product_type][layer].logo.original_url = settings.team_link.logo_original;
        }
      }
    }

    if (dealercode && reservedRoutes.indexOf(router.currentRoute.name) === -1 && router.currentRoute.name.indexOf('Locker') === -1) {

      if (lockerId) {
        router.push({ name: 'CatalogueWithTeamLink', params: { dealercode, lockerId, lockerSlug } })
      }
    }
  },

  clearDealerMode: (state) => {
    state.dealerMode = 0;
    state.settings.token = null;
  },

  setDealerMode: (state, data) => {
    state.dealerMode = 1;
    state.settings.token = data
  },

  lockStates: (state, keys) => {
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      state.locked[key] = true;
    }
  },

  unlockStates: (state, keys) => {
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      state.locked[key] = false;
    }
  },

  toggleLayoutVisibility: (state, data) => {
    state.teamColors[data.field] = data.visible;
  },

  // updateProductCards: (state, data) => {
  //   state.catalogs = data.products;
  //   state.gender = data.default_gender; 
  //   //state.artworks = data.artworks;
  //   state.genders = data.genders;
  // },

  updateGenders: (state) => {
    const allGenders = state.catalogs?.flatMap(cat => cat.genders)

    state.genders = [...new Set(allGenders)];
  },

  setDefaultStampValues: (state) => {
    let layerSides = CONSTANTS.LAYER.SIDES,
      productTypes = ["main_product", "companion_product"],
      fields = CONSTANTS.LAYER.MAIN_FIELDS;
    for (let p = 0; p < productTypes.length; p++) {
      const productType = productTypes[p];
      for (let index = 0; index < layerSides.length; index++) {
        const layerSide = layerSides[index];
        for (let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++) {
          const field = fields[fieldIndex];
          if (!state[productType][layerSide][field] || !state[productType][layerSide][field].value) {
            state[productType][layerSide][field] = CONSTANTS.DEFAULT_PRODUCT_DATA[layerSide][field];
          }
        }
      }
    }
  },

  setDefaultArtworks: (state) => {
    if (state.artworks && state.artworks.length) {
      let layerSides = CONSTANTS.LAYER.SIDES,
        productTypes = ["main_product", "companion_product"];
      for (let p = 0; p < productTypes.length; p++) {
        const productType = productTypes[p];
        for (let index = 0; index < layerSides.length; index++) {
          const layerSide = layerSides[index];
          state[productType][layerSide].artwork = state.artworks[0].id;
        }
      }
    }
  },

  updateProductField: (state, data) => {
    state[state.activeProduct][data.field] = data.value;
  },

  updateProductSubField: (state, data) => {
    state[state.activeProduct][data.field][data.subfield] = data.value;
  },

  updateProductSubFieldKey: (state, data) => {
    state[state.activeProduct][data.field][data.subfield][data.key] = data.value;
  },

  updateState: (state, data) => {
    state[data.state] = data.value;
  },

  updateSubState: (state, data) => {
    state[data.state][data.substate] = data.value;
  },

  updateSubStateField: (state, data) => {
    state[data.state][data.substate][data.field] = data.value;
  },

  updateSubStateSubField: (state, data) => {
    state[data.state][data.substate][data.field][data.subfield] = data.value;
  },

  setColor: (state, data) => {
    if (data.subfield) {
      let sideFillOutline = state[state.activeProduct].reversibleActive ? 'reversible_' + data.side : data.side;
      state[state.activeProduct][sideFillOutline][data.field][data.subfield] = data.id;
    }
    else {
      if (data.field.indexOf('artwork_color') !== -1) { //artwork colors
        state[state.activeProduct][data.side][data.field] = data.id;
      }
      else {
        if (!state[state.activeProduct].reversibleActive) {
          state[state.activeProduct][data.field] = data.id;
          //update layout colors
          let layers = CONSTANTS.LAYER.SIDES, target = 'outline';
          if (data.field === "color_2" || data.field === "color_3") {
            for (let index = 0; index < layers.length; index++) {
              const layer = state[state.activeProduct].reversibleActive ? 'reversible_' + layers[index] : layers[index];
              if (data.field === "color_2") {
                target = 'fill'; //outline is default
              }
              if (state[state.activeProduct][layer].name) {
                state[state.activeProduct][layer].name[target] = data.id;
              }
              if (state[state.activeProduct][layer].number) {
                state[state.activeProduct][layer].number[target] = data.id;
              }
            }
          }
        }
        else {
          state[state.activeProduct]['reversible_' + data.field] = data.id;
        }
      }
    }
  },

  setTeamName: (state, data) => {
    let layerSides = CONSTANTS.LAYER.MAIN_SIDES;
    for (let index = 0; index < layerSides.length; index++) {
      const layerSide = layerSides[index];
      state[data.product_type + '_product'][layerSide].name.value = data.value;
    }
  },

  setArtwork: (state, data) => {
    state[data.product][data.side].artwork = data.value;
  },

  setStampData: (state, data) => {
    state[data.product_type + '_product'][data.side][data.field][data.property] = data.url;
  },

  setGender: (state, name) => (state.gender = name),

  selectCatalog: (state, id) => {
    state.catalogs.forEach(sport => {
      if (sport.id == id) sport.selected = true
    })
  },

  unselectCatalog: (state, id) => {
    state.catalogs.forEach(sport => {
      if (sport.id == id) sport.selected = false
    })
  },

  setCompanionItems: (state, data) => {
    state.locked.event = false;
    state.companion_items = data;
  },

  lockEvent() {
    state.locked.event = true;
  },

  unlockEvent() {
    state.locked.event = false;
  },

  resetProductState: (state, data) => {
    if (state[data.product_type + '_product'] && Object.keys(state[data.product_type + '_product']).length) {
      state[data.product_type + '_product'].layout = {};
      state[data.product_type + '_product'].design = {};
      state[data.product_type + '_product'].product = {};
    }
    else {
      state[data.product_type + '_product'] = {};
    }
  },
  completeUniform: (state) => {
    state.locker = state.lockerModule.locker;
    state.locked.event = false;
    //state.artworks = data.data.artworks;
    if (router.currentRoute.name.indexOf('Locker') === -1 && state.goToLocker) { //push to locker page if not there already
      let params = {
        dealercode: router.currentRoute.params.dealercode,
      }, name = "Locker";
      if (router.currentRoute.params.showroom_id) {
        params.showroom_id = router.currentRoute.params.showroom_id;
        params.showroom_slug = router.currentRoute.params.showroom_slug;
        name = "LockerWithTeamLink";
      }
      router.push({
        name,
        params
      });
    }
  },
  updateLockerUniformName: (state, data) => {
    for (let index = 0; index < state.locker.data.items.length; index++) {
      const element = state.locker.data.items[index];
      if (element.id === data.item_id) {
        element.name = data.item_name;
      }
    }
  },
  uniformRoster: (state, data) => {
    for (let index = 0; index < state.locker.data.items.length; index++) {
      const element = state.locker.data.items[index];
      if (element.id === data.item_id) {
        element.roster = data.roster;
        break;
      }
    }
  },
  uniformClone: (state, data) => {
    let clone = {};
    for (let index = 0; index < state.locker.data.items.length; index++) {
      const element = state.locker.data.items[index];
      if (element.id === data.item_id) {
        Object.assign(clone, element);
        clone.id = data.new_id;
        state.locker.data.items.push(clone);
        break;
      }
    }
  },
  deleteLockerUniform: (state, data) => {
    for (let index = 0; index < state.locker.data.items.length; index++) {
      const element = state.locker.data.items[index];
      if (element.id === data.item_id) {
        state.locker.data.items.splice(index, 1);
      }
    }
  },

  changeLoginLoadingState: (state, bool) => state.loginLoading = bool,

  changeLoginErrorState: (state, bool) => state.loginError = bool,

  setAsEmbeddedShowroom: (state) => state.isAnEmbeddedShowroom = true,

  setNewActiveScreenLogo(state, newScreenLogoData) {
    const newScreenLogo = {
      ...newScreenLogoData,
      position: newScreenLogoData?.screenLogoProperties?.position || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
      zoom: newScreenLogoData?.screenLogoProperties?.zoom || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
    }
    Vue.set(state, 'activeScreenLogo', newScreenLogo)
  },

  SET_CATALOGS(state, catalogs) {
    state.catalogs = catalogs
  },

  SET_HOME_CATALOGS_IDS(state, homeCatalogsIds) {
    state.homeCatalogsIds = homeCatalogsIds
  },

  SET_LOADING_CATALOGS(state, booleanState) {
    state.loadingCatalogs = booleanState
  },
  SET_ACTIVE_PRODUCT_CATALOG_ID(state, catalogId) {
    state.activeProductCatalogId = catalogId
  },

  SET_SHOWROOM_STATUS(state, status) {
    Vue.set(state.team_link, 'status', status)
  }

};

const actions = {

  async dealerLogin({ state, commit, dispatch }, data) {
    const url = state.settings.endpoint + `api/snapsheets/dealer`

    const params = {
      code: router.currentRoute.params.dealercode,
      passcode: data.passcode,
      project_type: 'showroom',
      project_id: Number(router.currentRoute.params.showroom_id)
    }

    try {
      //sino, logeamos
      dispatch('triggerLoginStartLoading');
      const { data } = await axios.get(url, { params })
      if (data.api_token) {
        data['dealerCode'] = router.currentRoute.params.dealercode.toLowerCase()
        //setear session storage
        await dispatch('saveSessionData', data)
        commit("setDealerMode", data.api_token)
      }
      else {
        dispatch("triggerLoginError");
      }
    } catch (error) {
      dispatch("triggerLoginError");
    } finally {
      dispatch('triggerLoginEndLoading')
    }

  },

  setDealerMode({ commit }, data) { commit('setDealerMode', data) },

  clearDealerMode({ commit }) {
    sessionStorage.removeItem(CONSTANTS.GAMEPLAN_SESSION)
    commit('clearDealerMode')
  },

  async checkIfSessionExists({ getters, dispatch }) {

    if (sessionStorage.getItem(CONSTANTS.GAMEPLAN_SESSION)) {

      const sessionData = await dispatch('getSessionData')
      return getters['doesSessionDataBelongsToDealer'](sessionData)
    } else {
      return false
    }
  },

  async getSessionData() {
    try {
      return await JSON.parse(sessionStorage.getItem(CONSTANTS.GAMEPLAN_SESSION))
    } catch (e) {
      // console.log(e);
      throw new Error(e)
    }
  },

  async saveSessionData(_, sessionData) {
    try {
      const sessionDataStringified = await JSON.stringify(sessionData)
      sessionStorage.setItem(CONSTANTS.GAMEPLAN_SESSION, sessionDataStringified)

    } catch (e) {
      // console.log(e);
      throw new Error(e)
    }
  },

  goToLocker({ getters }) {
    router.push(getters.lockerRoute)
  },

  triggerLoginStartLoading({ commit }) {
    commit('changeLoginLoadingState', true)
  },

  triggerLoginEndLoading({ commit }) {
    commit('changeLoginLoadingState', false)
  },

  triggerLoginError({ commit }) {
    commit('changeLoginErrorState', true)
  },

  resetError({ commit }) {
    commit('changeLoginErrorState', false)
  },

  lockStates({ commit }, data) {
    commit("lockStates", data);
  },
  unlockStates({ commit }, data) {
    commit("unlockStates", data);
  },
  resetReversible({ commit }) {
    commit("resetReversible");
  },
  resetCompanionColors({ commit }) {
    commit("resetCompanionColors");
  },
  toggleReversible({ commit }) {
    commit("toggleReversible");
  },
  setActiveMainProduct({ commit }) {
    commit("setActiveMainProduct");
  },
  setActiveCompanionProduct({ commit }) {
    commit("setActiveCompanionProduct");
  },
  updateProductField({ commit }, data) {
    commit("updateProductField", data);
  },
  updateProductSubField({ commit }, data) {
    commit("updateProductSubField", data);
  },
  updateProductSubFieldKey({ commit }, data) {
    commit("updateProductSubFieldKey", data);
  },
  updateArtworks({ commit }, payload) {
    commit("updateState", {
      state: "artworks",
      value: payload.artworks,
    });

    //assumming we only use artworks in front side
    commit('updateSubStateField', {
      state: payload.productType,
      substate: 'front',
      field: 'artwork',
      value: (payload.artworks && payload.artworks.length) ? payload.artworks[0].id : 0,
    });
  },
  reversibleImportData({ commit }) {
    commit('reversibleImportData');
  },

  fixColor1({ commit }) {
    commit("fixColor1");
  },
  regenerateTextStamps({ getters }, productType) {
    //request text stamps for each layer
    let layerSides = CONSTANTS.LAYER.SIDES,
      layerFields = CONSTANTS.LAYER.MAIN_FIELDS;
    for (let index = 0; index < layerSides.length; index++) {
      const layerSide = layerSides[index];
      for (let indey = 0; indey < layerFields.length; indey++) {
        const layerField = layerFields[indey];
        if (state[productType][layerSide][layerField]) {
          this.dispatch("requestTextStamp", {
            value: state[productType][layerSide][layerField].value,
            field: layerField,
            side: state[productType].reversibleActive ? 'reversible_' + layerSide : layerSide,
            fill: getters.getColorById(state[productType][layerSide][layerField].fill).hex,
            outline: getters.getColorById(state[productType][layerSide][layerField].outline).hex,
            product_type: productType === 'main_product' ? 'main' : 'companion',
            font: state[productType][layerSide][layerField].font,
          });
        }
      }
    }
  },
  updateLogo({ commit }, data) {
    commit('updateSubStateField', {
      state: data.product_type + '_product',
      substate: data.side,
      field: 'logo',
      value: {
        url: data.url,
        original_url: data.original_url,
      }
    });
  },
  redirectToPageWithParams(e, page) {

    let params = {
      dealercode: router.currentRoute.params.dealercode,
    },
      name = page.name;
    if (router.currentRoute.params.showroom_id) {
      params.showroom_id = router.currentRoute.params.showroom_id;
      params.showroom_slug = router.currentRoute.params.showroom_slug;
      name = name + "WithTeamLink";
    }

    router.push({
      name: name,
      params
    });
  },
  // getLockerByCode({ dispatch, commit }, data) {

  //   dispatch('getLockerByCode', data.code)
  //     .then((response) => {
  //       commit("updateSubState", {
  //         state: 'locker',
  //         substate: 'data',
  //         value: response,
  //       })
  //     })
  //     .catch(() => {
  //       this.locker_info = 'Error trying to information';
  //     });
  // },
  async updateLockerName({ commit }, data) {
    await axios.post(state.settings.endpoint + 'api/v2/project/name',
      {
        locker_code: data.code,
        locker_name: data.name,
      },
      {
        headers: {
          Authorization: 'Bearer ' + state.settings.token,
          'Content-Type': 'application/json',
        }
      },
    );
    commit("updateSubStateField", {
      state: 'locker',
      substate: 'data',
      field: 'name',
      value: data.name,
    });
  },
  async updateUniformName({ commit }, data) {
    await axios.post(state.settings.endpoint + 'api/v2/project/item/name',
      {
        //locker_code: data.code,
        item_id: data.id,
        item_name: data.name,
      },
      {
        headers: {
          Authorization: 'Bearer ' + state.settings.token,
          'Content-Type': 'application/json',
        }
      },
    );
    commit("updateLockerUniformName", {
      item_id: data.id,
      item_name: data.name,
    });
  },
  async cloneUniform({ commit }, data) {
    const response = await axios.post(state.settings.endpoint + 'api/v2/project/item/clone',
      {
        //locker_code: data.code,
        item_id: data.item_id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + state.settings.token,
          'Content-Type': 'application/json',
        }
      },
    );
    commit("uniformClone", {
      item_id: data.item_id,
      new_id: response.data,
    });
  },
  async postUniformRoster({ commit }, data) {
    state.locked.event = true;
    await axios.post(state.settings.endpoint + 'api/v2/project/item/roster',
      {
        //locker_code: data.code,
        item_id: data.item_id,
        roster: JSON.stringify(data.roster),
      },
      {
        headers: {
          Authorization: 'Bearer ' + state.settings.token,
          'Content-Type': 'application/json',
        }
      },
    );
    commit("uniformRoster", {
      item_id: data.item_id,
      roster: JSON.stringify(data.roster),
    });

    commit("updateSubState", {
      state: 'locked',
      substate: 'event',
      value: false,
    });
  },
  //Remove an uniform from a locker
  async deleteUniform({ commit }, data) {
    await axios.delete(state.settings.endpoint + 'api/v2/project/item', {
      headers: {
        Authorization: 'Bearer ' + state.settings.token
      },
      params: {
        locker_code: data.code,
        item_id: data.item_id,
      }
    });
    commit("deleteLockerUniform", {
      item_id: data.item_id,
    });
  },
  async completeUniform({ dispatch, getters }) {
    state.locked.event = true;
    //remove old logo if the layout does not have a logo enabled
    let sides = CONSTANTS.LAYER.SIDES, has_logo = false;
    for (let index = 0; index < sides.length; index++) {  //search if the layout allows a logo in at least one side of the product
      if (state.main_product.layout && state.main_product.layout.layout_attributes && state.main_product.layout.layout_attributes[sides[index]] && state.main_product.layout.layout_attributes[sides[index]].logo) {
        has_logo = true;
      }
    }

    //Clear 'original_url' prop (because the backend doesnt' need it)
    if (has_logo) {
      for (let index = 0; index < sides.length; index++) {
        state.main_product[sides[index]].logo.original_url = ''
      }
    }

    if (!has_logo) { //if it doesnt then remove all the logo information we may have from a previous design
      for (let index = 0; index < sides.length; index++) {
        state.main_product[sides[index]].logo = {
          url: '',
          original_url: '',
        }
      }
    }


    let lockerCode = state.lockerModule?.locker?.code

    const productsToSave = [state.main_product].filter(pr => !!pr?.product)
    //Check if there is a companion product
    if (state.companion_product && state.companion_product.product != null && Object.keys(state.companion_product.product).length && state.companion_product.product.designs_by_gender) productsToSave.push(state.companion_product)

    function saveToLocker(product) {

      return new Promise((resolve, reject) => {

        const decorations = {
          front: product?.layout?.layout_attributes?.front
            ? {
              name: product.front.name && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.front, 'name')
                ? {
                  ...product.front.name,
                  url: product.front.name?.stamp_url || ''
                }
                : {},
              number: product.front.number && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.front, 'number')
                ? {
                  ...product.front.number,
                  url: product.front.number?.stamp_url || ''
                }
                : {},
              logo: product.front.logo?.stamp_url || product.front.logo?.url || product.front.logo?.content && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.front, 'logo') ? {
                ...product.front.logo,
                visible: product.front.logo.visible === false ? false : true,
                url: product.front.logo.url?.startsWith("https")
                  ? product.front.logo.url
                  : '',
                content: !product.front.logo.content?.startsWith("https")
                  ? product.front.logo.url
                  : '',
              } : null
            }
            : {},
          back: product?.layout?.layout_attributes?.back
            ? {
              name: product.back.name && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.back, 'name')
                ? {
                  ...product.back.name,
                  url: product.back.name?.stamp_url || ''
                }
                : {},
              number: product.back.number && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.back, 'number')
                ? {
                  ...product.back.number,
                  url: product.back.number?.stamp_url || ''
                }
                : {},
              logo: product.back.logo?.stamp_url || product.back.logo?.url || product.back.logo?.content && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.back, 'logo') ? {
                ...product.back.logo,
                visible: product.back.logo.visible === false ? false : true,
                url: product.back.logo.url?.startsWith("https")
                  ? product.back.logo.url
                  : '',
                content: !product.back.logo.content?.startsWith("https")
                  ? product.back.logo.url
                  : '',
              } : null
            }
            : {},
          side: product?.layout?.layout_attributes?.side
            ? {
              name: product.side.name && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.side, 'name')
                ? {
                  ...product.side.name,
                  url: product.side.name?.stamp_url || ''
                }
                : {},
              number: product.side.number && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.side, 'number')
                ? {
                  ...product.side.number,
                  url: product.side.number?.stamp_url || ''
                }
                : {},
              logo: product.side.logo?.stamp_url || product.side.logo?.url || product.side.logo?.content && {}.propertyIsEnumerable.call(product?.layout?.layout_attributes.side, 'logo') ? {
                ...product.side.logo,
                visible: product.side.logo.visible === false ? false : true,
                url: product.side.logo.url?.startsWith("https")
                  ? product.side.logo.url
                  : '',
                content: !product.side.logo.content?.startsWith("https")
                  ? product.side.logo.url
                  : '',
              } : null
            }
            : {},
        }

        const layoutAttributes = product?.layout?.layout_attributes

        let params = {
          alias: product.product?.alias || product.product?.styleId || product.product?.style_id,
          dealer_id: state.dealer.id,
          //Todo, ver de donde sacamos los colores de los sublimables
          //Temporalmente mandamos los colores del playbook, porque no hay ediciones individuales

          colors: product.product.is_sublimable
            ? [product.color_1, product.color_2, product.color_3]
            : [product.design.color_family],

          design: product.product.is_sublimable
            ? {
              id: product.design?.id,
              name: product.design?.name,
            }
            : {},

          decorations,
          gender: product.product.gender,
          isSublimable: product.product.is_sublimable,
          layout: product.layout.id,
          layout_attributes: layoutAttributes,
          name: product.product.name,
          product_id: product.product.id,
          price: product.product.price,
          styleId: product.product.style_id,
          variant: product.product.is_sublimable ? null : product.design?.color_name?.trim(),
        };

        if (lockerCode) {
          if (getters['lockerModule/isLockerLocked']) {
            this._vm.$toastr.e("This locker has been locked");
            return false;
          }
          params['locker_code'] = lockerCode;
        }

        if (!lockerCode) {
          params['project_id'] = router.currentRoute.params.showroom_id
          params['project_type'] = 'showroom'

        }

        axios.post(state.settings.endpoint + 'api/v2/lockers/item',
          params,
          {
            headers: {
              Authorization: 'Bearer ' + state.settings.token,
              'Content-Type': 'application/json',
            }
          },
        ).then(response => {
          lockerCode = response.data
          resolve()
        }).catch(() => reject())
      })
    }

    // Save the products in order. First, the main product, next the companion item
    productsToSave.reduce(async (previousPromise, nextProduct) => {
      try {
        await previousPromise;
        return saveToLocker(nextProduct);
      } catch (e) {
        
        Promise.reject()
        throw new Error('error saving product')
      }
    }, Promise.resolve())

      //Get locker once all the products have been saved
      .then(async () => {

        dispatch('lockerModule/getLockerByCode', lockerCode, { root: true })
          .then(dispatch('goToLocker'))

        //Aca por alguna razon se hace un redirect
        // if (locker.data && locker.data.items) {
        //   commit('completeUniform', {
        //     data: locker.data
        //   });
        // }
      })
      .catch(() => { 
        
        this._vm.$toastr.e("Error saving product");
      })
      .finally(() => {
        state.locked.event = false;
      })
  },

  //Call the API and retrieve the basic information
  async initizalizeState({ dispatch, commit }, queryDealerMode) {
    if (!(router.currentRoute.params.dealercode) && (!router.currentRoute.name === "SearchGuest")) {
      this.dispatch("redirectToPageWithParams", {
        name: "Home"
      });
    }
    else {

      let showroom_id = router.currentRoute.params.showroom_id;
      let isEmbedded = showroom_id ? false : true
      const embeddedEmail = router.currentRoute.query.email
      let basic_data_endpoint = isEmbedded ? 'api/v2/dealer-data' : `api/v2/showroom-data?team_link=${showroom_id}`

      if (isEmbedded) {
        commit('setAsEmbeddedShowroom')
      }

      //Get Showroom (Showroom-data or Dealer-data endpoints)
      const showroomData = await axios.get(state.settings.endpoint + basic_data_endpoint, {
        params: {
          dealer_code: router.currentRoute.params.dealercode,
          dealer_mode: queryDealerMode ? queryDealerMode : null,
          // catalog_ids: catalogParams,
          showroom_slug: router.currentRoute.params.showroom_slug
        }
      })

      const headerDisabled = isEmbedded ? !parseInt(router.currentRoute.query.header) : showroomData.data.dealer.settings.hide_dealer_header

      //GetCatalogs
      const catalogsIds = isEmbedded ? router.currentRoute.query["catalog_ids[]"] : showroomData.data.teamlink.selected_catalogs_ids || showroomData.data.teamlink.catalogs.map(catalog => catalog.id)
      await dispatch('getCatalogs', catalogsIds)

      commit("setInitialSettings", {
        colors: showroomData.data.colors,
        token: showroomData.data.dealer.api_token,
        dealer: showroomData.data.dealer,
        team_link: showroomData.data.teamlink,
        embeddedEmail,
        headerDisabled
      });

      //Request Locker
      if (showroomData.data.teamlink && showroomData.data.teamlink.locker) {
        dispatch('lockerModule/getLockerByCode', showroomData.data.teamlink.locker.code, { root: true })
          .catch(() => {
            this._vm.$toastr.e("Error in initState");
          });
      }

      //request team link mascot stamp
      if (showroom_id) {
        commit('setTeamName', { product_type: 'main', value: showroomData.data.teamlink.team_name });
      }

      commit("lockEvent");


      //request team link mascot stamp
      if (showroom_id) {
        commit('setTeamName', { product_type: 'main', value: showroomData.data.teamlink.team_name });
      }

      //update initial data
      const customerData = showroomData.data.teamlink?.customer2
      const name = showroomData.data.teamlink?.title
      const colors = [showroomData.data.teamlink?.color_id_1, showroomData.data.teamlink?.color_id_2, showroomData.data.teamlink?.color_id_3]
      const repId = showroomData.data.teamlink?.rep_id

      // If there is customer Data in the showroomData set it, otherwise create a new customer
      const setDataOrCreateCustomer = customerData && Object.keys(customerData).length
        ? () => dispatch('customer/initCustomer', customerData)
        : () => dispatch('customer/createCustomer', { name, colors, type: 'travel team', repId })

      setDataOrCreateCustomer().then(() => {
        dispatch("regenerateTextStamps", 'main_product');
        dispatch("regenerateTextStamps", 'companion_product');
        commit('unlockEvent')
        //end request stamps
      })
        .catch(() => {
          this._vm.$toastr.e("Error in setDataOrCreateCustomer");
          this.dispatch("redirectToPageWithParams", {
            name: "NotFound"
          });
        });

    }

    commit("unlockStates", ["event"]);
    commit('updateGenders')


  },

  //Gets a product-catalogue and requests its text-stamp data
  // async getProductsBySport({ commit }, sport) {
  //   if (sport) {
  //     this.state.catalogs = [];
  //     commit("lockStates", ["event"]);
  //     const showroomData = await axios.get(state.settings.endpoint + 'api/v2/catalog?sport=' + sport, {
  //       headers: {
  //         Authorization: 'Bearer ' + state.settings.token
  //       },
  //       params: {
  //         fill: getters.getColorById(state.main_product.color_2).hex,
  //         outline: getters.getColorById(state.main_product.color_3).hex,
  //         team_name: this.state.main_product.front.name.value,
  //         player_name: this.state.main_product.back.name.value,
  //         number: this.state.main_product.front.number.value,
  //         font: 'block'
  //       }
  //     });

  //     commit("updateProductCards", showroomData.data);
  //     commit("unlockStates", ["event"]);
  //   }
  // },

  updateArtwork({ commit }, data) {
    commit('setArtwork', data);
  },

  async requestTextStamp({ commit }, data) {
    //Request a new text stamp file for names or numbers
    let defaultValue = "Team name";
    const response = await axios.get(this.state.settings.endpoint + '/api/v2/text-stamp', {
      headers: {
        Authorization: 'Bearer ' + this.state.settings.token
      },
      params: {
        fill: data.fill,
        outline: data.outline,
        value: data.value.trim().length > 0 ? data.value : defaultValue,
        font: this.state.fonts[data.font - 1].file,
      }
    });
    commit('setStampData', {
      product_type: data.product_type,
      side: data.side,
      field: data.field,
      property: 'stamp_url',
      url: response.data
    });
  },

  async setDefaultLogo({ commit }, data) {
    const incompleteLogoURL = 'https://s3.amazonaws.com/marketing.foundersportgroup.com/'
    const isLogoAnIncompleteURL = this.state.team_link?.logo_converted === incompleteLogoURL || this.state.team_link?.logo_original === incompleteLogoURL
    if (data.logoRemoved || !this.state.team_link || !this.state.team_link?.logo_converted || isLogoAnIncompleteURL) {

      commit('updateSubStateField', {
        state: data.product_type,
        substate: this.state[this.state.activeProduct].reversibleActive ? "reversible_" + data.side : data.side,
        field: data.field,
        value: {
          url: '',
          original_url: ''
        }
      });
    }
    else { //set team link logo as default
      commit('updateSubStateField', {
        state: data.product_type,
        substate: data.side,
        field: data.field,
        value: {
          url: this.state.team_link?.logo_converted,
          original_url: this.state.team_link?.logo_original,
        }
      });
    }
  },

  async getCompanionItems({ state, commit }) {
    // if (!state.settings.token) return false;
    state.locked.event = true;
    const response = await axios.get(state.settings.endpoint + '/api/v2/companion-items', {
      headers: {
        Authorization: 'Bearer ' + state.settings.token
      },
      params: {
        cid: state.activeProductCatalogId,
        product_id: this.state.main_product.product.id,
        fill: CONSTANTS.DEFAULT.FILL,
        outline: CONSTANTS.DEFAULT.OUTLINE,
        team_name: this.state.main_product.front.name ? this.state.main_product.front.name.value : 'Team Name',
        player_name: this.state.main_product.back.name ? this.state.main_product.back.name.value : 'Player Name',
        number: this.state.main_product.front.number ? this.state.main_product.front.number.value : '00',
        font: state.fonts[0].file,
      }
    });
    commit("setCompanionItems", response.data);
  },

  updateColor({ commit }, data) {
    let colors = state.colors;
    for (let i = 0; i < colors.length; i++) {
      const element = colors[i];
      if (element.name === data.color.name) {
        commit("setColor", { id: element.id, field: data.field, product_type: data.product_type, side: data.side, subfield: data.subfield });
      }
    }
  },

  async getCatalogs({ state, dispatch, commit }, catalogsIds) {
    try {
      commit('SET_LOADING_CATALOGS', true)
      const getCatalogsURL = state.settings.endpoint + 'api/v2/catalogs'

      //Get the selected catalogs ids from
      //1) the url (if there aren't any ids in the url, just send an UNDEFINED and the backend will send all the catalogs)
      //2) the selected Ids coming from the showroom data (teamlink/standalone showroom)
      //3)The Id of the selected catalogs from the Catalogue Sidebar
      const catalogsResponse = await axios.get(getCatalogsURL, {
        params: {
          selected: catalogsIds,
        }
      })

      const newCatalogs = catalogsResponse.data.map(cat => {
        return {
          ...cat,
          selected: cat.products?.length ? true : false
        }
      })

      //Check if we have to init Catalog's state or update it
      if (state.catalogs.length > 0) {

        const newCatalog = newCatalogs.filter(catalogs => catalogs.products?.length > 0)[0]
        await dispatch('updateCatalog', newCatalog)

      } else {
        //init the catalogs data
        commit('SET_CATALOGS', newCatalogs)
        //If all the catalogs are empty, we are in an embbeded like TeamExpress, so set all the catalogs as Home Catalogs
        //Else, set as Home catalogs only wich that came in the url as params
        const selectedCatalogs = newCatalogs.filter(cat => cat.selected)
        const homeCatalogsIds = selectedCatalogs.length === 0 ? newCatalogs.map(cat => cat.id) : selectedCatalogs.map(cat => cat.id)
        commit('SET_HOME_CATALOGS_IDS', homeCatalogsIds)
      }
    } catch (error) {
      this._vm.$toastr.e("Error trying to get catalogs");
    } finally {
      commit('SET_LOADING_CATALOGS', false)
    }
  },

  async updateCatalog({ state, dispatch, commit }, newCatalog) {
    const updatedCatalogs = state.catalogs.map(catalog => {
      if (newCatalog.id == catalog.id) {
        return newCatalog
      } else {
        return catalog
      }
    })

    commit('SET_CATALOGS', updatedCatalogs)

    //Save the changes in the db
    try {
      await dispatch('saveUpdatedCatalogs')
    } catch (error) {
      throw new Error(error)
    }
  },

  async selectCatalog({ commit, dispatch, getters }, id) {
    if (getters.getSelectedCatalogsIds.includes(id)) {
      commit("unselectCatalog", id);

      //Save changes
      await dispatch('saveUpdatedCatalogs')

    } else {
      commit("selectCatalog", id);

      //If the catalog is not loaded, fetch its information. With this approach we will prevent duplicated calls to the API.
      //Just load catalogs one by one and save them in the state.

      if (!getters.isCatalogLoaded(id)) {
        await dispatch('getCatalogs', id)
      }
    }


  },

  async saveUpdatedCatalogs({ getters }) {
    try {
      const actualPageIsTheHomepage = router.currentRoute.name === "Catalogue" || router.currentRoute.name === "CatalogueWithTeamLink"
      const url = state.settings.endpoint + '/api/v2/team-link'

      const params = {
        id: state.team_link.id,
        catalog_ids: getters.getSelectedCatalogsIds
      }

      let config = {
        headers: {
          Authorization: 'Bearer ' + state.settings.token
        },
      }

      if (actualPageIsTheHomepage && !state.isAnEmbeddedShowroom && (!getters['actionsPermissions/isProjectLocked'])) {
        await axios.put(url, params, config)
      }


    } catch (error) {
      throw new Error(error)
    }
  },

  async saveUpdatedColorsAndTeamName({ state, rootGetters }) {
    try {
      const url = state.settings.endpoint + '/api/v2/team-link'
      const actualPageIsTheHomepage = router.currentRoute.name === "Catalogue" || router.currentRoute.name === "CatalogueWithTeamLink"

      const { color_1, color_2, color_3 } = state.main_product
      const teamName = state.main_product.front.name.value
      const params = {
        id: state.team_link.id,
        color_id_1: color_1,
        color_id_2: color_2,
        color_id_3: color_3,
        color_1: color_1,
        color_2: color_2,
        color_3: color_3,
        team_name: teamName
      }

      let config = {
        headers: {
          Authorization: 'Bearer ' + state.settings.token
        },
      }

      if (actualPageIsTheHomepage && !state.isAnEmbeddedShowroom && !rootGetters['actionsPermissions/isProjectLocked']) {
        await axios.put(url, params, config)
      }
    } catch {
      this._vm.$toastr.e("Error trying to save the updated colors");
    }
  },

  async updateShowroomStatus({ state, commit }, status) {
    try {

      const url = state.settings.endpoint + '/api/v2/team-link'

      const params = {
        id: state.team_link.id,
        status
      }

      let config = {
        headers: {
          Authorization: 'Bearer ' + state.settings.token
        },
      }

      await axios.put(url, params, config)

      commit('SET_SHOWROOM_STATUS', status)

    } catch (error) {
      throw new Error(error)
    }
  }

};

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    breadcrumbs, customer, project, screenLogo, actionsPermissions
  },

});


export default store;