const EMPTY_SVG_GROUPS = { group1: [], group2: [], group3: [] };
const BACKUP_STOCK_COLOR_IDS = [22, 25, 23, 24];

const COLORS = [
  {
    "id": 5,
    "name": "PINK",
    "slug": "pink",
    "code": null,
    "palette": "sublimable",
    "hex": "#E365A6",
    "default": null,
    "playbook_match_color_id": 1,
    "family_name": "pink"
  },
  {
    "id": 6,
    "name": "SCARLET",
    "slug": "scarlet",
    "code": null,
    "palette": "sublimable",
    "hex": "#B9121D",
    "default": 1,
    "playbook_match_color_id": 2,
    "family_name": "red"
  },
  {
    "id": 7,
    "name": "CARDINAL",
    "slug": "cardinal",
    "code": null,
    "palette": "sublimable",
    "hex": "#711A27",
    "default": null,
    "playbook_match_color_id": 3,
    "family_name": "cardinal"
  },
  {
    "id": 8,
    "name": "MAROON",
    "slug": "maroon",
    "code": null,
    "palette": "sublimable",
    "hex": "#501417",
    "default": null,
    "playbook_match_color_id": 4,
    "family_name": "maroon"
  },
  {
    "id": 9,
    "name": "ELECTRIC-PINK",
    "slug": "electric-pink",
    "code": null,
    "palette": "sublimable",
    "hex": "#DE2776",
    "default": null,
    "playbook_match_color_id": 5,
    "family_name": "electric pink"
  },
  {
    "id": 10,
    "name": "ORANGE",
    "slug": "orange",
    "code": null,
    "palette": "sublimable",
    "hex": "#F45C08",
    "default": null,
    "playbook_match_color_id": 6,
    "family_name": "orange"
  },
  {
    "id": 11,
    "name": "TEXAS",
    "slug": "texas",
    "code": null,
    "palette": "sublimable",
    "hex": "#AF6317",
    "default": null,
    "playbook_match_color_id": 7,
    "family_name": "texas"
  },
  {
    "id": 12,
    "name": "OLD-GOLD",
    "slug": "old-gold",
    "code": null,
    "palette": "sublimable",
    "hex": "#CF9128",
    "default": null,
    "playbook_match_color_id": 8,
    "family_name": "old gold"
  },
  {
    "id": 13,
    "name": "LIGHT-GOLD",
    "slug": "light-gold",
    "code": null,
    "palette": "sublimable",
    "hex": "#F6CD0B",
    "default": null,
    "playbook_match_color_id": 9,
    "family_name": "gold"
  },
  {
    "id": 14,
    "name": "VEGAS-GOLD",
    "slug": "vegas-gold",
    "code": null,
    "palette": "sublimable",
    "hex": "#D6BD6C",
    "default": null,
    "playbook_match_color_id": 10,
    "family_name": "vegas"
  },
  {
    "id": 15,
    "name": "DARK-GREEN",
    "slug": "dark-green",
    "code": null,
    "palette": "sublimable",
    "hex": "#0F2B03",
    "default": null,
    "playbook_match_color_id": 11,
    "family_name": "forest"
  },
  {
    "id": 16,
    "name": "KELLY-GREEN",
    "slug": "kelly-green",
    "code": null,
    "palette": "sublimable",
    "hex": "#396C27",
    "default": null,
    "playbook_match_color_id": 12,
    "family_name": "kelly"
  },
  {
    "id": 17,
    "name": "TEAL",
    "slug": "teal",
    "code": null,
    "palette": "sublimable",
    "hex": "#187269",
    "default": null,
    "playbook_match_color_id": 13,
    "family_name": "teal"
  },
  {
    "id": 18,
    "name": "COLUMBIA",
    "slug": "columbia",
    "code": null,
    "palette": "sublimable",
    "hex": "#7DA1D4",
    "default": null,
    "playbook_match_color_id": 14,
    "family_name": "columbia"
  },
  {
    "id": 19,
    "name": "ROYAL",
    "slug": "royal",
    "code": null,
    "palette": "sublimable",
    "hex": "#21368C",
    "default": 2,
    "playbook_match_color_id": 15,
    "family_name": "royal"
  },
  {
    "id": 20,
    "name": "NAVY",
    "slug": "navy",
    "code": null,
    "palette": "sublimable",
    "hex": "#10103A",
    "default": null,
    "playbook_match_color_id": 16,
    "family_name": "navy"
  },
  {
    "id": 21,
    "name": "PURPLE",
    "slug": "purple",
    "code": null,
    "palette": "sublimable",
    "hex": "#5E1D78",
    "default": null,
    "playbook_match_color_id": 17,
    "family_name": "purple"
  },
  {
    "id": 22,
    "name": "SILVER",
    "slug": "silver",
    "code": null,
    "palette": "sublimable",
    "hex": "#7A7774",
    "default": null,
    "playbook_match_color_id": 18,
    "family_name": "graphite"
  },
  {
    "id": 23,
    "name": "BLACK",
    "slug": "black",
    "code": null,
    "palette": "sublimable",
    "hex": "#202020",
    "default": null,
    "playbook_match_color_id": 19,
    "family_name": "black"
  },
  {
    "id": 24,
    "name": "GREY",
    "slug": "grey",
    "code": null,
    "palette": "sublimable",
    "hex": "#C6C6C6",
    "default": null,
    "playbook_match_color_id": 20,
    "family_name": "silver"
  },
  {
    "id": 25,
    "name": "WHITE",
    "slug": "white",
    "code": null,
    "palette": "sublimable",
    "hex": "#FFFFFF",
    "default": 3,
    "playbook_match_color_id": 21,
    "family_name": "white"
  }
]

const DEFAULT_LOGO_URL = '/images/default-logo.png'

const LAYER = {
  SIDES: ["front", "side", "back"],
  MAIN_SIDES: ["front", "side"], //sets team name front for most of jerseys and side for masks
  FIELDS: ["name", "number", "logo"], //artwork?
  MAIN_FIELDS: ["name", "number"],
};

const DEFAULT = {
  FILL: "#FFFFFF",
  OUTLINE: "#111111",
  FONT: "block",
  FILL_ID: 25,
  OUTLINE_ID: 23,
  C1: 19,
  C2: 13,
  C3: 25,
};

const DEFAULT_SCREEN_LOGO_PROPERTIES = {
  position: 2,
  zoom: 4
}

const DEFAULT_PRODUCT_DATA = {
  color_1: DEFAULT.C1,
  color_2: DEFAULT.C2,
  color_3: DEFAULT.C3,
  layout: {},
  design: {},
  product: {},
  front: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Team name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Team Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    logo: {
      url: DEFAULT_LOGO_URL,
      original_url: '',
    }
  },
  back: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Player name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Player Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    logo: {
      url: DEFAULT_LOGO_URL,
      original_url: '',
    }
  },
  side: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Team name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"Team name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
    },
    logo: {
      url: '',
      original_url: '',
    }
  },
}

const GAMEPLAN_SESSION = 'gameplan-session'

const PROJECT_STATUS = {
  OMG_STATUS: 'OMG Store Requested',
  QUOTE_STATUS: 'quote',
  CLOSED_STATUS: 'closed',
  OPEN_STATUS: 'open'
}

export default {
  EMPTY_SVG_GROUPS,
  BACKUP_STOCK_COLOR_IDS,
  COLORS,
  GAMEPLAN_SESSION,
  LAYER,
  DEFAULT,
  DEFAULT_SCREEN_LOGO_PROPERTIES,
  DEFAULT_PRODUCT_DATA,
  DEFAULT_LOGO_URL,
  PROJECT_STATUS
}