<template>
  <div
    class="companion-item"
    @click="item ? setActive() : ''"
    v-bind:class="{ active: isActive, 'main-item': !item }"
    v-if="is_sublimable || filteredStockVariants"
  >
    <div class="companion-item-content">
      <div class="checkbox-container" v-if="this.item && isActive">
        <input type="checkbox" :checked="isActive" disabled />
        <span class="checkmark"></span>
      </div>
      <Vector
        :side="'front'"
        :svgs="svgs"
        :backgrounds="backgrounds"
        :layout="layout"
        :is_stock="!is_sublimable"
        :product_type="product_type"
        :isVariantStock="isVariantStock"
      />
      <div v-if="this.item" class="item-info">
        <div class="top-container">
          <p v-if="isVariantStock && item.variants">More colors available</p>
        </div>
        <div class="bottom-container">
          <h3>{{ item.name }}</h3>
          <div class="flex justify-space-between">
            <h6 v-if="sizes" class="size">
              {{ this.sizes[0] }}-{{ this.sizes[this.sizes.length - 1] }}
            </h6>
            <h6 class="price" v-if="!hidePrice">MRSP: ${{ item.price }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";
import { mapGetters } from "vuex";

import Vector from "../Vector";
export default {
  name: "CompanionItem",
  props: ["item", "isActive", "filteredTargetColors", "hidePrice"],
  components: {
    Vector,
  },
  data() {
    return {
      variantStockDesignSelected: 0,
    }
  },
  computed: {
    ...mapGetters(["getColorByField"]),
    isVariantStock() {
      if (this.item) {
        return this.item.variants ? this.item.variants.length > 0 : false;
      } else {
        return (
          this.$store.state.main_product.product.variants &&
          this.$store.state.main_product.product.variants.length > 0
        );
      }
     // return !this.item.is_sublimable; this was used when api did also return null variant items
    },
    svgs() {
      if (this.item) {
        if (this.item.is_sublimable) {
          return this.item.design.svg_data.front;
        } else {
          return { group1: [], group2: [], group3: [] };
        }
      } else {
        return this.$store.state.main_product.product.is_sublimable
          ? this.$store.state.main_product.product.designs_by_gender[
              this.genderName
            ][0].svg_data.front
          : { group1: [], group2: [], group3: [] };
      }
    },
    designs() {
      return this.item.designs;
    },
    filteredStockVariants() {
      let filteredVariants = [];
      if (!this.isVariantStock || !this.item.variants) return false;
      for (
        let colorIndex = 0;
        colorIndex < this.filteredTargetColors.length;
        colorIndex++
      ) {
        const colorId = this.filteredTargetColors[colorIndex];
        for (let index = 0; index < this.item.variants.length; index++) {
          const variant = this.item.variants[index];
          if (variant.color_family === colorId) {
            filteredVariants.push(variant);
            break;
          }
        }
      }
      return filteredVariants.length ? filteredVariants : this.item.variants;
    },
    backgrounds() {
      if (this.item) {
        if (this.item.is_sublimable) {
          return this.item.backgrounds;
        } else {
          //return this.item.variants[0].backgrounds;
          //todo optimize this part
        if (!this.variantStockDesignSelected && this.item.variants) {
          //get closest matching family color
          for (let colorIndex = 1; colorIndex <= 3; colorIndex++) {
            //starting from c1
            const colorId = this.getColorByField({
              //get c1-3 id
              product_type: 'main',
              field: "color_" + colorIndex,
            }).id;
            for (
              let variantIndex = 0;
              variantIndex < this.item.variants.length;
              variantIndex++
            ) {
              const variant = this.item.variants[variantIndex]; //search through all variations
              if (variant.color_family === colorId) {
                //if it matches the current colorid, we show it
                //this.variantStockDesignSelected = variantIndex;
                return this.item.variants[variantIndex].backgrounds;
              }
            }
          }
          //no matching variant for c1-3, so we search for white, black and silver, in that order
          for (
            let variantIndex = 0;
            variantIndex < this.item.variants.length;
            variantIndex++
          ) {
            const variant = this.item.variants[variantIndex]; //search through all variations
            for (
              let backupColorIndex = 0;
              backupColorIndex < CONSTANTS.BACKUP_STOCK_COLOR_IDS.length;
              backupColorIndex++
            ) {
              const backupColorId =
                CONSTANTS.BACKUP_STOCK_COLOR_IDS[backupColorIndex];
              if (variant.color_family === backupColorId) {
              //  this.variantStockDesignSelected = variantIndex;
                return this.item.variants[variantIndex].backgrounds;
              }
            }
          }
        }
        return this.filteredStockVariants &&
          this.filteredStockVariants[this.variantStockDesignSelected]
          ? this.filteredStockVariants[this.variantStockDesignSelected]
              .backgrounds
          : []; //show nothing, something is wrong, maybe missing color family
        }
      } else {
        return this.$store.state.main_product.product.is_sublimable
          ? this.$store.state.main_product.product.backgrounds //return product backgrounds
          : this.$store.state.main_product.design.backgrounds;
      }
    },
    layout() {
      if (this.item) { //companion items shown in modal are items
        if (this.item.layouts) {
          return this.item.layouts[0];
        } else {
          return {
            layout_attributes: {},
          };
        }
      } else { //the main prod shown in modal is not counted as an item
        return this.$store.state.main_product.layout;
      }
    },
    product_type() {
      return this.item ? "companion" : "main";
    },
    genderName() {
      return this.$store.state.gender;
    },
    sizes() {
      let gender = this.$store.state.gender;
      return this.item.sizes ? this.item.sizes[gender] : null;
    },
    is_sublimable() {
      return !this.item ? !this.item : !this.item.variants;
    },
    event_loading() {
      return this.$store.state.locked.event;
    },
  },
  methods: {
    setActive() {
      if (!this.event_loading) {
        this.$emit("companionActive", {
          item: this.item,
          variantStockDesignSelected: this.isVariantStock ? this.filteredStockVariants[this.variantStockDesignSelected] : null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .companion-item {
    height: 280px;
    padding: 8px 6.5px;
    width: 185px !important;
    cursor: pointer;
    outline: none;
    .companion-item-content {
      height: 100%;
      border: 1px solid $lighterGray;
      border-radius: 6px;
      position: relative;
      .checkbox-container {
        position: absolute;
        top: 5px;
        right: 28px;
      }
      ::v-deep .product-image {
        display: flex;
        justify-content: center;
        align-content: center;
        height: 160px;
        padding: 12px 12px 0;
        svg {
          height: 100%;
        }
      }
      .top-container {
        background-color: $whiteGray;
        height: 30px;
        padding: 9px 12px;
        border-bottom: 1px solid $lighterGray;
        p {
          color: $black;
          font-size: 11px;
          line-height: 12px;
        }
      }
      .bottom-container {
        padding: 8px 12px;
        h3 {
          color: $darkerGray;
          line-height: 18px;
          letter-spacing: 0;
          text-transform: uppercase;
          margin-bottom: 7px;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 36px;
        }
        h6 {
          &.size {
            color: $darkGray;
          }
          &.price {
            font-family: $ubuntuLight;
            color: $blackGray;
          }
        }
      }
    }
    &.main-item {
      width: 173px;
      cursor: initial;
      padding: 8px 0;
      .companion-item-content {
        background-color: $whiteGray;
        ::v-deep .product-image {
          height: 100%;
        }
      }
    }
    &.active {
      cursor: initial;
      .companion-item-content {
        border-color: $black;
        box-shadow: 0 3px 6px rgba($black, 0.61);
      }
    }
  }
}
</style>