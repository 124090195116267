<template>
  <div v-show="shouldShowCardInHomepage(id)">
    <label
      class="sport-card flex-column flex align-center justify-end box-shadow"
      :class="{
        active: isSportActive,
      }"
      :for="sport.name"
    >
      <div class="icon flex" v-html="sport.svg_icon"></div>
      <input
        class="d-none"
        type="checkbox"
        :checked="isSportActive"
        :value="sport.id"
        @change="handleSelectCatalog"
        :name="sport.name"
        :id="sport.name"
      />
      <span>{{ sport.name }}</span>
    </label>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SportCard",
  props: ["sport", "id"],
  computed: {

    ...mapGetters(["getSelectedCatalogsIds", "shouldShowCardInHomepage"]),

    isSportActive() {
      return this.sport.selected;
    },
  },
  methods: {
    ...mapActions(["selectCatalog"]),
    handleSelectCatalog() {
      this.selectCatalog(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .sport-card {
    width: 57px;
    height: 53px;
    background: $white;
    border: 2px solid $white;
    border-radius: 3px;
    margin: 5px;
    padding: 2px;

    cursor: pointer;
    .icon {
      ::v-deep svg {
        width: 23.617px;
        height: 23.617px;
        path,
        rect,
        line,
        ellipse {
          stroke: $black;
          opacity: 0.3;
        }
      }
    }

    span {
      color: $black;
      font-family: $ubuntuBold;
      font-size: 8px;
      line-height: 9px;
      text-transform: uppercase;
      opacity: 0.3;
      margin-top: 5px;
    }
    &:not(.active):hover {
      background: $black;
      border-color: $black;
      span {
        color: $white;
        opacity: 1;
      }
      ::v-deep svg {
        fill: $white;
        path,
        rect,
        line,
        ellipse {
          stroke: $white;
          opacity: 1;
        }
      }
    }
    &.active {
      border-color: $black;
      span {
        opacity: 1;
      }
      ::v-deep svg {
        path,
        rect,
        line,
        ellipse {
          opacity: 1;
        }
      }
    }
    &.active:hover {
      border-color: #c2c2c2;
    }
  }
}
</style>