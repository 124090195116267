<template>
  <div class="logo-layout-component">
    <div class="layout-row-container layout-logo">
      <div class="layoyt-heading flex justify-space-between align-center">
        <h3>LOGO ({{ layerSide }})</h3>
      </div>

      <div
        class="
          layout-row
          flex
          align-center
          justify-space-between
          flex-column flex-lg-row
        "
      >
        <!-- Logo image -->
        <ScreenLogoPreview
          :showLogoCondition="activeScreenLogo && activeScreenLogo.id"
          :logo="activeScreenLogo"
          @handleReplaceOrDeleteButton="handleReplaceOrDeleteButton"
        />

        <!-- Logo size -->
        <div class="logo-size" v-show="false && !hideLogoSize">
          <p>Choose Logo Size</p>
          <v-select
            v-model="logo_option_selected"
            :options="logo_options"
            :clearable="false"
            :searchable="false"
            class="box-shadow logo-size-input"
          ></v-select>
        </div>

        <div class="vertical-line" v-show="false && !hideLogoSize"></div>

        <!-- Not vector message -->
        <div class="not-vector-container">
          <div class="bubble">
            <p>
              Unfortunately, the logo you uploaded does not meet the quality
              requirements. Please allow an extra 48 Hours of production time
              for our team of artists to vectorize this logo for production.
            </p>
          </div>
          <p class="color-red" v-if="!isVector">
            NOT VECTOR
            <br />+48HS PROD
          </p>
        </div>

        <!-- Replace / Edit -->
        <!-- <p class="text-underline pointer" @click="removeCurrentLogo">Replace or delete logo</p> -->

        <div class="col-6">
          <template v-if="isAnEmbeddedShowroom">
            <!-- Upload -->
            <UploadLogoButton
              v-show="!logoSrc"
              :graphicButtonHint="`Upload your ${layerSide} graphic`"
              :refName="'fileToSave'"
              @uploadingLogo="handleUploadingLogo"
              @logoHasBeenUploaded="handleLogoHasBeenUploaded"
              v-on="{ saveEditedLogo }"
            />

            <button
              v-if="logoSrc"
              @click="handleReplaceOrDeleteButton"
              class="swap-graphic-button mb-3"
            >
              <SwapIconSVG /> <span>Swap Graphic</span>
            </button>

            <button
              v-if="logoSrc"
              @click="openEditScreenLogoModal"
              class="edit-graphic-button mb-3"
            >
              Resize / Move
            </button>
          </template>

          <template v-else-if="logos.length">
            <button
              @click="handleReplaceOrDeleteButton"
              class="swap-graphic-button mb-3"
            >
              <SwapIconSVG /> <span>Swap Graphic</span>
            </button>

            <button
              v-if="logoSrc"
              @click="openEditScreenLogoModal"
              class="edit-graphic-button mb-3"
            >
              Resize / Move
            </button>
          </template>
        </div>

        <EditScreenLogoModal
          v-show="showEditScreenLogoModal"
          :refName="'edit-logo-position'"
          @applyScreenLogo="handleApplyScreenLogo"
          @close="handleCloseEditScreenLogoModal"
        />

        <ManageLogosModal
          v-show="showManageLogosModal"
          :preselectedLogoId="preselectedLogoId"
          :uploadedLogos="logos"
          :MAX_LOGOS_ALLOWED="1"
          :refName="'fileToSave'"
          @openCropperJsToEditLogo="openCropperJsToEditLogo"
          @changeSelectedLogo="changeSelectedLogo"
          @removeLogo="removeLogo"
          @closeModal="showManageLogosModal = false"
          @uploadingLogo="handleUploadingLogo"
          v-on="{ saveEditedLogo }"
          @logoHasBeenUploaded="handleLogoHasBeenUploaded"
        />

        <UploadLogoModal
          v-if="showUploadLogoModal"
          :fileUploaded="fileUploaded"
          :fileName="fileName"
          :fileSize="fileSize"
          :fileExtension="fileExtension"
          :fileOriginal="fileOriginal"
          :fullScreen="true"
          :customerId="customerId"
          :logoIdToBeEdited="logoIdToBeEdited"
          :editableLogo="editableLogo"
          :typesOfLogoToBeEdited="typesOfLogoToBeEdited"
          :isManageModalOpen="showManageLogosModal"
          @saveEditedLogo="saveEditedLogo"
          @updateLogoSrc="updateLogoSrc"
          @close="showUploadLogoModal = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import CONSTANTS from "@/constants.js";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import EditScreenLogoModal from "@/components/Logos/EditScreenLogoModal";
import ManageLogosModal from "@/components/Logos/ManageLogosModal";
import ScreenLogoPreview from "@/components/Logos/ScreenLogoPreview";
import UploadLogoButton from "@/components/Logos/UploadLogoButton";
import UploadLogoModal from "@/components/Logos/UploadLogoModal";
import SwapIconSVG from "@/components/Logos/SwapIconSVG.vue";
export default {
  name: "LogoLayout",
  props: ["hideLogoSize", "active_layout", "layerSide", "index", "productType"],

  data() {
    return {
      logo_options: ["12 x 14", "12 x 15"],
      logo_option_selected: null,

      file: "",
      showUploadLogoModal: false,
      showCompanionItemsModal: false,
      fileUploaded: "",
      fileOriginal: "",
      fileName: "",
      fileExtension: "",
      fileSize: "",

      //Logo data
      preselectedLogoId: 1,

      logoIdToBeEdited: "",
      editableLogo: "",
      originalEditableLogo: "",
      typesOfLogoToBeEdited: [],
      screenLogoProperties: { zoom: 4, position: 4 },

      //show manage logo
      showManageLogosModal: false,
      showEditScreenLogoModal: false,
    };
  },

  computed: {
    ...mapGetters(["activeProduct"]),
    ...mapGetters({
      getTeamLink: "getTeamLink",
    }),
    ...mapGetters("customer", ["getLogoById"]),

    ...mapState([
      "customerId",
      "dealerMode",
      "team_link",
      "isAnEmbeddedShowroom",
      "activeScreenLogo", // --> This will be the Source of the logo
    ]),
    ...mapState("customer", ["logos"]),

    reversibleActive() {
      return this.activeProduct.reversibleActive;
    },
    sideKey() {
      return this.reversibleActive
        ? "reversible_" + this.layerSide
        : this.layerSide;
    },
    isVector() {
      return ["ai", "svg", "eps"].includes(this.fileExtension);
    },
    logoSrc() {
      if (this.activeProduct[this.sideKey].logo.original_url) {
        return this.activeProduct[this.sideKey].logo?.url?.indexOf(".com") !==
          -1 ||
          this.activeProduct[this.sideKey].logo?.url?.indexOf(
            "data:image/png;base64,"
          ) !== -1
          ? this.activeProduct[this.sideKey].logo?.url
          : this.activeProduct[this.sideKey].logo?.url;
      }
      return false;
    },

    logo() {
      return this.getTeamLink?.logo_converted?.indexOf(".com") !== -1 ||
        this.getTeamLink?.logo_converted?.indexOf("data:image/png;base64,") !==
          -1
        ? this.getTeamLink?.logo_converted
        : this.getTeamLink?.logo_converted;
    },

    originalLogo() {
      return this.getTeamLink?.logo_original;
    },
  },

  methods: {
    ...mapActions(["setDefaultLogo"]),
    ...mapActions("screenLogo", ["setScreenLogoProperties", "setApiLoading"]),
    ...mapActions("customer", [
      "addNewLogo",
      "updateLogo",
      "updateShowroomLogo",
    ]),

    ...mapMutations(["setNewActiveScreenLogo"]),

    handleReplaceOrDeleteButton() {
      // if (this.dealerMode && !this.isAnEmbeddedShowroom) {
      if (!this.isAnEmbeddedShowroom) {
        this.showManageLogosModal = true;
      } else {
        this.removeLogo();
        const uploadButton = this.$children.filter(
          (child) => !!child.$refs.fileToSave
        )[0];

        uploadButton.$refs.fileToSave.click();
      }
    },

    updateLogoSrc({ fileUploaded, typeOfUpgrade = "originalLogo" }) {
      this.showUploadLogoModal = false;

      //update team link urls
      this.$store.commit("updateSubState", {
        state: "team_link",
        substate: "logo_converted",
        value: fileUploaded,
      });

      if (typeOfUpgrade === "originalLogo") {
        this.$store.commit("updateSubState", {
          state: "team_link",
          substate: "logo_original",
          value: fileUploaded,
        });
      }

      //upload after call to setDefaultLogo for both prods, uses team link logo
      let layersides = CONSTANTS.LAYER.SIDES;
      for (let index = 0; index < layersides.length; index++) {
        const layerside = layersides[index];
        this.setDefaultLogo({
          product_type: "main_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });

        this.setDefaultLogo({
          product_type: "companion_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });
      }
    },

    async changeSelectedLogo(selectedLogoId) {
      if (selectedLogoId) {
        const logo = this.getLogoById(selectedLogoId);
        //Update the active screen logo.
        this.setNewActiveScreenLogo({
          logo: logo.fileUploaded,
          id: selectedLogoId,
        });
        await this.updateLogoSrc({ fileUploaded: logo.fileUploaded });
        this.logoIdToBeEdited = selectedLogoId;

        this.setScreenLogoProperties({
          originalGraphic: logo.content,
          position:
            this.position || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
          zoom: this.zoom || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
        });
      }
      this.showManageLogosModal = false;
      this.edited = true;
    },

    // async changeSelectedLogo(selectedLogoId) {
    //   try {
    //     this.loading = true;
    //     this.showManageLogosModal = false;
    //     if (selectedLogoId) {
    //       this.logoIdToBeEdited = selectedLogoId;
    //       await this.updateShowroomLogo({
    //         logoId: selectedLogoId,
    //         typeOfLogo: this.typeOfLogo,
    //       });
    //     }
    //     this.edited = true;
    //     this.loading = false;
    //   } catch (error) {
    //     this.loading = false;
    //   }
    // },

    removeLogo() {
      this.logoIdToBeEdited = "";
      this.showManageLogosModal = false;
      this.edited = true;

      this.$store.commit("updateSubState", {
        state: "team_link",
        substate: "logo_converted",
        value: "",
      });

      this.$store.commit("updateSubState", {
        state: "team_link",
        substate: "logo_original",
        value: "",
      });

      //upload after call to setDefaultLogo for both prods, uses team link logo
      let layersides = CONSTANTS.LAYER.SIDES;
      for (let index = 0; index < layersides.length; index++) {
        const layerside = layersides[index];
        this.setDefaultLogo({
          product_type: "main_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });

        this.setDefaultLogo({
          product_type: "companion_product",
          side: layerside,
          field: "logo",
          logoRemoved: false,
        });
      }

      this.setNewActiveScreenLogo({});
    },

    handleUploadingLogo(file) {
      this.showUploadLogoModal = true;
      // this.showManageLogosModal = false;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.file = file;
      this.fileName = this.file.name;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    },

    handleLogoHasBeenUploaded(newLogo) {
      this.fileUploaded = newLogo.fileUploaded;
      this.fileOriginal = newLogo.fileOriginal;

      this.logoIdToBeEdited = newLogo.id;
      this.preselectedLogoId = newLogo.id;
      this.editableLogo = newLogo;
      this.originalEditableLogo = newLogo;
      this.typesOfLogoToBeEdited = newLogo.typeOfLogo ? newLogo.typeOfLogo : [];
      this.addNewLogo(newLogo);
    },

    openEditScreenLogoModal() {
      this.showEditScreenLogoModal = true;

      // const { zoom, position } = this.screenLogoProperties;

      // this.setScreenLogoProperties({
      //   originalGraphic: this.logo, // loogscr es el logo editado. hay que pasar original logo, pero por alguna razon esta quedando viejo/desactrualizado
      //   zoom,
      //   position,
      // });
    },

    handleCloseEditScreenLogoModal() {
      this.showEditScreenLogoModal = false;
      this.setScreenLogoProperties({
        originalGraphic: null,
        position:
          this.logo?.screenLogoProperties?.position ||
          CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
        zoom:
          this.logo?.screenLogoProperties?.zoom ||
          CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
      });
    },

    //This only shows how the screen logo will be, and it is applied in the modal
    async handleApplyScreenLogo(screenLogoProperties) {
      await this.updateLogoSrc({
        fileUploaded: screenLogoProperties.logo,
        typeOfUpgrade: "screenLogo",
      });

      this.screenLogoProperties.position = screenLogoProperties.position;
      this.screenLogoProperties.zoom = screenLogoProperties.zoom;

      this.setNewActiveScreenLogo({
        logo: screenLogoProperties.logo,
        id: this.logoIdToBeEdited,
        screenLogoProperties: this.screenLogoProperties,
      });

      this.setApiLoading(false);
      this.showEditScreenLogoModal = false;
    },

    openCropperJsToEditLogo(logo) {
      this.showUploadLogoModal = true;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.file = logo;
      this.fileName = this.file.fileOriginal;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];

      this.fileUploaded = logo.fileUploaded;
      this.fileOriginal = logo.fileOriginal;
      this.logoIdToBeEdited = logo.id;
      this.typesOfLogoToBeEdited = logo.typeOfLogo ? logo.typeOfLogo : [];
      this.editableLogo = logo;
    },

    getSelectedLogoById(selectedLogoId) {
      if (!selectedLogoId) return false;

      //To do=> We will need to return ALL the selected logos when the endpoint is ready
      const logo = this.logos.filter(
        (uploadedLogo) => uploadedLogo.id === selectedLogoId
      )[0];

      return logo;
    },

    saveEditedLogo({ editedLogoId, editedLogo, typeOfLogo, colors }) {
      this.fileUploaded = "";
      this.fileOriginal = "";

      this.preselectedLogoId = editedLogoId;
      this.updateLogo({ editedLogoId, editedLogo, typeOfLogo, colors });

      this.showManageLogosModal = true;

      //If dealer mode is not active, just set the uploaded logo as the new selected logo (because only the dealer is allowed to see the Logos Modal)
      this.changeSelectedLogo(editedLogoId);
    },
  },

  mounted() {
    if (this.originalLogo) {
      this.logoIdToBeEdited = this.activeScreenLogo?.id
      this.setScreenLogoProperties({
        originalGraphic: this.originalLogo,
        position:
          this.position || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
        zoom: this.zoom || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
      });
    }
  },

  components: {
    "v-select": vSelect,
    EditScreenLogoModal,
    ManageLogosModal,
    ScreenLogoPreview,
    UploadLogoModal,
    UploadLogoButton,
    SwapIconSVG,
  },
};
</script>

<style lang="scss" scoped>
button {
  border: 1px solid black;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s;
  padding: 0.5rem !important;
  &:hover {
    background: black;
    color: white;
  }
}

.edit-graphic-button {
  background: none;
  border: 1px solid #666;
  cursor: pointer;
  color: #666;
  font-family: "Ubuntu Regular";
  font-size: 0.9rem;
  margin: 8px auto;

  width: 100%;

  &:hover {
    background: black;
    color: white;
    transform: scale(1);
  }
}

.swap-graphic-button {
  * {
    font-family: "Ubuntu Regular";
  }
  align-items: center;
  background: none;
  border: none !important;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  transition: all 0.2s;

  span {
    text-decoration: underline;
    text-transform: capitalize;
    color: #424242;
    font-size: 0.9rem;
  }

  svg {
    margin-right: 0.4rem !important;
    width: 16px;
    height: 16px;
    fill: black;
  }

  &:hover {
    span {
      color: whitesmoke;
      text-decoration: none;
    }
    svg {
      fill: whitesmoke;
    }
  }
}
</style>